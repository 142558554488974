import { QuestionTypeID } from '@sprigShared/questions';
import { IconKey } from 'twig';

export const QUESTION_TYPE_ICON: Record<QuestionTypeID, IconKey> = {
  [QuestionTypeID.ConsentLegal]: 'question-consent-legal',
  [QuestionTypeID.RatingScale]: 'question-rating-scale',
  [QuestionTypeID.NPS]: 'question-nps',
  [QuestionTypeID.MultipleChoice]: 'question-multiple-choice-single',
  [QuestionTypeID.MultipleSelect]: 'question-multiple-choice-multi',
  [QuestionTypeID.OpenText]: 'question-open-text',
  [QuestionTypeID.RecordedTask]: 'question-recorded-task',
  [QuestionTypeID.TextUrlPrompt]: 'question-text-url',
  [QuestionTypeID.VideoVoice]: 'question-video-voice',
  [QuestionTypeID.Matrix]: 'question-matrix',
} as const;

export enum QuestionTypeLabel {
  LIKERT_SCALE = 'One to Five Scale',
  RATING_SCALE = 'Rating Scale',
  OPEN_ENDED_TEXT = 'Open Text',
  MULTIPLE_CHOICE_SINGLE_ANSWER = 'Multiple Choice Single-Select',
  MULTIPLE_CHOICE_MULTIPLE_ANSWER = 'Multiple Choice Multi-Select',
  NPS = 'NPS',
  TEXT_URL_PROMPT = 'Text / URL Prompt',
  VIDEO_VOICE = 'Video & Voice',
  CONSENT_LEGAL = 'Consent / Legal',
  RECORDED_TASK = 'Recorded Task',
  MATRIX = 'Matrix',
}

export interface QuestionTypeDisplay {
  label: QuestionTypeLabel;
  value: QuestionTypeID;
  icon: IconKey;
}

export const QUESTION_TYPE_OPTIONS: QuestionTypeDisplay[] = [
  {
    label: QuestionTypeLabel.OPEN_ENDED_TEXT,
    value: QuestionTypeID.OpenText,
    icon: QUESTION_TYPE_ICON[QuestionTypeID.OpenText],
  },
  {
    label: QuestionTypeLabel.RATING_SCALE,
    value: QuestionTypeID.RatingScale,
    icon: QUESTION_TYPE_ICON[QuestionTypeID.RatingScale],
  },
  {
    label: QuestionTypeLabel.MULTIPLE_CHOICE_SINGLE_ANSWER,
    value: QuestionTypeID.MultipleChoice,
    icon: QUESTION_TYPE_ICON[QuestionTypeID.MultipleChoice],
  },
  {
    label: QuestionTypeLabel.MULTIPLE_CHOICE_MULTIPLE_ANSWER,
    value: QuestionTypeID.MultipleSelect,
    icon: QUESTION_TYPE_ICON[QuestionTypeID.MultipleSelect],
  },
  {
    label: QuestionTypeLabel.NPS,
    value: QuestionTypeID.NPS,
    icon: QUESTION_TYPE_ICON[QuestionTypeID.NPS],
  },
  {
    label: QuestionTypeLabel.MATRIX,
    value: QuestionTypeID.Matrix,
    icon: QUESTION_TYPE_ICON[QuestionTypeID.Matrix],
  },
  {
    label: QuestionTypeLabel.VIDEO_VOICE,
    value: QuestionTypeID.VideoVoice,
    icon: QUESTION_TYPE_ICON[QuestionTypeID.VideoVoice],
  },
  {
    label: QuestionTypeLabel.RECORDED_TASK,
    value: QuestionTypeID.RecordedTask,
    icon: QUESTION_TYPE_ICON[QuestionTypeID.RecordedTask],
  },
  {
    label: QuestionTypeLabel.TEXT_URL_PROMPT,
    value: QuestionTypeID.TextUrlPrompt,
    icon: QUESTION_TYPE_ICON[QuestionTypeID.TextUrlPrompt],
  },
  {
    label: QuestionTypeLabel.CONSENT_LEGAL,
    value: QuestionTypeID.ConsentLegal,
    icon: QUESTION_TYPE_ICON[QuestionTypeID.ConsentLegal],
  },
];

export const QUESTION_ID_TO_LABEL = {
  1: QuestionTypeLabel.OPEN_ENDED_TEXT,
  2: QuestionTypeLabel.MULTIPLE_CHOICE_SINGLE_ANSWER,
  4: QuestionTypeLabel.RATING_SCALE,
  5: QuestionTypeLabel.MULTIPLE_CHOICE_MULTIPLE_ANSWER,
  7: QuestionTypeLabel.NPS,
  8: QuestionTypeLabel.TEXT_URL_PROMPT,
  9: QuestionTypeLabel.VIDEO_VOICE,
  10: QuestionTypeLabel.CONSENT_LEGAL,
  11: QuestionTypeLabel.RECORDED_TASK,
  12: QuestionTypeLabel.MATRIX,
} as const;

export const getQuestionTypeById = (questionTypeId: number): QuestionTypeDisplay =>
  // default to open text
  QUESTION_TYPE_OPTIONS.find((option) => option.value === questionTypeId) || QUESTION_TYPE_OPTIONS[0];
