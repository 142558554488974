export const turquoise = {
  100: '#EEFAF8',
  200: '#DCF5F2',
  300: '#CBF0EB',
  400: '#B9EBE4',
  500: '#A8E6DD',
  600: '#97E2D7',
  700: '#85DDD0',
  800: '#74D8C9',
  900: '#62D3C3',
  1000: '#51CEBC',
  2000: '#276768',
};
