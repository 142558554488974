import { gold } from './gold';
import { mango } from './mango';
import { orange } from './orange';
import { turquoise } from './turquoise';

export const intensity: { [key: number]: string } = {
  0: turquoise[800],
  17: turquoise[900],
  40: turquoise[1000],
  70: gold[800],
  81: mango[1000],
  100: orange[1000],
};
