export enum baseBreakpoints {
  smallMobile = 500,
  mobile = 700,
  tablet = 900,
  smallDesktop = 1100,
  desktop = 1260,
  monitor = 1500,
}

export const breakpoints = {
  smallMobile: `${baseBreakpoints.smallMobile}px`,
  mobile: `${baseBreakpoints.mobile}px`,
  tablet: `${baseBreakpoints.tablet}px`,
  smallDesktop: `${baseBreakpoints.smallDesktop}px`,
  desktop: `${baseBreakpoints.desktop}px`,
  monitor: `${baseBreakpoints.monitor}px`,
};
