export const lavender = {
  100: '#F8F1FD',
  200: '#F2E4FC',
  300: '#EBD6FA',
  400: '#E5C9F9',
  500: '#DEBBF7',
  600: '#D7AEF6',
  700: '#D1A0F4',
  800: '#CA93F3',
  900: '#C485F1',
  1000: '#BD78F0',
  2000: '#4B4174',
};
