import { Comparator } from '@sprigShared/comparator';
import { QuestionTypeID } from '@sprigShared/questions';
import { PLATFORM_TYPES } from '@sprigShared/types';

export const TEMPLATE_KEY = 'template';

export const SHARE_LINKEDIN_URL = 'https://www.linkedin.com/sharing/share-offsite?mini=true&url=';
export const SHARE_TWITTER_URL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
  'Have a couple minutes? Provide us some feedback with @Sprig'
)}&url=`;

export const REPLAY_MOBILE_AGENTS = {
  REACT_NATIVE: 'React Native',
  ANDROID: 'Android',
  IOS: 'iOS',
};

export enum DEVICE_TYPE {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export const WEBSURVEY_PLATFORMS = new Set([PLATFORM_TYPES.EMAIL, PLATFORM_TYPES.LINK]);

export const isDeviceType = (x: unknown): x is DEVICE_TYPE => {
  return typeof x === 'string' && Object.values(DEVICE_TYPE).find((v) => v === x) !== undefined;
};

export const isPlatformType = (x: unknown): x is PLATFORM_TYPES => {
  return typeof x === 'string' && Object.values(PLATFORM_TYPES).find((v) => v === x) !== undefined;
};

export enum PreviewTypes {
  SURVEY = 'survey',
  TEMPLATE = 'template',
}

export enum PreviewActions {
  reviewSurvey = 'reviewSurvey',
  previewSurvey = 'previewSurvey',
}

export const FROM_SCRATCH_SURVEY_FIXED_TEMPLATE_ID = 999;

export const ComparatorLabel: { [key in Comparator]: string } = {
  [Comparator.CompletelySubmitted]: 'is completely submitted',
  [Comparator.Contains]: 'contains',
  [Comparator.EndsWith]: 'ends with',
  [Comparator.Equal]: '= (is equal to)',
  [Comparator.Exactly]: 'is exactly',
  [Comparator.GivenUp]: 'is given up',
  [Comparator.GreaterThan]: '> (is greater than)',
  [Comparator.GreaterThanOrEqual]: '≥ (is greater than or equal to)',
  [Comparator.In]: 'includes',
  [Comparator.IncludesAll]: 'includes all',
  [Comparator.IncludesOne]: 'includes at least one',
  [Comparator.Is]: 'is',
  [Comparator.IsNot]: 'is not',
  [Comparator.IsSet]: 'is set',
  [Comparator.LessThan]: '< (is less than)',
  [Comparator.LessThanOrEqual]: '≤ (is less than or equal to)',
  [Comparator.Matches]: 'matches',
  [Comparator.NotContains]: 'does not contain',
  [Comparator.NotEqual]: '≠ (is not equal to)',
  [Comparator.NotIncludes]: 'does not include',
  [Comparator.NotMatches]: 'does not match',
  [Comparator.Partial]: 'is only partially submitted',
  [Comparator.Skipped]: 'is skipped by user',
  [Comparator.StartsWith]: 'starts with',
  [Comparator.Submitted]: 'is submitted',
};

export const SkipComparatorLabel: { [key in Comparator]: string } = {
  ...ComparatorLabel,
  [Comparator.Equal]: '=',
  [Comparator.NotEqual]: '≠',
  [Comparator.GreaterThan]: '>',
  [Comparator.GreaterThanOrEqual]: '≥',
  [Comparator.LessThan]: '<',
  [Comparator.LessThanOrEqual]: '≤',
};

export const FreeTextComparator = new Set([Comparator.Contains, Comparator.NotContains]);
export const SelectableComparator = new Set([Comparator.IncludesAll, Comparator.IncludesOne, Comparator.NotIncludes]);

/**
 * Survey Filter Types
 *
 * Attention: Not an Enum because it needs to be consumable by NON-Typescript files
 */
export const FILTER_TYPES = {
  ATTR: 'attr',
  RESP: 'resp',
  EVENT: 'event',
};

/**
 * Survey Filter Types Labels Names
 *
 * Attention: Not an Enum because it needs to be consumable by NON-Typescript files
 */
export const FILTERS_LABEL_MAP = {
  [FILTER_TYPES.ATTR]: 'Attributes',
  [FILTER_TYPES.RESP]: 'Questions',
  [FILTER_TYPES.EVENT]: 'Events',
};

export const SDK_HEADER_MODULES = 'sprig-modules';
export enum SdkModule {
  Replay = 'replay',
}

// replayer
export type ReplayerMessage =
  | { type: 'getReplayTimestamp' }
  | {
      type: 'replayReady';
      timestamp: number;
    }
  | {
      type: 'replaySeek';
      timestamp: number;
    }
  | {
      type: 'replayTimestamp';
      timestamp: number;
    };

export const TRANSLATION_LANGUAGES = [
  'Albanian (Albania)',
  'Arabic (Egypt)',
  'Bengali (India)',
  'Bulgarian (Bulgaria)',
  'Chinese (China-Simplified)',
  'Chinese (Hong Kong)',
  'Chinese (Simplified)',
  'Chinese (Taiwan-Traditional)',
  'Chinese (US-Simplified)',
  'Czech (Czechia)',
  'Danish (Denmark)',
  'Dutch (Belgium)',
  'Dutch (Netherlands)',
  'English (Australia)',
  'English (Canada)',
  'English (United Kingdom)',
  'English (USA)',
  'Estonian (Estonia)',
  'Finnish (Finland)',
  'French (Belgium)',
  'French (Canada)',
  'French (France)',
  'French (International)',
  'French (US)',
  'German (Austria)',
  'German (Germany)',
  'German (Luxembourg)',
  'Greek (Greece)',
  'Hebrew (Israel)',
  'Hindi (India)',
  'Hungarian (Hungary)',
  'Indonesian (Indonesia)',
  'Italian (Italy)',
  'Japanese (Japan)',
  'Korean (Korea)',
  'Latvian (Latvia)',
  'Lithuanian (Lithuania)',
  'Malay (Malaysia)',
  'Norwegian (Norway)',
  'Polish (Poland)',
  'Portuguese (Brazil)',
  'Portuguese (Portugal)',
  'Romanian (Romania)',
  'Russian (Russia)',
  'Sinhala (Sri Lanka)',
  'Slovak (Slovakia)',
  'Slovenian (Slovenia)',
  'Spanish (International)',
  'Spanish (Mexico)',
  'Spanish (Spain)',
  'Spanish (US)',
  'Swedish (Sweden)',
  'Tagalog (Philippines)',
  'Thai (Thailand)',
  'Turkish (Turkey)',
  'Vietnamese (Vietnam)',
];

export const STATUS = {
  IN_PROGRESS: 1,
  PAUSED: 2,
  COMPLETED: 3,
  DRAFT: 4,
  ARCHIVED: 5,
  NEW: 6, // Newly created but hasn't been saved as draft or launched
};

// Lets start sharing error messages here so both api and dashboard can share.
export const VALIDATION_ERRORS = {
  events: {
    invalid_field: 'Event contains invalid fields',
    name_exist: 'Event with that name/displayname already exists',
    url_pattern_exist: 'Event with that url pattern already exists',
  },
};

export const FEATURES = Object.freeze({
  advancedSkipLogic: 'advancedSkipLogic',
  buttonText: 'buttonText',
  captionText: 'captionText',
  consentLegal: 'consentLegal',
  endCard: 'endCard',
  enrichUrl: 'enrichUrl',
  eventProperty: 'eventProperty',
  feedbackButton: 'feedbackButton',
  footer: 'footer',
  heatmap: 'heatmap',
  hideRecordedPrompt: 'hideRecordedPrompt',
  launchDarklyConstraints: 'launchDarklyConstraints',
  matrix: 'matrix',
  mcmsSkipLogic: 'mcmsSkipLogic',
  mobileAccordionMatrix: 'mobileAccordionMatrix',
  mobileTriggerDelay: 'mobileTriggerDelay',
  multipleChoiceDropdown: 'multipleChoiceDropdown',
  noneOfTheAbove: 'noneOfTheAbove',
  nps: 'nps',
  openTextPlaceholder: 'openTextPlaceholder',
  optimizelyConstraints: 'optimizelyConstraints',
  otherSpecify: 'otherSpecify',
  pageUrlMatchType: 'pageUrlMatchType',
  previewDomain: 'previewDomain',
  ratingScale: 'ratingScale',
  recordedTask: 'recordedTask',
  replayDurationType: 'replayDurationType',
  requiredQuestions: 'requiredQuestions',
  richText: 'richText',
  sessionReplay: 'sessionReplay',
  skippedSkipLogic: 'skippedSkipLogic',
  textUrlPrompt: 'textUrlPrompt',
  videoVoice: 'videoVoice',
});

const CUSTOMIZED_COPY_VERSIONS = Object.freeze({ web: '1.8.0', ios: '4.1.0', android: '2.1.2' });
const MCMS_SKIP_LOGIC_VERSIONS = Object.freeze({ web: '1.11.0', ios: '4.2.0', android: '2.2.0' });
const SKIPPED_SKIP_LOGIC_VERSIONS = Object.freeze({ web: '2.15.14', ios: '4.8.5', android: '2.6.1' });
const NPS_VERSIONS = Object.freeze({ web: '1.3.0', ios: '2.1.0', android: '1.1.0' });
const TEXT_URL_PROMPT_VERSIONS = Object.freeze({ web: '1.13.0', ios: '4.3.0', android: '2.3.0' });
const PAGE_URL_MATCH_TYPE_VERSIONS = Object.freeze({ web: '2.6.0' });
const CONSENT_LEGAL_VERSIONS = Object.freeze({ web: '2.15.10', ios: '4.8.5', android: '2.6.1' });
const VIDEO_VOICE_VERSIONS = Object.freeze({ web: '2.2.0' });
const RECORDED_TASK_VERSIONS = Object.freeze({ web: '2.16.0' });
const ENRICH_URL_VERSIONS = Object.freeze({ android: '2.6.3' });
const OTHER_SPECIFY_VERSIONS = Object.freeze({ web: '2.17.0', ios: '4.8.5', android: '2.6.4' });
const RATING_SCALE_VERSIONS = Object.freeze({ web: '2.17.3', ios: '4.8.5', android: '2.6.4' });
const REQUIRED_VERSIONS = Object.freeze({
  web: '2.19.1',
  ios: '4.10.0',
  android: '2.8.0',
});
const OPTIMIZELY_CONSTRAINTS_VERSIONS = Object.freeze({ web: '2.18.3', ios: '4.11.0', android: '2.10.0' });
const MOBILE_TRIGGER_VERSIONS = Object.freeze({ ios: '4.11.0', android: '2.9.0' });
const EVENT_PROPERTIES_VERSIONS = Object.freeze({ web: '2.18.2', ios: '4.9.0', android: '2.7.0' });
const TEST_A_STUDY_VERSIONS = Object.freeze({ web: '2.23.2', android: '2.15.0', ios: '4.19.1' });
const SESSION_REPLAY_VERSIONS = Object.freeze({ web: '2.22.0', ios: '4.22.0', android: '2.17.0' });
const REPLAY_DURATION_TYPE_VERSIONS = Object.freeze({ web: '2.23.0' });
const LAUNCHDARKLY_CONSTRAINTS_VERSIONS = Object.freeze({ web: '2.23.0' });
export const ADVANCED_SKIP_LOGIC_VERSIONS = Object.freeze({ web: '2.24.0', android: '2.14.0', ios: '4.17.0' });
export const MATRIX_VERSIONS = Object.freeze({ web: '2.25.1' });
export const NONE_OF_THE_ABOVE_VERSIONS = Object.freeze({ web: '2.26.2', android: '2.16.4', ios: '4.20.2' });
export const RICH_TEXT_FOOTER_VERSIONS = Object.freeze({ web: '2.28.0', android: '2.17.0', ios: '4.21.7' });
const MULTIPLE_CHOICE_DROPDOWN_VERSIONS = Object.freeze({ web: '2.28.0', android: '2.17.0', ios: '4.21.7' });
const HIDE_RECORDED_PROMPT_VERSIONS = Object.freeze({ web: '2.28.0', android: '2.17.0', ios: '4.21.7' });

const FEEDBACK_LABEL_VERSIONS = {
  web: '2.27.0',
} as const;

const HEATMAP_VERSIONS = {
  web: '2.30.3',
} as const;

export const MOBILE_ACCORDION_MATRIX_VERSION = Object.freeze({ web: '2.32.0', android: '2.17.4', ios: '4.23.0' });

export const FEATURE_MIN_VERSIONS = Object.freeze({
  advancedSkipLogic: ADVANCED_SKIP_LOGIC_VERSIONS,
  buttonText: CUSTOMIZED_COPY_VERSIONS,
  captionText: CUSTOMIZED_COPY_VERSIONS,
  consentLegal: CONSENT_LEGAL_VERSIONS,
  endCard: CUSTOMIZED_COPY_VERSIONS,
  enrichUrl: ENRICH_URL_VERSIONS,
  eventProperty: EVENT_PROPERTIES_VERSIONS,
  feedbackButton: FEEDBACK_LABEL_VERSIONS,
  footer: RICH_TEXT_FOOTER_VERSIONS,
  heatmap: HEATMAP_VERSIONS,
  hideRecordedPrompt: HIDE_RECORDED_PROMPT_VERSIONS,
  launchDarklyConstraints: LAUNCHDARKLY_CONSTRAINTS_VERSIONS,
  matrix: MATRIX_VERSIONS,
  mcmsSkipLogic: MCMS_SKIP_LOGIC_VERSIONS,
  mobileAccordionMatrix: MOBILE_ACCORDION_MATRIX_VERSION,
  mobileTriggerDelay: MOBILE_TRIGGER_VERSIONS,
  multipleChoiceDropdown: MULTIPLE_CHOICE_DROPDOWN_VERSIONS,
  noneOfTheAbove: NONE_OF_THE_ABOVE_VERSIONS,
  nps: NPS_VERSIONS,
  openTextPlaceholder: CUSTOMIZED_COPY_VERSIONS,
  optimizelyConstraints: OPTIMIZELY_CONSTRAINTS_VERSIONS,
  otherSpecify: OTHER_SPECIFY_VERSIONS,
  pageUrlMatchType: PAGE_URL_MATCH_TYPE_VERSIONS,
  previewDomain: TEST_A_STUDY_VERSIONS,
  ratingScale: RATING_SCALE_VERSIONS,
  recordedTask: RECORDED_TASK_VERSIONS,
  replayDurationType: REPLAY_DURATION_TYPE_VERSIONS,
  requiredQuestions: REQUIRED_VERSIONS,
  richText: RICH_TEXT_FOOTER_VERSIONS,
  sessionReplay: SESSION_REPLAY_VERSIONS,
  skippedSkipLogic: SKIPPED_SKIP_LOGIC_VERSIONS,
  textUrlPrompt: TEXT_URL_PROMPT_VERSIONS,
  videoVoice: VIDEO_VOICE_VERSIONS,
});

//This represents the implicit 'required' status for each question type
//used for surveys created before the ability to toggle 'required'
export const LEGACY_REQUIRED_STATUS_BY_TYPE = {
  [QuestionTypeID.TextUrlPrompt]: true,
  [QuestionTypeID.ConsentLegal]: true,
  [QuestionTypeID.NPS]: true,
  [QuestionTypeID.RatingScale]: true,
  [QuestionTypeID.MultipleChoice]: true,
  [QuestionTypeID.MultipleSelect]: false,
  [QuestionTypeID.RecordedTask]: false,
  [QuestionTypeID.VideoVoice]: false,
  [QuestionTypeID.OpenText]: false,
  [QuestionTypeID.Matrix]: true,
};

export const ENRICH_URL_USER_ID_KEY = '{{user_id}}';
export const ENRICH_URL_EMAIL_KEY = '{{email}}';

export enum ConstraintTypeID {
  Attribute = 0,
  SessionCount = 1,
  TimeOnPageDelay = 2,
  Language = 3,
  PageCount = 4,
  EventSet = 8,
  WebNoCodeEvent = 9,
  Event = 15,
  EventCount = 16,
  EventDaysSinceFirst = 17,
  EventDaysSinceLast = 18,
  MobileOS = 19,
  DynamicGroup = 27,
  ManualGroup = 28,
  EventPropertyTrigger = 29,
  EventPropertyFilter = 30,
  DeviceType = 31,
  Optimizely = 32,
  LaunchDarkly = 33,
  UserId = 34,
}

export const LEGACY_PROPERTY_DEFAULTS = {
  buttonText: 'Next',
  captionText: 'This will help us improve your experience.',
  videoCaptionText: 'Listen carefully and record your response below',
  finishButtonText: 'Finish',
  openTextPlaceholder: 'I think that...',
};

interface APIDiagnostic {
  android: number;
  ios: number;
  web: number;
}

interface APIDiagnostics {
  lastDay: APIDiagnostic;
  lastWeek: APIDiagnostic;
  lastMonth: APIDiagnostic;
}

export interface Constraint<T = number | string, C = ConstraintTypeID> {
  createdAt: string;
  description: string | null;
  diagnostics?: APIDiagnostics;
  displayname: string | null;
  eventId?: number;
  keyValue?: string | null;
  name: string;
  comparisonType: Comparator;
  constraintTypeId: C;
  platform?: string;
  populationEstimate?: number;
  type?: string;
  uuid: string;
  value: T;
  isInlineURL?: boolean;
  surveyCount?: number;
}

export interface Diagnostic {
  isCollectingData: boolean;
  label: string;
  lastDay: number | null;
  lastHour: number | null;
  lastWeek: number | null;
  shouldShowDiagnostics: boolean;
}

export interface Diagnostics {
  android: Diagnostic;
  api: Diagnostic;
  csv: Diagnostic;
  ios: Diagnostic;
  isCollectingData: boolean;
  javascript: Diagnostic;
  mparticle: Diagnostic;
  segment: Diagnostic;
  shouldShowDiagnostics: boolean;
}

// https://api.slack.com/methods/chat.postMessage#errors
export enum SLACK_ERRORS {
  ACCOUNT_INACTIVE = 'account_inactive', // error arises when bot token is being used for deleted user/workspace
  CHANNEL_NOT_FOUND = 'channel_not_found', // channel could have been deleted by customer or integration moved to a different channel
  INVALID_AUTH = 'invalid_auth', // token is invalid
  MISSING_SCOPE = 'missing_scope', // the scope aint there my friend
  NOT_IN_CHANNEL = 'not_in_channel', // error arises when slack bot is not invited to channel
  TOKEN_REVOKED = 'token_revoked', // token could be revoked by the customer
}

export const URL_MATCH_TYPES = {
  EXACTLY: 'exactly',
  NOT_EXACTLY: 'notExactly',
  CONTAINS: 'contains',
  NOT_CONTAINS: 'notContains',
  STARTS_WITH: 'startsWith',
  ENDS_WITH: 'endsWith',
  REGEX: 'regex',
  LEGACY: 'legacy',
};

export const WILDCARD_URL_MATCH_TYPES = new Set([
  URL_MATCH_TYPES.EXACTLY,
  URL_MATCH_TYPES.CONTAINS,
  URL_MATCH_TYPES.STARTS_WITH,
  URL_MATCH_TYPES.ENDS_WITH,
  URL_MATCH_TYPES.LEGACY,
]);

export const BACKDROP_OPTION_DIMENSIONS = {
  WIDTH: 195,
  HEIGHT: 250,
};
