import isNull from 'lodash/isNull';

import { isBrowser } from '@sprigShared/environment';

const TOKEN_NAME = 'access_token';
const ADMIN_TEAM_ID_TOKEN = 'admin_team_id_token';
const FROM_ONBOARDING_NAME = 'onboarding_token';
const DEMO_PROMPT = 'sprig.d_prompt';
const LIMIT_TOKEN_NAME = 'sprig.limit';
const APPROACHING_LIMIT_TOKEN_NAME = 'sprig.approaching_limit';

const generateGetSetRemove = (tokenName: string) => {
  if (isBrowser()) {
    return {
      get: () => localStorage?.getItem(tokenName),
      set: (token: string | null | number | boolean) => {
        if (!isNull(token)) {
          localStorage?.setItem(tokenName, `${token}`);
        }
      },
      remove: () => localStorage?.removeItem(tokenName),
    };
  }

  return {
    get: () => null,
    set: (_token: string | null | number | boolean) => {},
    remove: () => {},
  };
};

export type Token = {
  get: () => string | null;
  set: (token: string | null | number | boolean) => void;
  remove: () => void;
};

export const AuthToken = Object.freeze(generateGetSetRemove(TOKEN_NAME));
export const AdminToken = Object.freeze(generateGetSetRemove(ADMIN_TEAM_ID_TOKEN));
export const UsageToken = Object.freeze(generateGetSetRemove(LIMIT_TOKEN_NAME));
export const ApproachingUsageToken = Object.freeze(generateGetSetRemove(APPROACHING_LIMIT_TOKEN_NAME));

// Todo: Move all these below to a different file
export const FromOnboardingFlag = Object.freeze(generateGetSetRemove(FROM_ONBOARDING_NAME));
export const HideDemoPrompt = Object.freeze(generateGetSetRemove(DEMO_PROMPT));
