import React from 'react';

import styled from '@emotion/styled';

import { theme, typography } from '../../../styles';
import { Icon } from '../../Icon';
import { Popup } from '../../Popup';

interface Props {
  htmlFor?: string;
  // should be an enum for sizes
  small?: boolean;
  className?: string;
  children: React.ReactNode;
  tooltip?: React.ReactNode;
  tooltipPlacement?: string;
}

export const Label = ({ children, tooltip, tooltipPlacement, ...props }: Props) => {
  if (tooltip) {
    return (
      <StyledLabel {...props}>
        <StyledPopup
          on="hover"
          placement={tooltipPlacement}
          trigger={
            <Trigger>
              {children}
              <StyledIcon src="help" />
            </Trigger>
          }
        >
          {tooltip}
        </StyledPopup>
      </StyledLabel>
    );
  }
  return <StyledLabel {...props}>{children}</StyledLabel>;
};

const StyledLabel = styled.label<Props>`
  ${(p) => p.small && typography.smallBody};
  border-bottom: ${(p) => (p.tooltip ? `1px dashed ${theme.gray8}` : 'none')};
  color: ${theme.gray10};
  display: block;
  margin-bottom: ${(p) => (p.tooltip ? '0.75em' : '0.5em')};
  padding-bottom: ${(p) => (p.tooltip ? '0.1em' : '0')};
`;
const StyledPopup = styled(Popup)`
  color: ${theme.gray12};
`;
const StyledIcon = styled(Icon)`
  margin-left: 0.25em;
`;
const Trigger = styled.div`
  display: flex;
  align-items: center;
`;
