import React, { forwardRef } from 'react';

import styled from '@emotion/styled';
import Color from 'color';

import { elevation, theme } from '../../../styles';
import { SizeVariant } from '../../../utils';
import { Icon } from '../../Icon';
import { getInputSize } from '../getInputSize';
import { LabelWrapper } from '../LabelWrapper';
import { Variant } from '../types';

export interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  className?: string;
  label?: React.ReactNode;
  labelPlacement?: 'start' | 'end';
  variant?: Variant;
  size?: SizeVariant;
}

const CheckboxInner = (
  { className, label, labelPlacement = 'end', variant = Variant.regular, size, checked, ...props }: CheckboxProps,
  ref: React.Ref<HTMLInputElement>
) => {
  const disabled = props.disabled;
  const deluxe = variant === Variant.deluxe;
  const inputSize = size ? size : deluxe ? SizeVariant.large : SizeVariant.medium;

  return (
    <LabelWrapper
      checked={checked}
      deluxe={deluxe}
      className={className}
      disabled={disabled}
      labelPlacement={labelPlacement}
    >
      <Wrapper size={inputSize}>
        <StyledInput {...props} checked={checked} disabled={disabled} ref={ref} type="checkbox" />
        <Box>
          <Icon src="check" />
        </Box>
      </Wrapper>
      {label && <span>{label}</span>}
    </LabelWrapper>
  );
};

export const Checkbox = forwardRef(CheckboxInner);

const StyledInput = styled.input`
  cursor: inherit;
  height: inherit;
  margin: 0;
  opacity: 0;
  position: relative;
  width: inherit;
  z-index: 1;

  & + div {
    & > svg {
      box-sizing: border-box;
      display: none;
      height: inherit;
      position: absolute;
      width: inherit;
    }
  }
  &:checked {
    & + div {
      background-color: ${theme.gold[800]};
      border-color: ${theme.gold[1000]};
      & > svg {
        display: block;
      }
    }
  }

  &:active {
    & + div {
      background-color: ${theme.navy[100]};
    }
    &:checked + div {
      background-color: ${theme.gold[1000]};
    }
  }
  &:disabled {
    & + div {
      background-color: ${theme.navy[50]};
      border-color: ${theme.navy[100]};
      box-shadow: none;
    }
    &:checked + div {
      background-color: ${theme.gold[300]};
      border-color: ${theme.gold[300]};
      & > svg {
        color: ${theme.disabled};
      }
    }
  }
  &:focus-visible {
    & + div {
      border-color: ${theme.navy[1000]};
      box-shadow: 0px 0px 0px 2px ${Color(theme.navy[1000]).alpha(0.3).string()};
      & > svg {
        color: ${theme.navy[100]};
        display: block;
      }
    }
    &:checked + div {
      background-color: ${theme.gold[800]};
      border-color: ${theme.gold[900]};
      & > svg {
        color: ${theme.primary};
      }
    }
  }
  &:hover:not(:active):not(:disabled):not(:focus-visible) {
    & + div {
      background-color: ${theme.navy[50]};
      & > svg {
        color: ${theme.navy[100]};
        display: block;
      }
    }
    &:checked + div {
      background-color: ${theme.gold[900]};
      & > svg {
        color: ${theme.primary};
      }
    }
  }
`;

const Box = styled.div`
  background-color: ${theme.white};
  border-radius: 4px;
  border: 1px solid ${theme.navy[300]};
  box-shadow: ${elevation.base1};
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div<{ size: SizeVariant }>`
  display: flex;
  position: relative;
  height: ${({ size }) => getInputSize(size)};
  width: ${({ size }) => getInputSize(size)};
`;
