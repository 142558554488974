import { useEffect, useRef, useState } from 'react';

export const useOnClickOutside = <T extends HTMLElement>(onClickOutside: () => void, isActive = true) => {
  const [isClickOutside, setIsClickOutside] = useState(false);
  const ref = useRef<T>(null);

  useEffect(() => {
    if (!isActive) {
      setIsClickOutside(false);
      return;
    }

    const listener = (event: MouseEvent) => {
      const insideNode = ref.current;
      if (!insideNode) {
        setIsClickOutside(false);
        return;
      }

      if (event.target !== insideNode && event.target instanceof Element && !insideNode.contains(event.target)) {
        setIsClickOutside(true);
        onClickOutside();
      }
    };
    document.addEventListener('click', listener, true);

    return () => document.removeEventListener('click', listener, true);
  }, [onClickOutside, isActive]);

  return { ref, isClickOutside };
};
