import { keyframes } from '@emotion/react';
import Color from 'color';

import { colors, semantic, theme as colorsThemes } from './colors';

export const FADE_INOUT_SECONDS = 0.3;

export const docBlockBorderColor = '#9993d7';

/**
 * @deprecated - use colors instead
 */
export const theme = {
  ...colors,
  ...semantic,
  ...colorsThemes,

  // old / deprecated
  brand: 'hsl(201, 63%, 12%)',
  brandBacking: 'hsl(203, 49%, 94%)', // remove
  brandText: 'hsl(201, 49%, 7%)',
  brandBorder: 'hsl(205, 48%, 85%)', // remove

  red: 'hsl(359, 65%, 57%)',
  redBacking: 'hsl(359, 100%, 95%)',
  redText: 'hsl(359, 65%, 45%)',
  redBorder: 'hsl(359, 100%, 90%)',
  redReplay: 'hsl(13, 95%, 62%)',

  oldOrange: 'hsl(13, 95%, 58%)',
  oldOrangeBacking: 'hsl(13, 100%, 95%)',
  oldOrangeText: 'hsl(13, 75%, 45%)',
  oldOrangeBorder: 'hsl(13, 100%, 90%)',

  yellow: 'hsl(48, 100%, 49%)',
  yellowBacking: 'hsl(48, 100%, 85%)',
  yellowText: 'hsl(48, 100%, 10%)',
  yellowBorder: 'hsl(48, 100%, 90%)',

  merigold: 'hsl(33, 99%, 58%)',
  merigoldBacking: 'hsl(33, 99%, 95%)',
  merigoldText: 'hsl(33, 99%, 35%)',
  merigoldBorder: 'hsl(33, 99%, 90%)',

  cyan: 'hsl(354, 67%, 74%)',
  cyanBacking: 'hsl(354, 67%, 93%)',
  cyanText: 'hsl(354, 71%, 62%)',
  cyanBorder: 'hsl(354, 71%, 68%)',

  green: 'hsl(171, 56%, 56%)',
  greenBacking: 'hsl(171, 56%, 95%)',
  greenText: 'hsl(171, 56%, 30%)',
  greenBorder: 'hsl(171, 56%, 90%)',

  desaturatedBlue: 'hsl(254, 24%, 17%)',

  purple: 'hsl(246, 50%, 58%)',
  purpleBacking: 'hsl(246, 50%, 95%)',
  purpleText: 'hsl(246, 50%, 58%)',
  purpleBorder: 'hsl(246, 50%, 90%)',

  magenta: 'hsl(336, 61%, 49%)',
  magentaBacking: 'hsl(336, 61%, 95%)',
  magentaText: 'hsl(336, 61%, 30%)',
  magentaBorder: 'hsl(336, 61%, 90%)',

  lilac: 'hsl(274, 80%, 71%)',
  lilacBacking: 'hsl(274, 80%, 95%)',
  lilacText: 'hsl(274, 80%, 30%)',
  lilacBorder: 'hsl(274, 80%, 90%)',

  pink: 'hsl(353, 87%, 76%)',
  pinkBacking: 'hsl(353, 87%, 95%)',
  pinkText: 'hsl(353, 87%, 30%)',
  pinkBorder: 'hsl(353, 87%, 90%)',

  pureBlack: '#000',
  black: '#0B2330',
  darkBlack: 'hsla(0, 0%, 0%, 0.05)',

  // We need to come up with a a better way to lighten/darken colors
  // will create helpers for: https://www.npmjs.com/package/color
  gray00: 'hsl(33, 30%, 99%)', // #FDFDFC
  gray0: 'hsl(60, 20%, 98%)', // #FBFBF9
  gray1: 'hsl(75, 15%, 97%)', // #F8F8F6
  gray2: 'hsl(75, 7%, 95%)', // #F3F3F1
  gray3: 'hsl(160, 5%, 87%)', // #DCDFDE
  gray4: 'hsl(201, 8%, 75%)', // #BAC1C4
  gray5: 'hsl(201, 8%, 70%)', // #ACB4B9
  gray7: 'hsl(201, 8%, 60%)', // #919BA1
  gray8: 'hsl(201, 8%, 50%)', // #75838A
  gray9: 'hsl(201, 10%, 40%)', // #5C6970
  gray10: 'hsl(201, 15%, 25%)', // #364349
  gray11: 'hsl(201, 25%, 18%)', // #223139
  gray12: 'hsl(201, 63%, 12%)', // #0B2432
  gray16: 'hsla(203, 50%, 3%, 1)', // To be removed?
  gray17: 'hsla(243, 14%, 75%, 1)', // To be removed?
  gray18: 'hsla(244, 10%, 65%, 1)', // To be removed?
  gray19: 'hsla(200, 10%, 88%, 1)', // To be removed?
  gray20: 'hsla(191, 8%, 72%, 1)', // To be removed?
  gray21: 'hsla(200, 11%, 33%, 1)', // To be removed?
  gray22: 'hsla(0, 12%, 97%, 1)', // To be removed?
  gray23: 'hsla(201, 8%, 50%, 1)', // #75838a
  gray24: 'hsla(201, 63%, 12%, 1)', // #0b2432
  gray25: 'hsla(160, 4%, 87%, 1)', // #DDDFDE
  gray26: 'hsla(201, 15%, 25%, 1)', // To be removed?
  gray27: 'hsla(200, 8%, 50%, 1)', // To be removed?
  gray28: 'hsla(33, 30%, 93%, 1)', // To be removed?
  gray29: 'hsl(0, 0%, 45%)', // #737373
  gray30: 'hsl(60, 4%, 95%)', // #F3F3F2

  modalBackground: (a: string | number = 0.95) => `hsla(241, 5%, 98%, ${a})`,

  borderRadius: '4px',
  linkHoverOpacity: 0.9,
};

export const vizColors = [
  theme.purple,
  theme.yellow,
  theme.green,
  theme.magenta,
  theme.merigold,
  theme.cyan,
  theme.red,
  theme.magentaText,
  theme.greenText,
];

export const NPS_COLORS = {
  Promoters: theme.green,
  Passives: theme.purple,
  Detractors: theme.red,
};

export const sentimentColors = {
  'very positive': 'hsl(145, 63%, 42%)',
  positive: 'hsl(149, 36%, 58%)',
  neutral: theme.gray5,
  negative: 'hsl(353, 45%, 67%)',
  'very negative': theme.red,
};

export const statusColors: { [key: number]: string } = {
  1: theme.greenText,
  2: theme.black,
  3: theme.greenText,
  4: theme.gray10,
  5: theme.gray10,
};

export const getVizColor = (index: number) => {
  const colorLen = vizColors.length;
  const multiple = Math.floor(index / colorLen);
  const color = Color(vizColors[index % colorLen])
    .lighten(Math.min(multiple * 0.2, 0.8))
    .hsl()
    .string();
  return color;
};

export const getColorFromString = (string?: string) => {
  if (!string) return theme.brand;

  const number = string
    .split('')
    .slice(0, 10)
    .reduce((a, r) => a + r.charCodeAt(0), 0);

  return vizColors[number % vizColors.length];
};

export const getColorFromName = (imageList: string[], name: string) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    const char = name.charCodeAt(i);
    hash = (hash * 31 + char) % Number.MAX_SAFE_INTEGER;
  }
  const positiveHash = Math.abs(hash);
  return imageList[positiveHash % imageList.length];
};

export const elevation = {
  base1: '0px 1px 3px rgba(11, 35, 48, 0.05)',
  base2: '0px 2px 6px rgba(11, 35, 48, 0.05)',
  base3: '0px 2px 8px 0px hsla(0, 0%, 0%, 0.03)',
};

export const zIndex = {
  modal: 400,
  popup: 401,
  modalFromPopup: 402,
  drawer: 403,
  demoTooltip: 404,
  modalOverTooltip: 405,
  chatbot: 500,
};

export const animations = {
  fadeIn: keyframes`
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }`,
  fadeOut: keyframes`
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }`,
  fadeInExpandOut: keyframes`
    0% {
      opacity: 0;
      transform: scale(0.97);
    }

    50% {
      opacity: 0.5;
      transform: scale(0.97);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }`,
  fadeInSlideUp: keyframes`
    0% {
      opacity: 0;
      transform: translateY(20%);
    }

    50% {
      opacity: 1;
      transform: translateY(5%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }`,
  fadeInSlideDown: keyframes`
    0% {
      opacity: 0;
      transform: translateY(-10%);
    }

    50% {
      opacity: 1;
      transform: translateY(-2%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }`,
  expandSubcontent: keyframes`
    0% {
      opacity: 0;
      transform: translateY(-80px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }`,
  slideInLeft: keyframes`
    0% {
        transform: translateX(100%);
    }

    100% {
      transform: translateX(0%);
    }`,
};
