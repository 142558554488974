export const gold = {
  100: '#FEF9E6',
  200: '#FEF4CC',
  300: '#FDEEB3',
  400: '#FDE899',
  500: '#FCE280',
  600: '#FBDD66',
  700: '#FBD74D',
  800: '#FAD133',
  900: '#FACC1A',
  1000: '#F9C600',
  2000: '#6A641D',
};
