export const pink = {
  100: '#FEF4F5',
  200: '#FDE8EB',
  300: '#FDDDE1',
  400: '#FCD1D7',
  500: '#FBC6CC',
  600: '#FABBC2',
  700: '#F9AFB8',
  800: '#F9A4AE',
  900: '#F898A4',
  1000: '#F78D9A',
  2000: '#694D5A',
};
