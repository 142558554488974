import React, { memo } from 'react';

import styled from '@emotion/styled';

import { colors } from '../../styles';

interface SpinnerProps {
  className?: string;
  color?: string;
  size?: string;
  band?: string;
  interval?: string;
}

const SpinnerComp = ({
  className,
  color = colors.typography.primary,
  size = '6rem',
  band = '5px',
  interval = '1.2s',
}: SpinnerProps) => (
  <SpinnerContainer
    className={className}
    data-testid="spinner"
    color={color}
    size={size}
    band={band}
    interval={interval}
  >
    <div className="first"></div>
    <div className="second"></div>
    <div className="third"></div>
    <div className="fourth"></div>
  </SpinnerContainer>
);

type SpinnerContainerProps = Pick<SpinnerProps, 'size' | 'band' | 'color' | 'interval'>;

const SpinnerContainer = styled.div<SpinnerContainerProps>`
  display: inline-block;
  position: relative;
  width: ${(p) => p.size};
  height: ${(p) => p.size};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 80%;
    height: 80%;
    margin: ${(p) => p.band};
    border: ${(p) => p.band} solid ${(p) => p.color};
    border-radius: 50%;
    animation: lds-ring ${(p) => p.interval} cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(p) => p.color} transparent transparent transparent;
  }

  .first {
    animation-delay: -0.45s;
  }
  .second {
    animation-delay: -0.3s;
  }
  .third {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Spinner = memo(SpinnerComp);
