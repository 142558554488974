import React from 'react';

import styled from '@emotion/styled';

import { DEVICE_TYPE } from '@sprigShared/survey-common-constants';
import { HeatmapType } from '@sprigShared/types-web';
import { Link, size, typography } from 'twig';

import analytics from '@sprigShared/analytics';
import { EmptyState } from '../EmptyState';

const Header = styled.h2`
  ${typography.h3}
  margin-bottom: ${size(3)};
`;

const Text = styled.p<{ textSize?: number }>`
  ${typography.smallBody}
  margin: ${({ textSize }) => size(textSize ?? 0.5)} 0;
`;

export const HeatmapEmptyState = ({ device, mapType }: { device: DEVICE_TYPE; mapType: HeatmapType }) => {
  const track = () => {
    analytics.track(analytics.evt.C_HEATMAP_HERE_TO_HELP);
  };

  return (
    <EmptyState device={device}>
      <Header>No session data for this visualization type</Header>
      <Text>
        Once data has been gathered from at least one capture matching your criteria, your {mapType} will be generated
        here.
      </Text>
      <Text>
        Your {mapType} will continue to refine with ongoing data collection. If you’d like any support, we’re{' '}
        <Link
          onClick={track}
          href="https://a.sprig.com/U0pjVmZxLTdRUX5zaWQ6NWZhNzIwZmItMWQ1NS00NjI4LTg1MmMtZDJiMDI4ZTVhYzU2"
        >
          here to help!
        </Link>
      </Text>
      {mapType === HeatmapType.Scrollmap ? (
        <Text textSize={2}>
          If you’re able to visualize a heatmap or clickmap, but scrollmaps are unavailable, the webpage structure may
          not be amenable to scrollmap visualizations and Sprig will be unable to generate a scrollmap.
        </Text>
      ) : null}
    </EmptyState>
  );
};
