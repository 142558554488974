import Color from 'color';

import { navy } from './navy';

export const border = {
  light: navy[100],
  lightTonal: Color(navy[100]).alpha(0.1).hexa(),
  mediumLight: Color(navy[1000]).alpha(0.1).hexa(),
  medium: navy[300],
  mediumTonal: Color(navy[300]).alpha(0.3).hexa(),
  mediumDark: Color(navy[1000]).alpha(0.3).hexa(),
  dark: navy[1000],
  darkTonal: Color(navy[100]).alpha(0.6).hexa(),
};
