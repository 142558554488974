export const DEFAULT_LIKERT_END_INDEX = 4;
export const DEFAULT_LIKERT_RANGE = 5;
export const LIKERT_SCALE_LABEL = {
  NUMBER: 'number',
  STAR: 'star',
  SMILEY: 'smiley',
};
const LIKERT_EMOJI_PREFIX = 'smiley';
export const LIKERT_SMILEY_EMOJI = [
  LIKERT_EMOJI_PREFIX + '-1',
  LIKERT_EMOJI_PREFIX + '-2',
  LIKERT_EMOJI_PREFIX + '-3',
  LIKERT_EMOJI_PREFIX + '-4',
  LIKERT_EMOJI_PREFIX + '-5',
  LIKERT_EMOJI_PREFIX + '-6',
  LIKERT_EMOJI_PREFIX + '-7',
  LIKERT_EMOJI_PREFIX + '-8',
  LIKERT_EMOJI_PREFIX + '-9',
  LIKERT_EMOJI_PREFIX + '-10',
  LIKERT_EMOJI_PREFIX + '-11',
];
