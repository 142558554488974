import React from 'react';

import styled from '@emotion/styled';

import { Icon, IconKey } from '../../..';
import { size, typography, colors } from '../../styles';

export enum ChipColor {
  // sematic status
  Failure = 'Failure',
  HeadsUp = 'HeadsUp',
  Info = 'Info',
  Neutral = 'Neutral',
  Success = 'Success',

  // Use semantic status when possible
  Gold = 'Gold',
  Indigo = 'Indigo',
  Lavender = 'Lavender',
  Magenta = 'Magenta',
  Mango = 'Mango',
  Navy = 'Navy',
  Orange = 'Orange',
  Pink = 'Pink',
  Turquoise = 'Turquoise',
}

// text color
export const colorMap: Record<ChipColor, string> = {
  [ChipColor.Failure]: colors.semantic.negativeText,
  [ChipColor.HeadsUp]: colors.semantic.headsUpText,
  [ChipColor.Info]: colors.semantic.infoText,
  [ChipColor.Neutral]: colors.typography.secondary,
  [ChipColor.Success]: colors.semantic.positiveText,
  [ChipColor.Gold]: colors.semantic.headsUpText,
  [ChipColor.Navy]: colors.typography.secondary,
  [ChipColor.Indigo]: colors.semantic.infoText,
  [ChipColor.Lavender]: colors.lavender[2000],
  [ChipColor.Magenta]: colors.semantic.negativeText,
  [ChipColor.Mango]: colors.mango[2000],
  [ChipColor.Orange]: colors.orange[2000],
  [ChipColor.Pink]: colors.pink[2000],
  [ChipColor.Turquoise]: colors.semantic.positiveText,
};

export const backgroundColorMap: Record<ChipColor, string> = {
  [ChipColor.Failure]: colors.semantic.negativeBackground,
  [ChipColor.HeadsUp]: colors.semantic.headsUpBackground,
  [ChipColor.Info]: colors.semantic.infoBackground,
  [ChipColor.Neutral]: colors.background.selected,
  [ChipColor.Success]: colors.semantic.positiveBackground,
  [ChipColor.Gold]: colors.semantic.headsUpBackground,
  [ChipColor.Navy]: colors.background.selected,
  [ChipColor.Indigo]: colors.semantic.infoBackground,
  [ChipColor.Lavender]: colors.lavender[200],
  [ChipColor.Magenta]: colors.semantic.negativeBackground,
  [ChipColor.Mango]: colors.mango[200],
  [ChipColor.Orange]: colors.orange[200],
  [ChipColor.Pink]: colors.pink[200],
  [ChipColor.Turquoise]: colors.turquoise[200],
};

export interface ChipProps {
  children?: React.ReactNode;
  className?: string;

  /**
   * Use semantic status when possible i.e. Success, HeadsUp, Info, Neutral, Failure
   */
  color?: ChipColor;

  /**
   * Icon to display at the end of the chip
   */
  endIcon?: IconKey;

  /**
   * Icon to display at the start of the chip
   */
  startIcon?: IconKey;
}

export const Chip = ({ children, className, color = ChipColor.Neutral, startIcon, endIcon }: ChipProps) => {
  return (
    <Wrapper className={className} chipColor={color} startIcon={startIcon} endIcon={endIcon}>
      {startIcon && <Icon src={startIcon} size={2} />}
      {children}
      {endIcon && <Icon src={endIcon} size={2} />}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  chipColor: ChipColor;
  startIcon?: IconKey;
  endIcon?: IconKey;
}>`
  ${typography.captionBold}
  align-items: center;
  background-color: ${({ chipColor }: { chipColor: ChipColor }) => backgroundColorMap[chipColor]};
  border-radius: ${size(1.5)};
  color: ${({ chipColor }: { chipColor: ChipColor }) => colorMap[chipColor]};
  display: inline-flex;
  gap: ${size(0.5)};
  line-height: ${size(2)};
  padding: ${size(0.5)} ${size(1)};

  svg {
    flex-shrink: 0;
  }
`;
