export const magenta = {
  100: '#FAEAF1',
  200: '#F4D6E2',
  300: '#EFC1D4',
  400: '#E9ADC5',
  500: '#E498B7',
  600: '#DF83A9',
  700: '#D96F9A',
  800: '#D45A8C',
  900: '#CE467D',
  1000: '#C9316F',
  2000: '#572949',
};
