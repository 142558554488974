// mapping of our own icons
export const iconsMap = {
  'a16z-logo': () => import('./assets/a16z-logo.svg'),
  'accel-logo': () => import('./assets/accel-logo.svg'),
  'add-question-plus': () => import('./assets/add-question-plus.svg'),
  'adobe-xd': () => import('./assets/adobe-xd.svg'),
  'add-question-x': () => import('./assets/add-question-x.svg'),
  'ai-insights-illustration': () => import('./assets/ai-insights-illustration.svg'),
  amplitude: () => import('./assets/amplitude.svg'),
  android: () => import('./assets/android.svg'),
  'android-logo': () => import('@phosphor-icons/react').then(({ AndroidLogo }) => ({ ReactComponent: AndroidLogo })),
  apple: () => import('./assets/apple.svg'),
  'apple-logo': () => import('@phosphor-icons/react').then(({ AppleLogo }) => ({ ReactComponent: AppleLogo })),
  'app-window': () => import('@phosphor-icons/react').then(({ AppWindow }) => ({ ReactComponent: AppWindow })),
  archive: () => import('@phosphor-icons/react').then(({ Archive }) => ({ ReactComponent: Archive })),
  'arrow-curved': () =>
    import('@phosphor-icons/react').then(({ ArrowBendDownRight }) => ({ ReactComponent: ArrowBendDownRight })),
  'arrow-down': () => import('@phosphor-icons/react').then(({ ArrowDown }) => ({ ReactComponent: ArrowDown })),
  'arrow-fat-lines-up': () =>
    import('@phosphor-icons/react').then(({ ArrowFatLinesUp }) => ({ ReactComponent: ArrowFatLinesUp })),
  'arrow-line-left': () =>
    import('@phosphor-icons/react').then(({ ArrowLineLeft }) => ({ ReactComponent: ArrowLineLeft })),
  'arrow-line-right': () =>
    import('@phosphor-icons/react').then(({ ArrowLineRight }) => ({ ReactComponent: ArrowLineRight })),
  'arrow-line-up-right': () =>
    import('@phosphor-icons/react').then(({ ArrowLineUpRight }) => ({
      ReactComponent: ArrowLineUpRight,
    })),
  'arrow-left': () => import('@phosphor-icons/react').then(({ ArrowLeft }) => ({ ReactComponent: ArrowLeft })),
  'arrow-right': () => import('@phosphor-icons/react').then(({ ArrowRight }) => ({ ReactComponent: ArrowRight })),
  'arrow-up': () => import('@phosphor-icons/react').then(({ ArrowUp }) => ({ ReactComponent: ArrowUp })),
  'arrow-up-right': () =>
    import('@phosphor-icons/react').then(({ ArrowUpRight }) => ({ ReactComponent: ArrowUpRight })),
  'arrows-counter-clockwise': () =>
    import('@phosphor-icons/react').then(({ ArrowsCounterClockwise }) => ({
      ReactComponent: ArrowsCounterClockwise,
    })),
  'arrows-in-line-horizontal': () =>
    import('@phosphor-icons/react').then(({ ArrowsInLineHorizontal }) => ({ ReactComponent: ArrowsInLineHorizontal })),
  'arrows-in-simple': () =>
    import('@phosphor-icons/react').then(({ ArrowsInSimple }) => ({ ReactComponent: ArrowsInSimple })),
  'arrows-out-line-horizontal': () =>
    import('@phosphor-icons/react').then(({ ArrowsOutLineHorizontal }) => ({
      ReactComponent: ArrowsOutLineHorizontal,
    })),
  'arrows-left-right': () =>
    import('@phosphor-icons/react').then(({ ArrowsLeftRight }) => ({ ReactComponent: ArrowsLeftRight })),
  'arrows-out-simple': () =>
    import('@phosphor-icons/react').then(({ ArrowsOutSimple }) => ({ ReactComponent: ArrowsOutSimple })),
  'arrow-square-out': () =>
    import('@phosphor-icons/react').then(({ ArrowSquareOut }) => ({ ReactComponent: ArrowSquareOut })),
  attributes: () => import('./Attributes').then(({ Attributes }) => ({ ReactComponent: Attributes })),
  atom: () => import('@phosphor-icons/react').then(({ Atom }) => ({ ReactComponent: Atom })),
  avatar: () => import('@phosphor-icons/react').then(({ UserCircle }) => ({ ReactComponent: UserCircle })),
  'avatar-empty': () => import('./assets/avatar-empty.svg'),
  award: () => import('@phosphor-icons/react').then(({ Medal }) => ({ ReactComponent: Medal })),
  axure: () => import('./assets/axure.svg'),
  bold: () => import('@phosphor-icons/react').then(({ TextBolder }) => ({ ReactComponent: TextBolder })),
  book: () => import('@phosphor-icons/react').then(({ BookOpen }) => ({ ReactComponent: BookOpen })),
  books: () => import('@phosphor-icons/react').then(({ Books }) => ({ ReactComponent: Books })),
  'caret-down': () => import('@phosphor-icons/react').then(({ CaretDown }) => ({ ReactComponent: CaretDown })),
  'caret-left': () => import('@phosphor-icons/react').then(({ CaretLeft }) => ({ ReactComponent: CaretLeft })),
  'caret-right': () => import('@phosphor-icons/react').then(({ CaretRight }) => ({ ReactComponent: CaretRight })),
  'caret-up': () => import('@phosphor-icons/react').then(({ CaretUp }) => ({ ReactComponent: CaretUp })),
  'caret-up-down': () => import('@phosphor-icons/react').then(({ CaretUpDown }) => ({ ReactComponent: CaretUpDown })),
  calendar: () => import('@phosphor-icons/react').then(({ Calendar }) => ({ ReactComponent: Calendar })),
  'calendar-check': () =>
    import('@phosphor-icons/react').then(({ CalendarCheck }) => ({ ReactComponent: CalendarCheck })),
  'canvas-drawing': () => import('@phosphor-icons/react').then(({ Image }) => ({ ReactComponent: Image })),
  census: () => import('./assets/census.svg'),
  chalkboard: () =>
    import('@phosphor-icons/react').then(({ ChalkboardTeacher }) => ({ ReactComponent: ChalkboardTeacher })),
  'change-question-type': () => import('./assets/change-question-type.svg'),
  'chart-bar': () =>
    import('@phosphor-icons/react').then(({ ChartBarHorizontal }) => ({ ReactComponent: ChartBarHorizontal })),
  'chart-bar-vertical': () => import('@phosphor-icons/react').then(({ ChartBar }) => ({ ReactComponent: ChartBar })),
  'chart-donut': () => import('./ChartDonut').then(({ ChartDonut }) => ({ ReactComponent: ChartDonut })),
  'chart-line': () => import('./ChartLine').then(({ ChartLine }) => ({ ReactComponent: ChartLine })),
  'chart-line-up': () => import('@phosphor-icons/react').then(({ ChartLineUp }) => ({ ReactComponent: ChartLineUp })),
  'chart-nps': () => import('./ChartNPS').then(({ ChartNPS }) => ({ ReactComponent: ChartNPS })),
  'chatgpt-black': () => import('./assets/chatgpt-black.svg'),
  'chat-circle': () => import('./assets/chat-circle.svg'),
  'chat-circle-text': () =>
    import('@phosphor-icons/react').then(({ ChatCircleText }) => ({ ReactComponent: ChatCircleText })),
  check: () => import('@phosphor-icons/react').then(({ Check }) => ({ ReactComponent: Check })),
  'check-circle': () => import('@phosphor-icons/react').then(({ CheckCircle }) => ({ ReactComponent: CheckCircle })),
  circle: () => import('@phosphor-icons/react').then(({ Circle }) => ({ ReactComponent: Circle })),
  'circle-check': () => import('./assets/circle-check.svg'),
  'circle-notch': () => import('./assets/circle-notch.svg'),
  'circle-wavy-check': () =>
    import('@phosphor-icons/react').then(({ CircleWavyCheck }) => ({ ReactComponent: CircleWavyCheck })),
  clipboard: () => import('@phosphor-icons/react').then(({ Clipboard }) => ({ ReactComponent: Clipboard })),
  'clipboard-check': () =>
    import('./ClipboardCheck').then(({ ClipboardCheck }) => ({ ReactComponent: ClipboardCheck })),
  clock: () => import('@phosphor-icons/react').then(({ Clock }) => ({ ReactComponent: Clock })),
  'clock-counter-clockwise': () =>
    import('@phosphor-icons/react').then(({ ClockCounterClockwise }) => ({ ReactComponent: ClockCounterClockwise })),
  close: () => import('@phosphor-icons/react').then(({ X }) => ({ ReactComponent: X })),
  'close-circle': () => import('@phosphor-icons/react').then(({ XCircle }) => ({ ReactComponent: XCircle })),
  'cloud-download': () =>
    import('@phosphor-icons/react').then(({ CloudArrowDown }) => ({ ReactComponent: CloudArrowDown })),
  'cloud-upload': () => import('@phosphor-icons/react').then(({ CloudArrowUp }) => ({ ReactComponent: CloudArrowUp })),
  code: () => import('@phosphor-icons/react').then(({ Code }) => ({ ReactComponent: Code })),
  comment: () => import('@phosphor-icons/react').then(({ ChatCircleText }) => ({ ReactComponent: ChatCircleText })),
  compass: () => import('@phosphor-icons/react').then(({ Compass }) => ({ ReactComponent: Compass })),
  'concept-test': () => import('./assets/concept-test.svg'),
  'currency-circle-dollar': () =>
    import('@phosphor-icons/react').then(({ CurrencyCircleDollar }) => ({ ReactComponent: CurrencyCircleDollar })),
  cursor: () => import('@phosphor-icons/react').then(({ Cursor }) => ({ ReactComponent: Cursor })),
  'cursor-click': () => import('@phosphor-icons/react').then(({ CursorClick }) => ({ ReactComponent: CursorClick })),
  dashboards: () => import('./Dashboards').then(({ Dashboards }) => ({ ReactComponent: Dashboards })),
  'data-export': () => import('./assets/data-export.svg'),
  dovetail: () => import('./assets/dovetail.svg'),
  'download-simple': () =>
    import('@phosphor-icons/react').then(({ DownloadSimple }) => ({ ReactComponent: DownloadSimple })),
  'data-hub-bold': () => import('./assets/data-hub-bold.svg'),
  desktop: () => import('@phosphor-icons/react').then(({ Desktop }) => ({ ReactComponent: Desktop })),
  'desktop-button-frame-bl': () => import('./assets/desktop-button-frame-bl.svg'),
  'desktop-button-frame-br': () => import('./assets/desktop-button-frame-br.svg'),
  'desktop-button-frame-cl': () => import('./assets/desktop-button-frame-cl.svg'),
  'desktop-button-frame-cr': () => import('./assets/desktop-button-frame-cr.svg'),
  device: () => import('./assets/device.svg'),
  'dot-outline': () => import('@phosphor-icons/react').then(({ DotOutline }) => ({ ReactComponent: DotOutline })),
  drone: () => import('@phosphor-icons/react').then(({ Drone }) => ({ ReactComponent: Drone })),
  'dots-three': () => import('@phosphor-icons/react').then(({ DotsThree }) => ({ ReactComponent: DotsThree })),
  'dots-three-vertical': () =>
    import('@phosphor-icons/react').then(({ DotsThreeVertical }) => ({
      ReactComponent: DotsThreeVertical,
    })),
  duplicate: () => import('@phosphor-icons/react').then(({ CopySimple }) => ({ ReactComponent: CopySimple })),
  'edit-question': () => import('./EditQuestion').then(({ EditQuestion }) => ({ ReactComponent: EditQuestion })),
  email: () => import('./Email').then(({ Email }) => ({ ReactComponent: Email })),
  events: () => import('./Events').then(({ Events }) => ({ ReactComponent: Events })),
  'event-explorer': () => import('./assets/event-explorer.svg'),
  explorer: () => import('./Explorer').then(({ Explorer }) => ({ ReactComponent: Explorer })),
  external: () => import('@phosphor-icons/react').then(({ ArrowSquareOut }) => ({ ReactComponent: ArrowSquareOut })),
  eye: () => import('@phosphor-icons/react').then(({ Eye }) => ({ ReactComponent: Eye })),
  'eye-slash': () => import('@phosphor-icons/react').then(({ EyeSlash }) => ({ ReactComponent: EyeSlash })),
  facebook: () => import('@phosphor-icons/react').then(({ FacebookLogo }) => ({ ReactComponent: FacebookLogo })),
  'feedback-button': () =>
    import('./FeedbackButton').then(({ FeedbackButton }) => ({ ReactComponent: FeedbackButton })),
  'feedback-plain': () => import('./assets/feedback.svg'),
  figma: () => import('./assets/figma.svg'),
  'figma-alt': () => import('./assets/figma-alt.svg'),
  'figma-logo': () => import('@phosphor-icons/react').then(({ FigmaLogo }) => ({ ReactComponent: FigmaLogo })),
  'figma-name-logo': () => import('./assets/figma-name-logo.svg'),
  file: () => import('@phosphor-icons/react').then(({ File }) => ({ ReactComponent: File })),
  'file-css': () => import('@phosphor-icons/react').then(({ FileCss }) => ({ ReactComponent: FileCss })),
  'file-download': () =>
    import('@phosphor-icons/react').then(({ FileArrowDown }) => ({ ReactComponent: FileArrowDown })),
  'file-pdf': () => import('@phosphor-icons/react').then(({ FilePdf }) => ({ ReactComponent: FilePdf })),
  fire: () => import('@phosphor-icons/react').then(({ Fire }) => ({ ReactComponent: Fire })),
  'first-round-logo': () => import('./assets/first-round-logo.svg'),
  flag: () => import('@phosphor-icons/react').then(({ Flag }) => ({ ReactComponent: Flag })),
  'flow-arrow': () => import('@phosphor-icons/react').then(({ FlowArrow }) => ({ ReactComponent: FlowArrow })),
  folder: () => import('@phosphor-icons/react').then(({ Folder }) => ({ ReactComponent: Folder })),
  'frame-corners': () => import('@phosphor-icons/react').then(({ FrameCorners }) => ({ ReactComponent: FrameCorners })),
  'folder-open': () => import('@phosphor-icons/react').then(({ FolderOpen }) => ({ ReactComponent: FolderOpen })),
  'folder-simple': () => import('@phosphor-icons/react').then(({ FolderSimple }) => ({ ReactComponent: FolderSimple })),
  framer: () => import('./assets/framer.svg'),
  fullscreen: () => import('@phosphor-icons/react').then(({ CornersOut }) => ({ ReactComponent: CornersOut })),
  funnel: () => import('@phosphor-icons/react').then(({ Funnel }) => ({ ReactComponent: Funnel })),
  grabber: () => import('./assets/grabber.svg'),
  gift: () => import('@phosphor-icons/react').then(({ Gift }) => ({ ReactComponent: Gift })),
  globe: () => import('@phosphor-icons/react').then(({ Globe }) => ({ ReactComponent: Globe })),
  googleG: () => import('./assets/google-g.svg'),
  'google-tag-manager': () => import('./assets/google-tag-manager.svg'),
  group: () => import('./Group').then(({ Group }) => ({ ReactComponent: Group })),
  'group-dynamic': () => import('./assets/group-dynamic.svg'),
  'hand-pointing': () => import('@phosphor-icons/react').then(({ HandPointing }) => ({ ReactComponent: HandPointing })),
  hash: () => import('@phosphor-icons/react').then(({ Hash }) => ({ ReactComponent: Hash })),
  heart: () => import('@phosphor-icons/react').then(({ Heart }) => ({ ReactComponent: Heart })),
  heatmap: () => import('./assets/heatmap.svg'),
  'heatmap-plain': () => import('./assets/heatmap-plain.svg'),
  help: () => import('@phosphor-icons/react').then(({ Question }) => ({ ReactComponent: Question })),
  home: () => import('./Home').then(({ Home }) => ({ ReactComponent: Home })),
  hourglass: () => import('@phosphor-icons/react').then(({ HourglassMedium }) => ({ ReactComponent: HourglassMedium })),
  'identification-badge': () =>
    import('@phosphor-icons/react').then(({ IdentificationBadge }) => ({ ReactComponent: IdentificationBadge })),
  info: () => import('@phosphor-icons/react').then(({ Info }) => ({ ReactComponent: Info })),
  intersect: () => import('@phosphor-icons/react').then(({ Intersect }) => ({ ReactComponent: Intersect })),
  'integrations-transparent': () => import('./assets/integrations-transparent.svg'),
  'in-product-survey': () => import('./assets/in-product-survey.svg'),
  'ips-plain': () => import('./assets/ips-plain.svg'),
  invision: () => import('./assets/invision.svg'),
  italic: () => import('@phosphor-icons/react').then(({ TextItalic }) => ({ ReactComponent: TextItalic })),
  javascript: () => import('./assets/javascript.svg'),
  justinmind: () => import('./assets/justinmind.svg'),
  layout: () => import('@phosphor-icons/react').then(({ Layout }) => ({ ReactComponent: Layout })),
  'launch-darkly': () => import('./assets/launch-darkly.svg'),
  'launch-darkly-logo': () => import('./assets/launch-darkly-logo.svg'),
  lightning: () => import('./assets/lightning.svg'),
  'lightning-alt': () => import('./assets/lightning-alt.svg'),
  link: () => import('@phosphor-icons/react').then(({ LinkSimple }) => ({ ReactComponent: LinkSimple })),
  'light-bulb': () => import('@phosphor-icons/react').then(({ Lightbulb }) => ({ ReactComponent: Lightbulb })),
  'link-horizontal': () =>
    import('@phosphor-icons/react').then(({ LinkSimpleHorizontal }) => ({
      ReactComponent: LinkSimpleHorizontal,
    })),
  linkedin: () => import('@phosphor-icons/react').then(({ LinkedinLogo }) => ({ ReactComponent: LinkedinLogo })),
  'linkedin-circle': () => import('./assets/linkedin-circle.svg'),
  'list-bullets': () => import('@phosphor-icons/react').then(({ ListBullets }) => ({ ReactComponent: ListBullets })),
  'list-hamburger': () => import('@phosphor-icons/react').then(({ List }) => ({ ReactComponent: List })),
  'list-numbers': () => import('@phosphor-icons/react').then(({ ListNumbers }) => ({ ReactComponent: ListNumbers })),
  'list-plus': () => import('@phosphor-icons/react').then(({ ListPlus }) => ({ ReactComponent: ListPlus })),
  lock: () => import('@phosphor-icons/react').then(({ LockKey }) => ({ ReactComponent: LockKey })),
  'lock-simple': () => import('@phosphor-icons/react').then(({ LockSimple }) => ({ ReactComponent: LockSimple })),
  'logic-plus': () => import('./assets/logic-plus.svg'),
  'loom-logo': () => import('./assets/loom-logo.svg'),
  'magic-pencil': () => import('./assets/magic-pencil.svg'),
  'magic-wand': () => import('@phosphor-icons/react').then(({ MagicWand }) => ({ ReactComponent: MagicWand })),
  'magnifying-glass': () =>
    import('@phosphor-icons/react').then(({ MagnifyingGlass }) => ({ ReactComponent: MagnifyingGlass })),
  marvel: () => import('./assets/marvel.svg'),
  menu: () => import('@phosphor-icons/react').then(({ List }) => ({ ReactComponent: List })),
  micro: () => import('./assets/micro.svg'),
  'mixpanel-logo': () => import('./assets/mixpanel-logo.svg'),
  mixpanel: () => import('./assets/mixpanel.svg'),
  mobile: () => import('./Mobile').then(({ Mobile }) => ({ ReactComponent: Mobile })),
  'mobile-button-frame-bl': () => import('./assets/mobile-button-frame-bl.svg'),
  'mobile-button-frame-br': () => import('./assets/mobile-button-frame-br.svg'),
  'mobile-button-frame-cl': () => import('./assets/mobile-button-frame-cl.svg'),
  'mobile-button-frame-cr': () => import('./assets/mobile-button-frame-cr.svg'),
  modal: () => import('./Modal').then(({ Modal }) => ({ ReactComponent: Modal })),
  monitor: () => import('@phosphor-icons/react').then(({ Monitor }) => ({ ReactComponent: Monitor })),
  'monitor-play': () => import('@phosphor-icons/react').then(({ MonitorPlay }) => ({ ReactComponent: MonitorPlay })),
  'mouse-scroll': () => import('@phosphor-icons/react').then(({ MouseScroll }) => ({ ReactComponent: MouseScroll })),
  mparticle: () => import('./assets/mparticle.svg'),
  'new-survey': () => import('./NewSurvey').then(({ NewSurvey }) => ({ ReactComponent: NewSurvey })),
  'no-code': () => import('./NoCode').then(({ NoCode }) => ({ ReactComponent: NoCode })),
  notebook: () => import('@phosphor-icons/react').then(({ Notebook }) => ({ ReactComponent: Notebook })),
  note: () => import('@phosphor-icons/react').then(({ Note }) => ({ ReactComponent: Note })),
  'note-pencil': () => import('@phosphor-icons/react').then(({ NotePencil }) => ({ ReactComponent: NotePencil })),
  notion: () => import('./assets/notion.svg'),
  'notion-logo': () => import('./assets/notion-logo.svg'),
  'number-circle-one': () =>
    import('@phosphor-icons/react').then(({ NumberCircleOne }) => ({ ReactComponent: NumberCircleOne })),
  'number-circle-two': () =>
    import('@phosphor-icons/react').then(({ NumberCircleTwo }) => ({ ReactComponent: NumberCircleTwo })),
  'number-circle-three': () =>
    import('@phosphor-icons/react').then(({ NumberCircleThree }) => ({
      ReactComponent: NumberCircleThree,
    })),
  'number-circle-four': () =>
    import('@phosphor-icons/react').then(({ NumberCircleFour }) => ({ ReactComponent: NumberCircleFour })),
  optimizely: () => import('./assets/optimizely.svg'),
  palette: () => import('@phosphor-icons/react').then(({ Palette }) => ({ ReactComponent: Palette })),
  'paper-plane-tilt': () =>
    import('@phosphor-icons/react').then(({ PaperPlaneTilt }) => ({ ReactComponent: PaperPlaneTilt })),
  pause: () => import('@phosphor-icons/react').then(({ Pause }) => ({ ReactComponent: Pause })),
  pencil: () => import('@phosphor-icons/react').then(({ PencilSimple }) => ({ ReactComponent: PencilSimple })),
  people: () => import('./People').then(({ People }) => ({ ReactComponent: People })),
  'picture-in-picture': () =>
    import('@phosphor-icons/react').then(({ PictureInPicture }) => ({ ReactComponent: PictureInPicture })),
  pin: () => import('@phosphor-icons/react').then(({ PushPin }) => ({ ReactComponent: PushPin })),
  play: () => import('@phosphor-icons/react').then(({ Play }) => ({ ReactComponent: Play })),
  'play-circle': () => import('@phosphor-icons/react').then(({ PlayCircle }) => ({ ReactComponent: PlayCircle })),
  playlist: () => import('@phosphor-icons/react').then(({ Playlist }) => ({ ReactComponent: Playlist })),
  plug: () => import('./Plug').then(({ Plug }) => ({ ReactComponent: Plug })),
  plus: () => import('@phosphor-icons/react').then(({ Plus }) => ({ ReactComponent: Plus })),
  'plus-circle': () => import('@phosphor-icons/react').then(({ PlusCircle }) => ({ ReactComponent: PlusCircle })),
  'presentation-chart': () =>
    import('@phosphor-icons/react').then(({ PresentationChart }) => ({
      ReactComponent: PresentationChart,
    })),
  'prototype-test': () => import('./PrototypeTest').then(({ PrototypeTest }) => ({ ReactComponent: PrototypeTest })),
  'prototype-test-plain': () => import('./assets/prototype-test-plain.svg'),
  protopie: () => import('./assets/protopie.svg'),
  pulse: () => import('@phosphor-icons/react').then(({ Activity }) => ({ ReactComponent: Activity })),
  'public-api': () => import('./assets/public-api.svg'),
  'qr-code': () => import('@phosphor-icons/react').then(({ QrCode }) => ({ ReactComponent: QrCode })),
  question: () => import('@phosphor-icons/react').then(({ Question }) => ({ ReactComponent: Question })),
  'question-consent-legal': () => import('./assets/question-consent-legal.svg'),
  'question-matrix': () => import('./assets/question-matrix.svg'),
  'question-multiple-choice-single': () => import('./assets/question-multiple-choice-single.svg'),
  'question-multiple-choice-multi': () => import('./assets/question-multiple-choice-multi.svg'),
  'question-nps': () => import('./assets/question-nps.svg'),
  'question-open-text': () => import('./assets/question-open-text.svg'),
  'question-rating-scale': () => import('./assets/question-rating-scale.svg'),
  'question-recorded-task': () => import('./assets/question-recorded-task.svg'),
  'question-text-url': () => import('./assets/question-text-url.svg'),
  'question-video-voice': () => import('./assets/question-video-voice.svg'),
  'react-native': () => import('./assets/react-native.svg'),
  redo: () => import('@phosphor-icons/react').then(({ ArrowClockwise }) => ({ ReactComponent: ArrowClockwise })),
  refresh: () =>
    import('@phosphor-icons/react').then(({ ArrowsCounterClockwise }) => ({ ReactComponent: ArrowsCounterClockwise })),
  'replay-badge': () => import('./assets/replay-badge.svg'),
  'replay-plain': () => import('./assets/replay-plain.svg'),
  replay: () => import('./assets/replay.svg'),
  'replay-fast-forward': () => import('./assets/replay-fast-forward.svg'),
  'replay-rewind': () => import('./assets/replay-rewind.svg'),
  'robot-black': () => import('./assets/robot-black.svg'),
  'robot-yellow': () => import('./assets/robot-yellow.svg'),
  rocket: () => import('@phosphor-icons/react').then(({ RocketLaunch }) => ({ ReactComponent: RocketLaunch })),
  rudderstack: () => import('./assets/rudderstack.svg'),
  scan: () => import('@phosphor-icons/react').then(({ Scan }) => ({ ReactComponent: Scan })),
  'seal-warning': () => import('@phosphor-icons/react').then(({ SealWarning }) => ({ ReactComponent: SealWarning })),
  segment: () => import('./assets/segment.svg'),
  selection: () => import('@phosphor-icons/react').then(({ Selection }) => ({ ReactComponent: Selection })),
  'selection-all': () => import('@phosphor-icons/react').then(({ SelectionAll }) => ({ ReactComponent: SelectionAll })),
  'selection-foreground': () =>
    import('@phosphor-icons/react').then(({ SelectionForeground }) => ({ ReactComponent: SelectionForeground })),
  send: () => import('./assets/send.svg'),
  settings: () => import('./Settings').then(({ Settings }) => ({ ReactComponent: Settings })),
  share: () => import('@phosphor-icons/react').then(({ Share }) => ({ ReactComponent: Share })),
  'sign-out': () => import('@phosphor-icons/react').then(({ SignOut }) => ({ ReactComponent: SignOut })),
  sketch: () => import('./assets/sketch.svg'),
  slider: () => import('@phosphor-icons/react').then(({ CodeSimple }) => ({ ReactComponent: CodeSimple })),
  slack: () => import('./assets/slack.svg'),
  'slack-no-background': () => import('./assets/slack-no-background.svg'),
  sliders: () => import('@phosphor-icons/react').then(({ Sliders }) => ({ ReactComponent: Sliders })),
  'sliders-horizontal': () =>
    import('@phosphor-icons/react').then(({ SlidersHorizontal }) => ({ ReactComponent: SlidersHorizontal })),
  'smiley-1': () => import('./assets/smiley-1.svg'),
  'smiley-2': () => import('./assets/smiley-2.svg'),
  'smiley-3': () => import('./assets/smiley-3.svg'),
  'smiley-4': () => import('./assets/smiley-4.svg'),
  'smiley-5': () => import('./assets/smiley-5.svg'),
  'smiley-6': () => import('./assets/smiley-6.svg'),
  'smiley-7': () => import('./assets/smiley-7.svg'),
  'smiley-8': () => import('./assets/smiley-8.svg'),
  'smiley-9': () => import('./assets/smiley-9.svg'),
  'smiley-10': () => import('./assets/smiley-10.svg'),
  'smiley-11': () => import('./assets/smiley-11.svg'),
  'sprig-circle': () => import('./assets/sprig-circle.svg'),
  sparkle: () => import('@phosphor-icons/react').then(({ Sparkle }) => ({ ReactComponent: Sparkle })),
  'sprig-ai-logo': () => import('./assets/sprig-ai-logo.svg'),
  'sprig-ai-user': () => import('./assets/sprig-ai-user.svg'),
  'sprig-ai-user-blackwhite': () => import('./assets/sprig-ai-user-blackwhite.svg'),
  'sprig-logo': () => import('./assets/sprig-logo.svg'),
  'squares-four': () => import('@phosphor-icons/react').then(({ SquaresFour }) => ({ ReactComponent: SquaresFour })),
  star: () => import('@phosphor-icons/react').then(({ Star }) => ({ ReactComponent: Star })),
  storefront: () => import('@phosphor-icons/react').then(({ Storefront }) => ({ ReactComponent: Storefront })),
  study: () => import('./assets/study.svg'),
  'study-type-ct': () => import('./assets/study-type-ct.svg'),
  'study-type-ips': () => import('./assets/study-type-ips.svg'),
  'study-type-ips-sparkle': () => import('./assets/study-type-ips-sparkle.svg'),
  'study-type-ss': () => import('./assets/study-type-ss.svg'),
  survey: () => import('./Survey').then(({ Survey }) => ({ ReactComponent: Survey })),
  tag: () => import('@phosphor-icons/react').then(({ Tag }) => ({ ReactComponent: Tag })),
  target: () => import('@phosphor-icons/react').then(({ Target }) => ({ ReactComponent: Target })),
  template: () => import('@phosphor-icons/react').then(({ Stack }) => ({ ReactComponent: Stack })),
  'toast-logo': () => import('./assets/toast-logo.svg'),
  'thumbs-up': () => import('@phosphor-icons/react').then(({ ThumbsUp }) => ({ ReactComponent: ThumbsUp })),
  'thumbs-down': () => import('@phosphor-icons/react').then(({ ThumbsDown }) => ({ ReactComponent: ThumbsDown })),
  'trend-up': () => import('@phosphor-icons/react').then(({ TrendUp }) => ({ ReactComponent: TrendUp })),
  timer: () => import('@phosphor-icons/react').then(({ Timer }) => ({ ReactComponent: Timer })),
  trash: () => import('@phosphor-icons/react').then(({ Trash }) => ({ ReactComponent: Trash })),
  trophy: () => import('@phosphor-icons/react').then(({ Trophy }) => ({ ReactComponent: Trophy })),
  twitter: () => import('@phosphor-icons/react').then(({ TwitterLogo }) => ({ ReactComponent: TwitterLogo })),
  'twitter-circle': () => import('./assets/twitter-circle.svg'),
  unarchive: () => import('./assets/unarchive.svg'),
  underline: () => import('@phosphor-icons/react').then(({ TextUnderline }) => ({ ReactComponent: TextUnderline })),
  undo: () =>
    import('@phosphor-icons/react').then(({ ArrowCounterClockwise }) => ({ ReactComponent: ArrowCounterClockwise })),
  union: () => import('./assets/union.svg'),
  upgrade: () => import('./assets/upgrade.svg'),
  'upload-simple': () => import('@phosphor-icons/react').then(({ UploadSimple }) => ({ ReactComponent: UploadSimple })),
  user: () => import('@phosphor-icons/react').then(({ User }) => ({ ReactComponent: User })),
  'user-chat-circle': () => import('./assets/user-chat-circle.svg'),
  'user-circle': () => import('@phosphor-icons/react').then(({ UserCircle }) => ({ ReactComponent: UserCircle })),
  'user-interviews': () => import('./assets/user-interviews.svg'),
  'user-minus': () => import('@phosphor-icons/react').then(({ UserMinus }) => ({ ReactComponent: UserMinus })),
  'user-plus': () => import('@phosphor-icons/react').then(({ UserPlus }) => ({ ReactComponent: UserPlus })),
  'users-three': () => import('@phosphor-icons/react').then(({ UsersThree }) => ({ ReactComponent: UsersThree })),
  wall: () => import('@phosphor-icons/react').then(({ Wall }) => ({ ReactComponent: Wall })),
  warning: () => import('@phosphor-icons/react').then(({ Warning }) => ({ ReactComponent: Warning })),
  'warning-circle': () =>
    import('@phosphor-icons/react').then(({ WarningCircle }) => ({ ReactComponent: WarningCircle })),
  webhooks: () => import('./assets/webhooks.svg'),
  wrench: () => import('@phosphor-icons/react').then(({ Wrench }) => ({ ReactComponent: Wrench })),
  zapier: () => import('./assets/zapier.svg'),
} as const;

export const iconsKeys = Object.keys(iconsMap);

export type IconKey = keyof typeof iconsMap;
