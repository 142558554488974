/* eslint-disable */
// @ts-nocheck
import React, { memo, useState, useEffect, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { ClassNames } from '@emotion/react';
import styled from '@emotion/styled';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import ReactHoverObserver from 'react-hover-observer';
import { Manager, Reference, Popper } from 'react-popper';
import { Portal } from 'react-portal';
import { CSSTransition } from 'react-transition-group';

import { useOnClickOutside } from '../../hooks';
import { size, theme, zIndex, animations, FADE_INOUT_SECONDS, typography } from '../../styles';

export const POPUP_PLACEMENT_TYPES = {
  auto: 'auto',
  autoEnd: 'auto-end',
  autoStart: 'auto-start',
  bottom: 'bottom',
  bottomStart: 'bottom-start',
  bottomEnd: 'bottom-end',
  left: 'left',
  leftEnd: 'left-end',
  leftStart: 'left-start',
  right: 'right',
  rightEnd: 'right-end',
  rightStart: 'right-start',
  top: 'top',
  topStart: 'top-start',
  topEnd: 'top-end',
};

/**
 * @deprecated - use 'hover' | 'click' | 'none' instead
 */
export const POPUP_ON_TYPES = {
  hover: 'hover',
  click: 'click',
  none: 'none',
} as const;

const VERTICAL_OFFSET_SIZE = 10;
const DEFAULT_MODIFIERS = [
  {
    name: 'offset',
    options: {
      offset: [0, VERTICAL_OFFSET_SIZE],
    },
  },
  {
    name: 'preventOverflow',
    options: {
      padding: VERTICAL_OFFSET_SIZE,
    },
  },
];

export interface PopupProps {
  alwaysWrap?: boolean;
  animate?: boolean;
  animateFadeout?: boolean;
  arrow?: boolean;
  children: React.ReactNode;
  className?: string;
  closeForceOpen?: () => void;
  contentContainerStyle?: React.CSSProperties;
  disableClickOutside?: boolean;
  disabled?: boolean;
  forceHidePopup?: boolean;
  forceOpen?: boolean;
  handleHoverChange?: (isHovering: boolean) => void;
  id?: string;
  modifiers?: Record<string, unknown>[];
  on?: 'click' | 'hover' | 'none';
  onClose?: () => void;
  onOpen?: (event: React.MouseEvent) => void;
  open?: boolean;
  placement?: string;
  preventDefault?: boolean;
  referenceElement?: React.ReactNode;
  role?: string;
  strategy?: string;
  stopPropagation?: boolean;
  trigger: React.ReactNode;
  triggerContainerStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
}

const PopupComponent = ({
  alwaysWrap = false,
  animate,
  animateFadeout,
  arrow = true,
  children,
  className,
  closeForceOpen,
  contentContainerStyle,
  disabled,
  disableClickOutside = false,
  forceHidePopup = false,
  forceOpen = false,
  handleHoverChange,
  id,
  modifiers = [],
  on = 'hover',
  open: openPopup,
  onClose: handleOnClose,
  onOpen,
  placement = POPUP_PLACEMENT_TYPES.top,
  preventDefault,
  referenceElement,
  strategy,
  role,
  stopPropagation,
  trigger,
  triggerContainerStyle,
  wrapperStyle,
}: PopupProps) => {
  const [isOpenState, setIsOpenState] = useState(openPopup || false);

  const onClose = () => {
    if (isFunction(closeForceOpen)) closeForceOpen();
    setIsOpenState(false);
    handleOnClose?.();
  };

  const isOnClickAndOpen = on === 'click' && isOpenState && !disableClickOutside;
  const { ref: popperRef, isClickOutside } = useOnClickOutside(onClose, isOnClickAndOpen);

  const onTriggerClick = (event) => {
    !disabled &&
      on === 'click' &&
      openPopup === undefined &&
      !isClickOutside &&
      open(isOpenState, event, setIsOpenState, preventDefault, stopPropagation, onOpen);
  };

  useEffect(() => {
    if (forceOpen) setIsOpenState(true);
  }, [forceOpen]);

  useEffect(() => {
    if (openPopup !== undefined) setIsOpenState(openPopup);
  }, [openPopup]);

  modifiers = DEFAULT_MODIFIERS.concat(modifiers);
  const onHover = on === 'hover';
  const isOpen = !on || isOpenState;
  const isRenderProp = isFunction(children);
  const wrappedPopper = (
    <Wrapped as={Portal} isUsed={!onHover || alwaysWrap}>
      <Popper
        innerRef={popperRef}
        placement={placement}
        modifiers={modifiers}
        strategy={strategy}
        referenceElement={referenceElement}
      >
        {({ ref, style, placement, arrowProps, update }) => (
          <ContentContainer
            animate={animate}
            className={className}
            data-placement={placement}
            id={id}
            ref={ref}
            role={role}
            style={{ ...style, ...contentContainerStyle }}
          >
            {isRenderProp ? children({ onClose, update }) : children}
            {arrow && <Arrow ref={arrowProps.ref} style={arrowProps.style} placement={placement} />}
          </ContentContainer>
        )}
      </Popper>
    </Wrapped>
  );
  return (
    <ClassNames>
      {({ css }) => {
        const fadeAnimationClass = css`
          animation: ${FADE_INOUT_SECONDS}s ${animations.fadeOut} forwards ease-out;
        `;
        return (
          <HoverObserver isUsed={onHover} style={wrapperStyle}>
            {({ isHovering }) => {
              const showPopup = !forceHidePopup && (isOpen || (isHovering && onHover));

              return (
                <Manager>
                  <Reference>
                    {({ ref }) => (
                      <TriggerContainer
                        aria-expanded={isOpen}
                        aria-haspopup="true"
                        onClick={onTriggerClick}
                        ref={ref}
                        style={triggerContainerStyle}
                      >
                        {trigger}
                      </TriggerContainer>
                    )}
                  </Reference>
                  <HoverChangeWatcher isHovering={isHovering} handleHoverChange={handleHoverChange} />
                  {animateFadeout && (
                    <CSSTransition
                      in={showPopup}
                      unmountOnExit
                      timeout={FADE_INOUT_SECONDS * 1000}
                      classNames={{
                        exit: fadeAnimationClass,
                        exitActive: fadeAnimationClass,
                        exitDone: fadeAnimationClass,
                      }}
                    >
                      {wrappedPopper}
                    </CSSTransition>
                  )}
                  {!animateFadeout && showPopup && wrappedPopper}
                </Manager>
              );
            }}
          </HoverObserver>
        );
      }}
    </ClassNames>
  );
};

const open = (state, event, setIsOpenState, preventDefault, stopPropagation, onOpen) => {
  if (isFunction(onOpen)) onOpen(event);
  setIsOpenState(!state);
  if (preventDefault) event.preventDefault();
  if (stopPropagation) event.stopPropagation();
};

const Wrapped = ({ as, isUsed, children, ...props }) => (isUsed ? React.createElement(as, props, children) : children);

Wrapped.propTypes = {
  as: PropTypes.elementType.isRequired,
  isUsed: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

const HoverObserver = ({ isUsed, children, style }) => {
  return isUsed ? (
    <ReactHoverObserver className={cx(hoverContainerStyles, css(style))} inHoverMs={300}>
      {children}
    </ReactHoverObserver>
  ) : (
    children({ isHovering: false })
  );
};

HoverObserver.propTypes = {
  isUsed: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  style: PropTypes.object,
};

const HoverChangeWatcher = ({ isHovering, handleHoverChange }) => {
  const initialEffect = useRef(true);
  useEffect(() => {
    if (initialEffect.current) {
      initialEffect.current = false;
      return;
    }
    if (handleHoverChange) handleHoverChange(isHovering);
  }, [handleHoverChange, isHovering]);
  return <></>;
};

HoverChangeWatcher.propTypes = {
  isHovering: PropTypes.bool,
  handleHoverChange: PropTypes.func,
};

const sharedArrowStyles = `
  position: absolute;
  width: 12px;
  height: 12px;
  z-index: -1;
`;

const arrowStylesByPlacement = {
  bottom: `
    top: -7px;
    :before {
      transform: rotate(-135deg);
    }
  `,
  top: `
    bottom: -7px;
    :before {
      transform: rotate(45deg);
    }
  `,
  right: `
    left: -7px;
    :before {
      transform: rotate(135deg);
    }
  `,
  left: `
    right: -7px;
    :before {
      transform: rotate(-45deg);
    }
  `,
};

export const Arrow = styled.div`
  ${sharedArrowStyles}
  background: inherit;
  border-color: inherit;
  visibility: hidden;

  &:before {
    content: '';
    display: block;
    transform: rotate(45deg);
    border-width: 1px;
    border-right-style: solid;
    border-bottom-style: solid;
    ${sharedArrowStyles}
    background: inherit;
    border-color: inherit;
    visibility: visible;
    border-bottom-right-radius: 2px;
  }
  ${(p) => arrowStylesByPlacement[p.placement]}
`;

const ContentContainer = styled.div`
  ${typography.smallBody}
  animation: ${(p) => (p.animate ? 0.3 : 0)}s ${animations.fadeIn} forwards ease-out;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-color: ${theme.gray19};
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  max-width: 500px;
  padding: ${size(1.5)};
  z-index: ${zIndex.popup};

  &:before {
    content: '';
    width: calc(100% + 30px);
    height: 100%;
    display: block;
    position: absolute;
    top: -${VERTICAL_OFFSET_SIZE}px;
    left: -${VERTICAL_OFFSET_SIZE}px;
    padding: ${VERTICAL_OFFSET_SIZE}px 0;
    box-sizing: content-box;
    z-index: -1;
  }
`;

const hoverContainerStyles = css`
  display: inline-block;
`;

const TriggerContainer = styled.div`
  display: inline-block;
  user-select: none;
  height: inherit;
  width: inherit;
`;

export const Popup = memo(PopupComponent);
