import React, { forwardRef } from 'react';

import styled from '@emotion/styled';

import { size } from '../../styles';
import { IconKey } from '../Icon';

import { ButtonProps, Loader, StyledButton, StyledIcon, useButtonProps } from './Button';

export type IconButtonProps = Omit<ButtonProps, 'children' | 'endIcon' | 'startIcon'> & {
  'aria-label': string;
  filled?: boolean;
  icon: IconKey;
};

/**
 * IconButton
 * @description A type of button that only renders an icon
 */
export const IconButton = forwardRef(
  ({ icon, filled, variant = 'tertiary', ...props }: IconButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const { loading, ...buttonProps } = useButtonProps(props);

    return (
      <StyledIconButton variant={variant} {...buttonProps} ref={ref}>
        {loading ? <Loader /> : icon ? <StyledIcon src={icon} variant={filled ? 'fill' : 'bold'} /> : ''}
      </StyledIconButton>
    );
  }
);

const StyledIconButton = styled(StyledButton)`
  padding: 0;
  min-width: ${size(5)};
  ${(p) => p.size === 'small' && `min-width: ${size(4)}`};
`;
