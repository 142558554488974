import { gold } from './gold';
import { indigo } from './indigo';
import { magenta } from './magenta';
import { turquoise } from './turquoise';

export const semantic = {
  infoBackground: indigo[200],
  infoText: indigo[2000],
  headsUpBackground: gold[200],
  headsUpText: gold[2000],
  negativeBackground: magenta[200],
  negativeBorder: magenta[1000],
  negativeText: magenta[2000],
  positiveBackground: turquoise[200],
  positiveText: turquoise[2000],
  link: indigo[1000],
} as const;
