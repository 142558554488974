import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';

export interface ResizeContextValues {
  height: number;
  width: number;
}

export const ResizeContext = createContext<ResizeContextValues>({ height: 0, width: 0 });

interface ResizeContextProps {
  /**
   * The child elements to be rendered within this component.
   */
  children: React.ReactNode;
}

/**
 * Context provider for managing the resizing of elements within a component.
 */
export const ResizeContextProvider = ({ children }: ResizeContextProps) => {
  const [{ width, height }, setDimensions] = useState({ height: window.innerHeight, width: window.innerWidth });

  useEffect(() => {
    const listener = () => {
      setDimensions({ height: window.innerHeight, width: window.innerWidth });
    };

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  const value = useMemo(() => ({ width, height }), [width, height]);
  return <ResizeContext.Provider value={value}>{children}</ResizeContext.Provider>;
};

/**
 * A custom hook for consuming the `ResizeContext` context within a React component.
 */
export const useResizeContext = () => useContext(ResizeContext);
