import { background } from './background';
import { border } from './border';
import { common } from './common';
import { cream } from './cream';
import { gold } from './gold';
import { icons } from './icons';
import { indigo } from './indigo';
import { intensity } from './intensity';
import { lavender } from './lavender';
import { magenta } from './magenta';
import { mango } from './mango';
import { navy } from './navy';
import { orange } from './orange';
import { pink } from './pink';
import { semantic } from './semantic';
import { shadow } from './shadow';
import { status, surveyStatus } from './status';
import { theme } from './theme';
import { turquoise } from './turquoise';
import { typography } from './typography';

const colors = {
  background,
  border,
  common,
  cream,
  gold,
  icons,
  indigo,
  intensity,
  lavender,
  magenta,
  mango,
  navy,
  orange,
  pink,
  semantic,
  shadow,
  status,
  surveyStatus,
  theme,
  turquoise,
  typography,
} as const;

export { background, border, colors, semantic, status, theme };

export { getTextColor } from './utils';
