export enum EventActionError {
  ActiveSurveyUse = 'ActiveSurveyUse',
  EventNotFound = 'EventNotFound',
  DisplayNameInUse = 'DisplayNameInUse',
  LabelInUse = 'LabelInUse',
  DifferentEnv = 'DifferentEnv',
  ValidationFailed = 'ValidationFailed',
}

export enum MissingEventAction {
  Accept = 'accept',
  Deny = 'deny',
}
