import { gold } from './gold';
import { indigo } from './indigo';
import { navy } from './navy';
import { orange } from './orange';
import { turquoise } from './turquoise';

export const status = {
  positiveBackground: turquoise[200],
  positiveText: turquoise[2000],
  negativeBackground: orange[200],
  negativeText: orange[2000],
  infoBackground: indigo[200],
  infoText: indigo[2000],
  headsUpBackground: gold[200],
  headsUpText: gold[2000],
} as const;

// Survey Status is the key here
export const surveyStatus: { [key: number]: string } = {
  1: indigo[1000],
  2: status.headsUpText,
  3: turquoise[2000],
  4: navy[700],
  5: orange[2000],
  6: navy[700],
} as const;
