import { QueryCache, QueryClient } from '@tanstack/react-query';

import { axios } from './axios';
import { Toast } from 'twig';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: ({ queryKey: [url] }) => {
        if (typeof url === 'string') {
          return axios(url).then((res) => res.data);
        }
        throw new Error('Invalid queryKey provided');
      },
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (_, query) => {
      if (typeof query?.meta?.onError === 'function') {
        query.meta.onError();
      } else {
        Toast.error('Sorry, there was an error fetching from the server.');
      }
    },
    onSuccess: (data, query) => {
      if (typeof query?.meta?.onSuccess === 'function') {
        query.meta.onSuccess(data);
      }
    },
  }),
});
