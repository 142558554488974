import React, { forwardRef } from 'react';

import styled from '@emotion/styled';
import { type LocationState } from 'history';
import { Link as RouterLink } from 'react-router-dom';

import { size, theme, typography } from '../../styles';
import { StyledIcon } from '../Button/Button';

type LocationTo = string | LocationState;

export type LinkProps = (
  | {
      /** Links that are external
       * @example https://www.google.com
       */
      href?: string;
      to?: never;
    }
  | {
      href?: never;
      /** Routes to some page in the app
       * @example /dashboard
       */
      to?: LocationTo;
    }
) &
  Omit<React.ComponentPropsWithoutRef<'a'>, 'href'>;

/** Link
 * @description A anchor/link component that can be used to route to a page in the app or an external link
 */
export const Link = forwardRef(({ children, ...props }: LinkProps, ref: React.Ref<HTMLAnchorElement>) => {
  return (
    <StyledLink
      as={props.to ? RouterLink : undefined}
      {...props}
      {...getLinkProps(props)}
      href={!(props.href || props.to) ? '#' : props.href}
      ref={ref}
    >
      <span>{children}</span>
      {props.href && <StyledIcon src="arrow-up-right" />}
    </StyledLink>
  );
});

const URL_REGEX =
  /https?:\/\/(?:www\.)?(localhost|(?:[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b))(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)/;

export const getLinkProps = (props: {
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  to?: LocationTo;
  as?: React.ElementType;
}): {
  as?: React.ElementType;
  href?: string;
  rel?: string;
  target?: React.HTMLAttributeAnchorTarget;
  to?: LocationTo;
} => {
  const { href, to, as } = props;

  const elType = href ? 'a' : undefined;
  const isExternal = Boolean(href && URL_REGEX.test(href));

  return {
    as: as || to ? RouterLink : elType,
    ...(isExternal
      ? {
          rel: href ? 'noopener noreferrer' : undefined,
          target: props.target || (href ? '_blank' : undefined),
        }
      : {}),
  };
};

export const StyledLink = styled.a`
  ${typography.smallBodyBold};
  font-size: inherit;
  align-items: center;
  border-bottom: 2px solid ${theme.indigo[1000]};
  color: ${theme.indigo[1000]};
  display: inline-flex;
  gap: ${size(0.5)};
  text-decoration: none !important;
  vertical-align: middle;
  width: fit-content;

  & > span {
  }

  &:hover {
    border-color: ${theme.indigo[2000]};
    color: ${theme.indigo[2000]};
  }
`;
