import React, { forwardRef } from 'react';

import styled from '@emotion/styled';

import { elevation, theme } from '../../../styles';
import { LabelWrapper } from '../LabelWrapper';

export interface ToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  labelPlacement?: 'start' | 'end';
}

const ToggleInner = (
  { className, disabled, label, labelPlacement, onChange, ...props }: ToggleProps,
  ref: React.Ref<HTMLInputElement>
) => {
  return (
    <LabelWrapper className={className} disabled={disabled} labelPlacement={labelPlacement}>
      <Wrapper>
        <StyledInput disabled={disabled} onChange={onChange} ref={ref} type="checkbox" {...props} />
        <Box />
      </Wrapper>
      {label && <span>{label}</span>}
    </LabelWrapper>
  );
};

export const Toggle = forwardRef(ToggleInner);

const Wrapper = styled.div`
  height: 24px;
  position: relative;
  width: 36px;
`;
const StyledInput = styled.input`
  cursor: inherit;
  height: inherit;
  margin: 0;
  opacity: 0;
  position: relative;
  width: inherit;
  z-index: 1;

  &:checked {
    & + div {
      background-color: ${theme.gold[800]};
      border-color: ${theme.gold[1000]};

      &::before {
        transform: translateX(calc(50% + 2px));
      }
    }
  }

  &:active {
    & + div {
      background-color: ${theme.navy[300]};
    }
    &:checked + div {
      background-color: ${theme.gold[1000]};
    }
  }
  &:disabled {
    & + div {
      background-color: ${theme.navy[25]};
    }
    &:checked + div {
      background-color: ${theme.gold[300]};
      border-color: transparent;
    }
  }
  &:focus-visible {
    & + div {
      box-shadow: 0px 0px 0px 2px ${theme.navy[200]};
    }
  }

  /* Label affects the hover state so we would need to check for other states */
  &:hover:not(:active):not(:disabled):not(:focus-visible) {
    & + div {
      background-color: ${theme.navy[100]};
    }
    &:checked + div {
      background-color: ${theme.gold[900]};
    }
  }
`;
const Box = styled.div`
  background-color: ${theme.navy[50]};
  border: 1px solid transparent;
  border-radius: 40px;
  box-sizing: border-box;
  height: 100%;
  left: 0;
  position: absolute;
  transition: 0.4s;
  width: 100%;
  top: 0;

  /* Circle */
  &::before {
    background-color: white;
    border-radius: 50%;
    box-shadow: ${elevation.base1};
    content: '';
    height: 20px;
    left: 1px;
    position: absolute;
    top: 1px;
    transition: 0.4s;
    width: 20px;
  }
`;
