export const navy = {
  25: '#F9F9F8',
  50: '#EFEFEE',
  100: '#E2E3E1',
  200: '#C8CCCC',
  300: '#B0B5B7',
  400: '#989FA2',
  500: '#808A8E',
  600: '#68757A',
  700: '#506067',
  800: '#3C4F59',
  900: '#233843',
  1000: '#0B2330',
};
