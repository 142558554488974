import styled from '@emotion/styled';

import { size, roundBorderLight, roundBorderMedium, roundBorderDark, typography } from '../../../styles';
import { LabelWrapper } from '../LabelWrapper';

export enum DisplayVariant {
  grid = 'grid',
  flex = 'flex',
}

export const Fieldset = styled.fieldset<{ maxHeight?: string | number; display: DisplayVariant; columns: number }>`
  border: none;
  width: 100%;
  padding: unset;
  margin: unset;
  gap: ${size(1)};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
  ${({ display, columns }) =>
    display === DisplayVariant.grid
      ? `
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
  `
      : `
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
  `}

  & > legend {
    padding-bottom: ${size(1)};
  }
`;

export const StyledLabelWrapper = styled(LabelWrapper)<{ checked?: boolean }>`
  ${({ checked }) => (checked ? roundBorderDark : roundBorderLight)}

  &:hover {
    ${roundBorderMedium}
  }

  padding: ${size(2)};
  justify-content: center;
`;

export const StyledLabel = styled.div<{ checked?: boolean }>`
  ${({ checked }) => (checked ? typography.bodyBold : typography.body)}
`;

export const Wrapper = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && 'cursor: not-allowed;'}
`;

export const PillInner = styled.input`
  display: none;
`;
