import React, { forwardRef } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconWeight } from '@phosphor-icons/react';

import { elevation, size, theme, typography } from '../../styles';
import { propValidator } from '../../utils';
import { Icon, IconKey, iconsMap } from '../Icon';
import { LinkProps } from '../Link';
import { getLinkProps } from '../Link/Link';
import { Spinner } from '../LoadingIndicator';

// NOTE: Using [disabled] instead :disabled to have it work for both <button> and <a>
export const buttonBaseCss = css`
  ${typography.bodyBold};
  align-items: center;
  background-color: ${theme.gold[800]};
  border-radius: 8px;
  border: 1px solid ${theme.gold[1000]};
  box-shadow: ${elevation.base1};
  box-sizing: border-box;
  color: ${theme.primary};
  display: inline-flex;
  gap: 8px;
  height: fit-content;
  justify-content: center;
  margin: 0;
  min-height: ${size(5)};
  padding: 0 ${size(2)};
  text-decoration: none;
  white-space: nowrap;
  width: fit-content;

  &:active,
  &[aria-pressed='true'] {
    background-color: ${theme.gold[1000]};
  }
  &[disabled]:not([data-loading]) {
    opacity: 0.2;
  }
  &:focus-visible {
    box-shadow: 0px 0px 0px 2px ${theme.navy[200]};
    outline: none;
  }
  &:hover:not(:active):not([disabled]) {
    background-color: ${theme.gold[900]};
  }
`;

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary';

export type ButtonProps = {
  // eslint-disable-next-line
  as?: any;
  children: React.ReactNode;
  endIcon?: IconKey | false;
  fullWidth?: boolean;
  loading?: boolean;
  iconVariant?: IconWeight;
  size?: 'small' | 'default';
  startIcon?: IconKey | false | React.ReactElement;
  variant?: ButtonVariant;
} & React.ComponentPropsWithoutRef<'button'> &
  Omit<LinkProps, 'onClick'>;

const isValidElement = (key: IconKey | false | React.ReactElement): key is React.ReactElement =>
  React.isValidElement(key);

const getStartIcon = (startIcon?: IconKey | false | React.ReactElement, iconVariant?: IconWeight) => {
  if (startIcon) {
    if (isValidElement(startIcon)) return startIcon;
    if (startIcon in iconsMap) return <StyledIcon variant={iconVariant} src={startIcon} />;
  }
  return null;
};

/**
 * Button
 * @description An enhanced button component that can be used as a link or a button
 */
export const Button = forwardRef(
  (
    { as, children, endIcon, iconVariant, startIcon, variant = 'primary', ...props }: ButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const { loading, ...buttonProps } = useButtonProps(props);

    const Loading = <Loader />;
    const showAtStart = !endIcon || startIcon;

    return (
      <StyledButton variant={variant} {...buttonProps} ref={ref} {...(as ? { as } : {})}>
        {loading && showAtStart ? Loading : getStartIcon(startIcon, iconVariant)}
        <span>{children}</span>
        {loading && !showAtStart ? Loading : endIcon && <StyledIcon variant={iconVariant} src={endIcon} />}
      </StyledButton>
    );
  }
);

export const useButtonProps = ({ disabled, loading = false, ...props }: Omit<ButtonProps, 'children'>) => {
  return {
    disabled: disabled || loading,
    loading,
    type: !(props.href || props.to) ? ('button' as const) : undefined,
    ...props,
    ...getLinkProps(props),
    'data-loading': loading ? '' : undefined,
  };
};

export const StyledButton = styled('button', { shouldForwardProp: propValidator })<ButtonProps>`
  ${buttonBaseCss};
  ${(p) => p.size === 'small' && typography.smallBodyBold};
  ${(p) =>
    p.size === 'small' &&
    `
    min-height: ${size(4)};
    padding: 0px 10px;
  `};

  ${(p) => {
    // Secondary Styles
    if (p.variant === 'secondary') {
      return `
        background-color: ${theme.white};
        border-color: ${theme.navy[100]};
        color: ${theme.primary};

        &:active, &[aria-pressed='true'] {
          background-color: ${theme.navy[100]};
        }
        &[disabled]:not([data-loading]) {
          background-color: ${theme.white};
          color: ${theme.disabled};
          box-shadow: none;
          opacity: 1;
        }
        &:hover:not(:active):not([disabled]) {
          background-color: ${theme.navy[50]};
        }
      `;
    }

    // Tertiary Styles
    if (p.variant === 'tertiary') {
      return `
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        color: ${theme.primary};

        &:active, &[aria-pressed='true'] {
          background-color: ${theme.navy[100]};
          mix-blend-mode: multiply;
        }
        &[disabled]:not([data-loading]) {
          background-color: transparent;
          color: ${theme.disabled};
          opacity: 1;
        }
        &:hover:not(:active):not([disabled]) {
          background-color: ${theme.navy[50]};
          mix-blend-mode: multiply;
        }
      `;
    }
  }};

  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  ${(p) => p.fullWidth && 'width: 100%'};
`;

// shared
export const Loader = () => <Spinner color={theme.primary} size={size(2)} band="1.5px" />;

export const StyledIcon = styled(Icon)`
  color: inherit;
  flex-shrink: 0;
  height: 16px;
  pointer-events: none;
  width: 16px;
`;
