import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { environment, isBrowser } from '@sprigShared/environment';
import { DASHBOARD_API_HEADER } from '@sprigShared/routing-constants';
import { Toast, AuthMessage } from 'twig';

import { AuthToken } from './tokens';

const TOAST_CONFIG = {
  // specifying toastId ensures only 1 toast of this type ever appears
  toastId: 'no-duplicate-api',
};

const instance = axios.create({
  baseURL: environment.apiUrl,
  headers: {
    Authorization: `Bearer ${AuthToken.get()}`,
    ...DASHBOARD_API_HEADER,
  },
});

instance.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${AuthToken.get()}`;
  // Always return config, so other downstream interceptors can act
  return config;
});

instance.interceptors.response.use(undefined, (error: AxiosError) => {
  if (error.config?.noErrHandling) return error.response || { data: undefined, status: null };

  const unauthorizedStatus = ('status' in error && error.status === 401) || error?.response?.status === 401;
  if (unauthorizedStatus && isBrowser()) {
    Toast.error(AuthMessage, {
      ...TOAST_CONFIG,
      onClose: () => {
        if (isBrowser()) {
          window.location.href = window.location.origin + '/login';
        }
      },
    });
  }

  return Promise.reject(error);
});

export { instance as axios };

type NetworkErr = { data: undefined; status: null };
type NoErrHandlingConfig = AxiosRequestConfig & { noErrHandling: true };

declare module 'axios' {
  export interface AxiosRequestConfig {
    noErrHandling?: boolean;
  }
  export interface AxiosInstance {
    delete<T = undefined, R = AxiosResponse<T>>(
      url: string,
      data: unknown,
      config: NoErrHandlingConfig
    ): Promise<R | NetworkErr>;
    get<T = undefined, R = AxiosResponse<T>>(
      url: string,
      data: unknown,
      config: NoErrHandlingConfig
    ): Promise<R | NetworkErr>;
    patch<T = undefined, R = AxiosResponse<T>>(
      url: string,
      data: unknown,
      config: NoErrHandlingConfig
    ): Promise<R | NetworkErr>;
    post<T = undefined, R = AxiosResponse<T>>(
      url: string,
      data: unknown,
      config: NoErrHandlingConfig
    ): Promise<R | NetworkErr>;
    put<T = undefined, R = AxiosResponse<T>>(
      url: string,
      data: unknown,
      config: NoErrHandlingConfig
    ): Promise<R | NetworkErr>;
  }
}
