export const orange = {
  100: '#FEEEEA',
  200: '#FEDED5',
  300: '#FDCDC0',
  400: '#FDBDAB',
  500: '#FCAC96',
  600: '#FC9B82',
  700: '#FB8B6D',
  800: '#FB7A58',
  900: '#FA6A43',
  1000: '#FA592E',
  2000: '#6B392F',
};
