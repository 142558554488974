import React from 'react';

import styled from '@emotion/styled';

import { size, theme } from '../../styles';

interface ErrorBoundaryProps {
  children?: React.ReactNode;
  errorTitle?: string;
  errorMessage?: string;
  iconSrc?: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error & { code?: string };
  errorInfo?: { componentStack?: string };
}

export const ErrorWrapper = styled.section`
  padding: ${size(2)};
  width: 100%;
  margin: auto;
  background-color: ${theme.redBacking};
  border-radius: ${size(0.5)};
  border: ${size(0.25)} solid ${theme.redBorder};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: scroll;

  & > div {
    max-width: 100%;
  }

  & p {
    margin-bottom: ${size(0.5)};
  }
`;

export class IconErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
      errorInfo: undefined,
    };
  }

  static getDerivedStateFromError(error: Error & { code?: string }) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error & { code?: string }, errorInfo: { componentStack?: string }) {
    console.error({ iconSrc: this.props.iconSrc, error, errorInfo });
    if (!errorInfo) this.setState({ errorInfo });
  }

  render() {
    const { errorTitle, iconSrc } = this.props;
    const { errorInfo, error } = this.state;
    if (this.state.hasError) {
      return (
        <ErrorWrapper>
          <p>Error with requested Icon {iconSrc}</p>
          {errorTitle}
          <p>{error?.message}</p>
          <div>
            {errorInfo?.componentStack?.split('\n').map((m: string, i: number) => (
              <p key={`${m}_${i}`}>{m}</p>
            ))}
          </div>
        </ErrorWrapper>
      );
    }

    return this.props.children;
  }
}
