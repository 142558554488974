import styled from '@emotion/styled';

import { theme, size } from '../../styles';

// TODO: add more options and colors redefine text colors (maybe preset pill types)
export const Pill = styled.div<{ color?: string; textColor?: string }>`
  padding: ${size(0.5)} ${size(1)};
  border-radius: 50px;
  background-color: ${({ color = theme.brand }) => color};
  color: ${({ textColor = 'inherit' }) => textColor};
  font-size: ${size(1.5)};
  text-align: center;
  height: ${size(3)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
`;
