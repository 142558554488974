import React from 'react';

import styled from '@emotion/styled';

import { DEVICE_TYPE } from '@sprigShared/survey-common-constants';
import { colors, size } from 'twig';

import { DeviceContainer } from '../DeviceContainer';

import DesktopBackground from './desktop-background.svg';
import MobileBackground from './mobile-background.svg';

const SizedDeviceContainer = styled(DeviceContainer)`
  height: 700px;
`;

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
`;

const MessageBox = styled.div<{ isDesktop: boolean }>`
  margin-top: ${size(5)};
  padding: ${size(3)};
  border-radius: ${size(1)};
  border: 1px solid ${colors.border.light};

  background-color: ${colors.common.white};
  ${({ isDesktop }) => (isDesktop ? `max-width: 50%;` : `margin: ${size(5)};`)}
`;

export const EmptyState = ({ device, children }: { device: DEVICE_TYPE; children: React.ReactNode }) => {
  const isDesktop = device === DEVICE_TYPE.DESKTOP;
  return (
    <SizedDeviceContainer device={device} backgroundImage={isDesktop ? DesktopBackground : MobileBackground}>
      <MessageContainer>
        <MessageBox isDesktop={isDesktop}>{children}</MessageBox>
      </MessageContainer>
    </SizedDeviceContainer>
  );
};
