import React, { ForwardedRef, HTMLAttributes } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { theme } from '../../styles';
import { Icon, type IconKey } from '../Icon';

type InputType = React.HTMLInputTypeAttribute;

type InputProps<T extends InputType = 'text'> = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'max' | 'min' | 'onChange' | 'type' | 'value'
> & {
  canClear?: boolean;
  canCopy?: boolean;
  clear?: boolean;
  debounce?: boolean;
  debounceTimeout?: number;
  error?: boolean;
  helpText?: string;
  icon?: IconKey;
  label?: string;
  labelHidden?: boolean;
  max?: number | string | null;
  min?: number | string | null;
  onChange?: (value: never, event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent) => void;
  preText?: string;
  ref?: ForwardedRef<HTMLInputElement>;
  smallLabel?: boolean;
  tooltip?: React.ReactNode;
  type?: T;
  value?: React.InputHTMLAttributes<HTMLInputElement>['value'] | null;
  wrapperProps?: HTMLAttributes<HTMLDivElement>;
};

export const iconBaseCss = css`
  background-color: ${theme.gray2};
  border-radius: 4px;
  border: 0;
  border: 1px solid transparent;
  box-shadow: none;
  font-size: 1em;
  line-height: 28px;
  padding: 4px 10px;
  transition: none;
  width: 100%;

  &:hover,
  &:focus,
  &:disabled:hover {
    border-color: ${theme.gray5};
  }

  &:disabled,
  &:focus {
    background-color: ${theme.gray2};
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &[aria-invalid='true'] {
    border: 1px solid ${theme.red};
    border-radius: 4px;
    box-shadow: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus input:-webkit-autofill {
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const FieldWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: fit-content;
  position: relative;
  width: 100%;
`;

export const StyledInput = styled.input<Pick<InputProps, 'canCopy' | 'canClear' | 'icon'>>`
  ${iconBaseCss}
  ${({ icon }) =>
    icon &&
    `
    padding-left: 37px;
  `};
  ${({ canCopy, canClear }) =>
    (canCopy || canClear) &&
    `
    overflow: hidden;
    padding-right: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

export const HelpText = styled.div`
  margin-top: 0.25em;
  font-size: 0.875em;
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  color: ${theme.gray8};
`;

export const PreText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 16px;
  min-width: fit-content;
`;

export const StyledIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-right: -30px;
  margin-left: 14px;
  z-index: 1;
  color: ${theme.gray11};
`;
