import React from 'react';

import styled from '@emotion/styled';
import round from 'lodash/round';

import { colors, getTextColor, size } from '../../styles';

export interface ProgressBarProps {
  className?: string;
  color?: string;
  colorBackground?: string;
  decimals?: number;
  hideLabel?: boolean;
  label?: string;
  labelClassName?: string;
  max?: number;
  min?: number;
  now: number;
  linearEase?: number;
}

const Container = styled.div<{ colorBackground: string }>`
  width: 100%;
  height: ${size(3)};
  background-color: ${({ colorBackground }) => colorBackground};
  border-radius: ${size(2)};
  overflow-x: clip;
`;

const Bar = styled.div<{ value: number; color: string; linearEase: number }>`
  background-color: ${({ color }) => color};
  border-radius: ${size(2)};
  height: 100%;
  width: ${({ value }) => value}%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width ${({ linearEase }) => linearEase}s linear;
`;

const Label = styled.span<{ hideLabel?: boolean; textColor: string }>`
  color: ${({ textColor }) => textColor};
  ${({ hideLabel }) =>
    hideLabel &&
    `
      clip: rect(0,0,0,0);
      border: 0;
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      white-space: nowrap;
      width: 1px;
    `}
`;

export const ProgressBar = ({
  className,
  color = colors.indigo[1000],
  colorBackground = colors.semantic.infoBackground,
  decimals = 0,
  hideLabel = false,
  label,
  labelClassName,
  max = 100,
  min = 0,
  linearEase = 0.25,
  now,
}: ProgressBarProps) => {
  const value = round((now / (max - min)) * 100, decimals);
  const textLabel = label || `${value}%`;
  const textColor = getTextColor(color);
  return (
    <Container className={className} colorBackground={colorBackground}>
      <Bar
        linearEase={linearEase}
        color={color}
        value={value}
        role="progressbar"
        aria-valuenow={now}
        aria-valuemin={min}
        aria-valuemax={max}
      >
        <Label textColor={textColor} hideLabel={hideLabel} className={labelClassName}>
          {textLabel}
        </Label>
      </Bar>
    </Container>
  );
};
