import { useCallback, useMemo } from 'react';

import queryString, { ParseOptions, StringifyOptions } from 'query-string';
import { useHistory } from 'react-router-dom';

const stringifyFormats: StringifyOptions = {
  arrayFormat: 'bracket',
  encode: false,
  skipEmptyString: true,
};

const parseFormats: ParseOptions = {
  ...stringifyFormats,
  parseBooleans: true,
  parseNumbers: true,
};

export const useQueryParam = <T>(paramKey: string, initialValue?: T) => {
  const history = useHistory();

  const search = history.location.search;
  const params = useMemo(() => {
    const newParams = queryString.parse(search, parseFormats);
    if (newParams[paramKey] === undefined && initialValue !== undefined) {
      // @ts-expect-error - want to use initialValue for generic type
      newParams[paramKey] = initialValue;
    }
    return newParams;
  }, [initialValue, paramKey, search]);

  const setSearchParam = useCallback(
    (value?: T) => {
      const newParams = queryString.parse(history.location.search, parseFormats);

      const isDeleted = value === undefined;

      if (isDeleted) delete newParams[paramKey];
      // @ts-expect-error - want to set value for generic type
      else newParams[paramKey] = value;

      history.replace({
        search: queryString.stringify(newParams, stringifyFormats),
        ...(isDeleted ? { state: { removeSearch: paramKey } } : {}),
      });
    },
    [history, paramKey]
  );

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const key: T = <T>(<unknown>params[paramKey]);

  return [key, setSearchParam] as const;
};
