import React, { useId } from 'react';

import styled from '@emotion/styled';

import { IconButton } from '../../components';
import { colors, size, typography } from '../../styles';

export enum DocBlockVariant {
  HeadsUp,
  Info,
  Neutral,
}

export interface DocBlockProps {
  children: React.ReactNode;
  onClose?: () => void;
  title: string;
  variant?: DocBlockVariant;
}

export const DocBlock = ({ children, onClose, title, variant = DocBlockVariant.Neutral }: DocBlockProps) => {
  const titleID = useId();
  return (
    <Wrapper variant={variant} aria-labelledby={titleID}>
      <TitleWrapper>
        <Title id={titleID}>{title}</Title>
        {onClose && <IconButton aria-label="Close" icon="close" onClick={onClose} />}
      </TitleWrapper>
      <Description>{children}</Description>
    </Wrapper>
  );
};

const Wrapper = styled.section<{ variant: DocBlockVariant }>`
  border-radius: ${size(0.5)};
  border-width: 1.5px;
  border-style: solid;
  padding: ${size(2)};
  ${(p) => {
    switch (p.variant) {
      case DocBlockVariant.HeadsUp:
        return `
          background-color: ${colors.semantic.headsUpBackground};
          border-color: ${colors.semantic.headsUpText};
          color: ${colors.semantic.headsUpText};
        `;
      case DocBlockVariant.Info:
        return `
          background-color: ${colors.semantic.infoBackground};
          border-color: ${colors.semantic.infoText};
          color: ${colors.semantic.infoText};
        `;
      default:
        return `
          background-color: ${colors.cream[300]};
          border-color: ${colors.border.light};
          color: ${colors.typography.primary};
        `;
    }
  }}
`;
const TitleWrapper = styled.div`
  display: flex;
  min-height: 40px;
  justify-content: space-between;
`;
const Title = styled.h2`
  ${typography.h4};
`;
const Description = styled.div`
  ${typography.body};
`;
