import { common } from './common';
import { navy } from './navy';

export const background = {
  active: navy[100],
  primary: common.white,
  secondary: navy[25],
  selected: navy[50],
  dark: navy[1000],
};
