import { datadogRum } from '@datadog/browser-rum';

import { ANALYTICS_EVENTS } from './analyticsConstants';

// Store user locally for use in future Track events during session
type SegmentUser = {
  email: string;
  id: number;
  name: string;
  role: string;
  teamId: number;
};
let segmentUser: SegmentUser | null;

type DataDogUser = SegmentUser & { teamName?: string };

const ddSetUser = (props: DataDogUser) => datadogRum && datadogRum.setUser({ ...props, id: String(props.id) });

const loginSuccess = () => trackSprig(ANALYTICS_EVENTS.A_LOGIN_SUCCESS);

// Segment leverages the analytics.js library
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#getting-started
const loginStart = () => trackSegment(ANALYTICS_EVENTS.A_LOGIN_START);

const logout = () => {
  window.analytics?.track(ANALYTICS_EVENTS.A_LOGOUT, {}, () => {
    segmentUser = null;
    reset();
  });
};

const getOrCreateUserAnonymousId = (): string | undefined => {
  if (!window || !window.analytics || !window.analytics.user || !window.analytics.user()) return undefined;
  return window?.analytics?.user()?.anonymousId() || undefined;
};

const trackSegment = (eventName: string, properties = {}) => {
  if (!window || !window.analytics || !eventName) return;
  window.analytics.track(eventName, properties);
};

const pageSegment = (pageName: string, properties = {}) => {
  if (!window || !window.analytics) return;
  window.analytics.page(
    pageName,
    fillSegmentProperties({
      referrer: window.document.referrer,
      url: window.location.href,
      ...properties,
    })
  );
};

const trackSprig = (eventName: string, properties = {}) => {
  if (typeof eventName === 'string' && eventName.length > 0) window.Sprig('track', eventName, properties);
};

// Main track function to use across Dashboard where possible.
// All new track events should have a corresponding key added into ANALYTICS_EVENTS in analyticsConstants.js
const track = (eventName: string, properties = {}) => {
  if (!eventName) return;
  trackSegment(eventName, fillSegmentProperties(properties));
  trackSprig(eventName, properties);
};

const fillSegmentProperties = (properties: { [key: string]: string | number | boolean } = {}) => {
  // Todo: bring back environment once we have a better way to get it PDE-5740
  // const env = getCurrentEnv(); // Userleap's Product Environment (Prod or Dev)
  // if (env) properties.environment = env.get('name');
  // Add default current user properties to the track call
  properties.onboardingTestVariant = 'launchPad';

  if (segmentUser)
    properties = {
      ...properties,
      email: segmentUser.email || properties.email,
      userId: segmentUser.id,
      name: segmentUser.name,
      teamId: segmentUser.teamId,
      role: segmentUser.role,
      sprig_user_id: segmentUser.id, // this is the easiest way to send the user id to Hubspot and onto Salesforce.
    };
  return properties;
};

const identify = (userId: number, traits = {}) => {
  if (!window || !window.analytics) return;
  const { identify: segmentIdentify } = window.analytics;
  const allTraits = fillSegmentProperties(traits);
  userId ? segmentIdentify(userId, allTraits) : segmentIdentify(allTraits);
};

const identifyUser = (user: SegmentUser) => {
  const { id, name, email, role, teamId } = user;
  // Set local user data on successful loadUser call
  segmentUser = { id, name, email, role, teamId };

  window.Sprig('setUserId', id);
  window.Sprig('setEmail', email);
  window.Sprig('setAttribute', 'role', role);

  ddSetUser({ id, name, role, email, teamId });
  window.smartlook && window.smartlook('identify', id, { name, email });
};

const identifyUserAndTeam = ({
  userId,
  teamName,
  planName,
}: {
  userId: number;
  teamName: string;
  planName: string;
}) => {
  const properties = {
    teamName,
    planName,
  };

  window.Sprig('setAttribute', 'company', teamName);
  window.Sprig('setAttribute', 'plan', planName);

  identify(userId, properties);
};

const identifyAndView = (userId: number, traits: Record<string, boolean | number | string>, pageName: string) => {
  if (!pageName) return;
  identify(userId, traits);
  pageSegment(pageName);
};

// HubSpot Group call not supported for new API yet :-(
// https://segment.com/docs/connections/destinations/catalog/hubspot/#group
const group = (teamId: string, properties = {}) => {
  if (!window || !window.analytics || !teamId) return;
  window.analytics.group(teamId, properties);
};

const reset = () => {
  if (!window || !window.analytics) return;
  window.analytics.reset();
  window.Sprig('logoutUser');
};

export default {
  ddSetUser,
  identify,
  evt: ANALYTICS_EVENTS,
  identifyUser,
  identifyAndView,
  group,
  loginStart,
  loginSuccess,
  logout,
  reset,
  track,
  trackSegment,
  trackSprig,
  pageSegment,
  identifyUserAndTeam,
  getOrCreateUserAnonymousId,
};

export { ANALYTICS_EVENTS, track, pageSegment };
