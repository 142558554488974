import { css } from '@emotion/react';

import { theme } from '../../styles';

export const labelBaseCss = css`
  display: block;
  line-height: 20px;
  font-weight: normal;
  color: ${theme.gray10};
  margin-bottom: 0.5em;
  font-size: 1.075em;
  padding-bottom: 0;
  border-bottom: 'none';
  font-size: 14px;
  line-height: 145%;
  color: ${theme.gray12};
`;
