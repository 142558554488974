import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { type IconWeight, type Icon as TypeIcon } from '@phosphor-icons/react';

import { size } from '../../styles';

import { IconErrorBoundary } from './IconErrorBoundary';
import { iconsMap, type IconKey } from './icons';

export interface IconProps extends Omit<React.SVGProps<SVGSVGElement>, 'ref'> {
  alt?: string;
  className?: string;
  size?: number;
  src: IconKey;
  variant?: IconWeight;
}

const getSize = ({
  propSize,
  height,
  width,
}: {
  propSize: number;
  height?: string | number;
  width?: string | number;
}) => {
  if (height || width) {
    return {
      height,
      width,
    };
  }

  return {
    height: size(propSize),
    width: size(propSize),
  };
};

const FallbackDiv = styled.div<{ height?: string | number; width?: string | number }>`
  ${({ height, width }) => `
    height: ${height};
    width: ${width};
  `}
`;

const FallbackIcon = ({
  src,
  size: propSize = 2,
  height,
  width,
  className,
}: Pick<IconProps, 'src' | 'className' | 'size' | 'height' | 'width'>) => {
  return <FallbackDiv data-testid={`icon-${src}`} className={className} {...getSize({ propSize, width, height })} />;
};

export const IconComponentWrapper = ({
  src,
  height,
  width,
  variant = 'bold',
  size: propSize = 2,
  ...props
}: IconProps) => {
  const [componentState, setComponentState] = useState<{
    IconComponent:
      | null
      | TypeIcon
      | (({ weight, ...props }: { weight?: IconWeight }) => JSX.Element)
      | React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  }>({
    IconComponent: null,
  });

  useEffect(() => {
    if (!(src in iconsMap)) {
      throw new Error(`${src} icon not found`);
    }

    const importIcon = async (): Promise<void> => {
      const item = await iconsMap[src]?.();
      const IconComponent = item?.ReactComponent;
      if (IconComponent && typeof IconComponent !== 'string') {
        setComponentState({ IconComponent });
      }
    };

    importIcon();
  }, [src]);

  const { IconComponent } = componentState;

  return IconComponent ? (
    <IconComponent {...props} {...getSize({ propSize, width, height })} weight={variant} />
  ) : (
    <FallbackIcon src={src} size={propSize} width={width} height={height} />
  );
};

export const Icon = ({ src, ...props }: IconProps) => (
  <IconErrorBoundary iconSrc={src}>
    <IconComponentWrapper {...props} src={src} />
  </IconErrorBoundary>
);
