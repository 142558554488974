export const mango = {
  100: '#FFF5EA',
  200: '#FFECD4',
  300: '#FFE2BF',
  400: '#FFD9A9',
  500: '#FECF93',
  600: '#FEC57E',
  700: '#FEBC69',
  800: '#FEB253',
  900: '#FEA83E',
  1000: '#FE9F28',
  2000: '#6C552D',
};
