// This helps the infrastructure team help isolate dashboard traffic from the SDK & other services
export const DASHBOARD_API_HEADER = { 'x-sprig-api-src': 'dashboard' };

export const APP_ROUTES = {
  ACTIVITY: '/activity',
  AI_SEARCH: '/ai-search',
  ATTRIBUTES: '/attributes',
  CREATE: '/create',
  DASHBOARDS: '/dashboards',
  DEMO: '/demo',
  EVENTS: '/events',
  EXPLORER: '/explorer',
  EXPLORER_DRAFT: '/explorer/draft',
  EXPLORER_REPORT_BUILDER: '/explorer/report-builder',
  EXPLORER_REPORT: '/explorer/report',
  FEEDBACK: '/feedback',
  FEEDBACK_NEW: '/feedback/new',
  GROUPS: '/groups',
  GROUP_DETAILS: '/groups/:id',
  HEATMAP: '/heatmap',
  HEATMAP_NEW: '/heatmap/new',
  HOME: '/welcome',
  INTEGRATIONS: '/integrations',
  GET_INSPIRED: '/getinspired',
  LAUNCH_PAD: '/welcome',
  PEOPLE: '/people',
  PEOPLE_DETAILS: '/people/:id',
  PLANS: '/settings/plans',
  PREVIEW: '/previews/:uuid',
  REPLAY: '/replay',
  REPLAY_NEW: '/replay/new',
  SETTINGS: '/settings/profile',
  SETTINGS_BASE: '/settings',
  STUDY_LIST: '/studylist/all',
  STUDY_LIST_BASE: '/studylist',
  SURVEY: '/survey',
  SURVEY_NEW: '/survey/new',
  SURVEYS_NEW: '/surveys/new',
  SURVEYS: '/surveys',
  TEMPLATE: '/template',
  TEMPLATES: '/templates',
  TEMPLATES_CUSTOM: '/templates/custom',
  USERS: '/users',
  USER_DETAILS: '/users/:id',
  VISITORS_OLD: '/visitors/all',
  VISITOR_DETAILS_OLD: '/visitors/:id',
  VISITORS: '/visitors',
} as const;

export const DASHBOARD_ROUTE_PREFIXES = [
  APP_ROUTES.ACTIVITY,
  APP_ROUTES.ATTRIBUTES,
  APP_ROUTES.DASHBOARDS,
  APP_ROUTES.EVENTS,
  APP_ROUTES.EXPLORER,
  APP_ROUTES.GROUPS,
  APP_ROUTES.HOME,
  APP_ROUTES.INTEGRATIONS,
  APP_ROUTES.PEOPLE,
  APP_ROUTES.SURVEYS,
  APP_ROUTES.TEMPLATE,
  APP_ROUTES.TEMPLATES,
  APP_ROUTES.USERS,
  APP_ROUTES.SETTINGS_BASE,
  APP_ROUTES.STUDY_LIST_BASE,
  APP_ROUTES.SURVEY_NEW,
  APP_ROUTES.VISITORS,
] as const;

export const SurveyRoutingTypes = {
  Replay: 'replay',
  Heatmap: 'heatmap',
  Feedback: 'feedback',
  Survey: 'survey',
  Legacy: 'surveys',
} as const;

export const SurveyRoutingTypeValues = Object.values(SurveyRoutingTypes);

//This pattern is used to detect URLs that use survey UUID rather than id. These are redirected to the normal ID
export const surveyPageRegex = new RegExp(
  `/(?:${SurveyRoutingTypeValues.join('|')})/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`
);
