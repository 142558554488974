export const indigo = {
  100: '#F0EFFA',
  200: '#E1DFF4',
  300: '#D1CEEF',
  400: '#C2BEE9',
  500: '#B3AEE4',
  600: '#A49EDF',
  700: '#958ED9',
  800: '#857DD4',
  900: '#766DCE',
  1000: '#675DC9',
  2000: '#42468C',
};
