import React, { useState } from 'react';

import styled from '@emotion/styled';

import { colors, shadows, size } from '../../styles';
import { propValidator } from '../../utils';
import { Icon, IconKey } from '../Icon';
import { Label } from '../Input/Label';
import { Popup } from '../Popup';

// TODO: Refactor props to make component more extensible GROWTH-1220
interface OptionType<V> {
  'aria-label'?: string;
  adComponent?: React.ReactNode;
  component?: React.ReactNode;
  disabled?: boolean;
  label?: string;
  optionKey: V; // rename to value later
  popover?: React.ReactNode;
  selectedIconVariant?: 'bold' | 'fill' | 'regular';
  src?: IconKey;
}

interface ToggleProps<U> {
  className?: string;
  defaultSelection?: U;
  label?: string;
  onToggle: (selectionKey?: U) => void;
  options: OptionType<U>[];
  isVertical?: boolean;
}

export const SegmentedButton = <T extends string>({
  className,
  defaultSelection,
  label,
  onToggle,
  options,
  isVertical = false,
}: ToggleProps<T>) => {
  const [selectedKey, setSelectedKey] = useState(defaultSelection);

  const handleToggle = (key?: T) => {
    if (selectedKey == key) return;
    setSelectedKey(key);
    onToggle(key);
  };

  const ToggleCreation = (
    <Container className={className} role="group" isVertical={isVertical}>
      {options.map(({ disabled, optionKey, popover, selectedIconVariant = 'fill', src, ...o }, index) => (
        <Popup
          key={optionKey}
          on={popover ? 'hover' : 'none'}
          triggerContainerStyle={{ display: 'flex' }}
          trigger={
            <Option
              aria-label={o['aria-label']}
              aria-pressed={selectedKey === optionKey}
              disabled={!!disabled}
              first={index === 0}
              isVertical={isVertical}
              last={index === options.length - 1}
              onClick={() => (disabled ? '' : handleToggle(optionKey))}
              selected={selectedKey === optionKey}
              type="button"
            >
              {src && <Icon src={src} variant={selectedKey === optionKey ? selectedIconVariant : 'regular'} />}
              {o.component || <b>{o.label}</b>}
              {disabled && o.adComponent}
            </Option>
          }
        >
          {popover}
        </Popup>
      ))}
    </Container>
  );

  return label ? (
    <div>
      <Label>{label}</Label>
      {ToggleCreation}
    </div>
  ) : (
    ToggleCreation
  );
};

const Container = styled.div<{ isVertical: boolean }>`
  ${shadows.base1}
  border-radius: ${size(1)};
  ${({ isVertical }) => !isVertical && 'display: inline-flex;'}
`;

const getBorderRadius = (p: { first: boolean; last: boolean; isVertical: boolean }) => {
  if (p.isVertical) {
    if (p.last) return `0 0 ${size(1)} ${size(1)}`;
    if (p.first) return `${size(1)} ${size(1)} 0 0`;
  } else {
    if (p.last) return `0 ${size(1)} ${size(1)} 0`;
    if (p.first) return `${size(1)} 0 0 ${size(1)}`;
  }
  return '0';
};

const Option = styled('button', { shouldForwardProp: propValidator })<{
  disabled: boolean;
  first: boolean;
  last: boolean;
  selected: boolean;
  square?: boolean;
  isVertical: boolean;
}>`
  background-color: ${(p) => (p.selected ? colors.border.light : colors.background.primary)};
  border-radius: ${getBorderRadius};
  border: 1px solid ${colors.border.light};
  ${({ isVertical, last }) => {
    const borderValue = last ? `1px solid ${colors.border.light}` : 'none';
    return isVertical ? `border-bottom: ${borderValue};` : `border-right: ${borderValue};`;
  }}
  font-size: ${size(2)};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 150%;
  padding: ${size(1.5)};
  color: ${(p) => (p.selected ? colors.theme.primary : colors.navy[900])};

  ${(p) =>
    p.disabled
      ? `
          background-color: ${colors.border.lightTonal};
          color: ${colors.typography.disabled};
          cursor: not-allowed;
        `
      : `&:hover {
          background-color: ${colors.background.selected};
          color: ${colors.theme.primary};
          cursor: pointer;
        }
  `}
`;
