import { BaseQuestion, QuestionType } from './base';
import { MultipleChoiceOption } from './multipleChoice';
import { RoutingOptions } from './routing';

export enum AvPermission {
  Camera = 'camera',
  Microphone = 'microphone',
  Screen = 'screen',
}

// recorded task
interface BaseTaskPage {
  buttonText: string;
  headline: string;
}

export enum RecordedTaskPageType {
  AvPermission = 'av_permission',
  ScreenPermission = 'screen_permission',
  StartTask = 'start_task',
  CompleteTask = 'complete_task',
}

export interface PermissionTaskPage extends BaseTaskPage {
  captionText: string;
  headline: string;
  permissionDeniedBody: string;
  permissionDeniedHeadline: string;
  permissionDescriptors: AvPermission[];
  permissionGrantedCaptionText?: string;
  permissionGrantedHeadline?: string;
  skipButtonText?: string;
  svg: string;
  tryAgainButtonText: string;
  type: RecordedTaskPageType.AvPermission;
}
export interface ScreenTaskPage extends BaseTaskPage {
  captionText: string;
  permissionDeniedCaptionText: string;
  permissionDeniedHeadline: string;
  selectTabText: string;
  skipButtonText: string;
  type: RecordedTaskPageType.ScreenPermission;
}
export interface StartTaskPage extends BaseTaskPage {
  captionText?: string;
  taskDetail: string;
  type: RecordedTaskPageType.StartTask;
}
export interface CompleteTaskPage extends BaseTaskPage {
  captionText?: string;
  skipButtonText: string;
  taskDetail: string;
  type: RecordedTaskPageType.CompleteTask;
}

export type RecordedTaskPage = PermissionTaskPage | ScreenTaskPage | StartTaskPage | CompleteTaskPage;

export interface RecordedTaskCardProperties {
  captionText: string;
  conceptUrl: string;
  pages: RecordedTaskPage[];
  permissions: AvPermission[];
  required: boolean;
  taskDetail: string;
}

export interface RecordedTaskQuestion extends BaseQuestion {
  props: {
    message: string;
    options: MultipleChoiceOption[];
    properties: RecordedTaskCardProperties;
    routingOptions: RoutingOptions;
  };
  type: QuestionType.RecordedTask;
}
