export enum QuestionTypeID {
  OpenText = 1,
  MultipleChoice = 2,
  RatingScale = 4,
  MultipleSelect = 5,
  NPS = 7,
  TextUrlPrompt = 8,
  VideoVoice = 9,
  ConsentLegal = 10,
  RecordedTask = 11,
  Matrix = 12,
}

export interface BaseQuestion {
  id: number;
  name: number;
  surveyId: number;
}

export type ConceptUrl = string | null;

export enum PromptActionType {
  Continue = 'CONTINUE',
  External = 'EXTERNAL',
}

export enum QuestionType {
  ConsentLegal = 'consentlegal',
  LikertScale = 'likert',
  Matrix = 'matrix',
  MultipleChoice = 'multiplechoice',
  MultipleSelect = 'multipleselect',
  NPS = 'nps',
  OpenEndedText = 'open',
  RecordedTask = 'recordedtask',
  TextUrlPrompt = 'texturlprompt',
  VideoVoice = 'videovoice',
}

export interface Labels {
  left: string;
  right: string;
}

export interface RatingIcon {
  idx?: number;
  svg: string;
}

export enum ScaleLabelType {
  Number = 'number',
  Smiley = 'smiley',
  Star = 'star',
}
