import { size as styleSize } from '../../styles';
import { SizeVariant } from '../../utils';

export const getInputSize = (size: SizeVariant) => {
  switch (size) {
    case SizeVariant.small:
      return styleSize(1);
    case SizeVariant.large:
      return styleSize(2.5);
    case SizeVariant.xlarge:
      return styleSize(3);
    default:
      return styleSize(2);
  }
};

export const getInputSizeInner = (size: SizeVariant) => {
  switch (size) {
    case SizeVariant.small:
      return styleSize(1 * 0.3);
    case SizeVariant.large:
      return styleSize(2.5 * 0.3);
    case SizeVariant.xlarge:
      return styleSize(3 * 0.3);
    default:
      return styleSize(2 * 0.3);
  }
};
