import React, { useMemo } from 'react';

import styled from '@emotion/styled';

import { getColorFromString } from '../../styles';
import { propValidator, getInitials } from '../../utils';
import { Icon } from '../Icon';

export const AVATAR_SIZES = Object.freeze({
  XXSMALL: 'xxsmall',
  XSMALL: 'xsmall',
  SMALL: 'small',
  SMALL_MEDIUM: 'small_medium',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge',
});

type Size = typeof AVATAR_SIZES[keyof typeof AVATAR_SIZES];

export const getAvatarStyles = (size: Size) => {
  const baseStyles = { fontWeight: '500', top: 0 };
  // Moving Forward Lets use Pixels (px) for dimension and font-size please
  switch (size) {
    case AVATAR_SIZES.XXSMALL:
      return { ...baseStyles, dimension: '16px', fontSize: '9px' };
    case AVATAR_SIZES.XSMALL:
      return { ...baseStyles, dimension: '1.25em', fontSize: '0.8em' };
    case AVATAR_SIZES.SMALL:
      return { ...baseStyles, dimension: '1.5em', fontSize: '0.8em' };
    case AVATAR_SIZES.SMALL_MEDIUM:
      return { ...baseStyles, dimension: '32px', fontSize: '14px' };
    case AVATAR_SIZES.MEDIUM:
      return { ...baseStyles, dimension: '2.25em', fontSize: '1em' };
    case AVATAR_SIZES.LARGE:
      return { ...baseStyles, dimension: '2.75em', fontSize: '1.2em' };
    case AVATAR_SIZES.XLARGE:
      return { ...baseStyles, dimension: '6.2em', fontSize: '1.55em', fontWeight: '700' };
  }
};

export const DeletedUserDefaultName = 'Deleted User';

export const Avatar = ({
  name,
  size = AVATAR_SIZES.MEDIUM,
  src = '',
  ...props
}: {
  className?: string;
  name?: string;
  size?: Size;
  src?: string | null;
}) => {
  const { dimension, fontSize, fontWeight, top } = useMemo(() => getAvatarStyles(size), [size]);
  const initials = useMemo(() => getInitials(name), [name]);
  const isAlphabet = initials && /^[a-zA-Z]+$/.test(initials);
  if (src) return <AvatarImage src={src} dimension={dimension} />;

  return (
    <Container
      style={{
        background: getColorFromString(name),
      }}
      alt={name}
      dimension={dimension}
      {...props}
    >
      {isAlphabet ? (
        <Initials size={fontSize} top={top} weight={fontWeight} data-test-id="initials">
          {initials.toUpperCase()}
        </Initials>
      ) : (
        <DefaultAvatar alt={name} src="avatar-empty" />
      )}
    </Container>
  );
};

const Initials = styled('span', { shouldForwardProp: propValidator })<{
  size: string;
  top: string | number;
  weight: string;
}>`
  color: #fff;
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => size};
  line-height: 0;
  position: relative;
  top: ${({ top }) => top};
`;

const DefaultAvatar = styled(Icon)<{ alt?: string }>`
  width: 100%;
  height: 100%;
  color: transparent;
`;

const Container = styled.div<{ alt?: string; dimension: string }>`
  width: ${({ dimension }) => dimension};
  height: ${({ dimension }) => dimension};
  min-width: ${({ dimension }) => dimension};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-width: 100%;
  border-radius: 50%;
  user-select: none;
`;

const AvatarImage = styled.div<{ dimension: string; src: string }>`
  background-image: ${({ src }) => `url(${src})`};
  border-radius: 50%;
  background-position: top center;
  background-size: cover;
  width: ${({ dimension }) => dimension};
  height: ${({ dimension }) => dimension};
`;
