export interface AndroidReplaySettings {
  hideEditText: boolean;
  hideImageView: boolean;
  hideMapView: boolean;
  hideNumericPassword: boolean;
  hideSpecificClasses: string | null;
  hideTextView: boolean;
}

export const ANDROID_DEFAULT_CLASSES: Record<
  Exclude<keyof AndroidReplaySettings, 'hideSpecificClasses' | 'hideNumericPassword'>,
  string
> = {
  hideEditText: 'EditText',
  hideTextView: 'TextView',
  hideMapView: 'MapView',
  hideImageView: 'ImageView',
};

export const ANDROID_REPLAY_DEFAULTS: AndroidReplaySettings = {
  hideEditText: true,
  hideImageView: false,
  hideMapView: false,
  hideNumericPassword: true,
  hideSpecificClasses: null,
  hideTextView: true,
};

// use this, IOSReplaySettings below will be deprecated once all customers have migrated off of the current versions
export enum IOSMaskingLevel {
  Basic = 'basic',
  Full = 'full',
}

export interface IOSReplaySettings {
  hideUITextField: boolean;
  hideUITextView: boolean;
  hideWKWebView: boolean;
  hideUIInputView: boolean;
  hideMKMapView: boolean;
  hideUIImageView: boolean;
  hideUILabel: boolean;
  hideIsSecureTextEntry: boolean;
  hideSpecificClasses: string | null;
  maskingLevel?: IOSMaskingLevel;
}

export const IOS_DEFAULT_CLASSES: Record<
  Exclude<keyof IOSReplaySettings, 'hideIsSecureTextEntry' | 'hideSpecificClasses' | 'maskingLevel'>,
  string
> = {
  hideUITextField: 'UITextField',
  hideUITextView: 'UITextView',
  hideWKWebView: 'WKWebView',
  hideUIInputView: 'UIInputView',
  hideMKMapView: 'MKMapView',
  hideUIImageView: 'UIImageView',
  hideUILabel: 'UILabel',
};
export const IOS_REPLAY_DEFAULTS: IOSReplaySettings = {
  hideUITextField: true,
  hideUITextView: true,
  hideWKWebView: false,
  hideUIInputView: false,
  hideMKMapView: false,
  hideUIImageView: false,
  hideUILabel: false,
  hideIsSecureTextEntry: true,
  hideSpecificClasses: null,
  maskingLevel: IOSMaskingLevel.Basic,
};

export const WEB_REPLAY_DEFAULTS: WebReplaySettings = {
  hideAllFormContents: true,
  hidePasswordsOnly: false,
  hideAllImages: false,
  hideCssSelectors: null,
};

export interface WebReplaySettings {
  hideAllFormContents: boolean;
  hidePasswordsOnly: boolean;
  hideAllImages: boolean;
  hideCssSelectors: string | null;
}

/**
 * @deprecated - please remove & use sprigShared/replays
 */
export interface ReplaySettingsPlatform {
  ios?: IOSReplaySettings;
  android?: AndroidReplaySettings;
  web?: WebReplaySettings;
}

export enum ReplayEventType {
  Click = 'Sprig_Click',
  Event = 'Sprig_TrackEvent',
  PageView = 'Sprig_PageView',
  SurveyShown = 'Sprig_ShowSurvey',
  SurveySubmitted = 'Sprig_SubmitSurvey',
  Noop = 'Sprig_Noop',
  Touch = 'Sprig_Touch',
  Keystroke = 'Sprig_Keystroke',
  BackForward = 'Sprig_BackForward',
  Refresh = 'Sprig_Refresh',
  Scroll = 'Sprig_Scroll',
  RageClick = 'Sprig_RageClick',
  DeadClick = 'Sprig_DeadClick',
  ErrorClick = 'Sprig_ErrorClick',
}

export const TOP_LEVEL_HTML_STRING = '//HTML[1]';
