export const cream = {
  100: '#FEFDFD',
  200: '#FCFBFA',
  300: '#FBFAF8',
  400: '#FAF8F5',
  500: '#F8F6F3',
  600: '#F7F4F1',
  700: '#F6F2EE',
  800: '#F5F1EC',
  900: '#F3EFE9',
  1000: '#F2EDE7',
};
