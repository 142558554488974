/**
 * ANALYTICS_EVENTS contains all event names used in the Dashboard.
 * New events can be added here easily via the structure below:
 *
 * All Event names will begin with the 'Dashboard - ' prefix
 * Event names can be static strings, or if dynamic, can take in a parameter and will be generated as a fn
 *
 * For certain event names, there are a few action prefixes we will use:
 * A_: Action
 * V_: Viewed
 * C_: Clicked
 * S_: Submitted
 * E_: Error
 *
 * Note: If an event name has an object commented inline, those dynamic properties are being passed alongside
 *
 * Keep the Event Categories in Alphabetical Order Please
 */

export const ANALYTICS_EVENTS = {
  // Activity Feed
  V_ACTIVITY_FEED: 'Dashboard - Viewed - Activity Feed',
  V_ACTIVITY_FEED_DURATION: 'Dashboard - Viewed - Activity Feed - Duration Based', // { duration: <number of seconds viewed before triggering event> }
  C_ACTIVITY_FEED_NEW_FEATURE_CARD: 'Dashboard - Clicked - Activity Feed - New In Sprig Card', // { feature: <feature name> } replay-updates, matrix-questions
  C_ACTIVITY_FEED_NEW_IN_SPRIG_TOGGLE: 'Action - Dashboard - Clicked - New In Sprig Toggle',
  C_ACTIVITY_FEED_CARD: 'Dashboard - Clicked - Activity Feed - Card', // { surveyId, hasSummary: <bolean>, type: <launched, drafted, completed, received new responses> }
  C_ACTIVITY_FEED_FILTER_SCOPE: 'Dashboard - Clicked - Activity Feed - Scope Filter', // { selection: <Team, You> }
  C_ACTIVITY_FEED_FILTER_INTERVAL: 'Dashboard - Clicked - Activity Feed - Interval Filter', // { selection: <last 30, last 7, today> }
  C_ACTIVITY_FEED_SUBMIT_FEEDBACK: 'Dashboard - Clicked - Activity Feed - Submit Feedback Survey Link',
  C_AI_INSIGHTS_OPEN_AUDIT_MODAL: 'Dashboard - Clicked - Categorical Insights - Open Auditable Insights Modal', // { surveyId, type: <strength, trend, opportunity, correlation>, source: <'ai inisights tab' : 'homepage'> }
  C_ACTIVITY_FEED_CREATE_NEW_STUDY: 'Dashboard - Clicked - Activity Feed - Empty State - Create New Study',
  C_ACTIVITY_FEED_SINGLE_INSIGHT_POSITIVE: 'Dashboard - Clicked - Insight - Thumbs Up', // { insightId, title, description, surveyId, userEmail, teamId, name }
  C_ACTIVITY_FEED_SINGLE_INSIGHT_NEGATIVE: 'Dashboard - Clicked - Insight - Thumbs Down', // { insightId, title, description, surveyId, userEmail, teamId, name }

  // Survey Audit Feed
  C_SURVEY_AUDIT_FEED_VIEW_ALL: 'Dashboard - Clicked - Homepage - View Account Activity Feed', //{ userEmail, teamId, name }
  C_SURVEY_AUDIT_FEED_NAVIGATE_STUDY: 'Dashboard - Clicked - View Account Activity Feed - Navigate to Study', //{ surveyId, userEmail, teamId, name }

  // Amplitude Integration
  C_AMPLITUDE_DOCS: 'Dashboard - Clicked - Read Docs from Amplitude Connect card', // { configEnvironment: <Production, Development>}
  C_SAVE_AMPLITUDE: 'Dashboard - Clicked - Save Amplitude API Key', // { configEnvironment: <Production, Development>}
  C_REMOVE_AMPLITUDE: 'Dashboard - Clicked - Remove Amplitude API Key', // { configEnvironment: <Production, Development>}

  // Attributes
  V_ATTRIBUTE_DETAILS: 'Dashboard - Viewed - Attribute Details',
  C_EDIT_ATTRIBUTE: 'Dashboard - Clicked - Edit Attribute',
  C_ARCHIVE_OR_UNARCHIVE_ATTRIBUTES: 'Dashboard - Clicked - Archive Attributes', // { action, numAttributes, source }
  A_ATTRIBUTE_SEARCH: 'Dashboard - Action - Search Attributes', // { query }

  // Banners
  V_LEGACY_USAGE_BANNER: 'Dashboard - Viewed - Usage Banner',
  C_ENTERPRISE_EXTRA_SURVEYS: 'Dashboard - Clicked - Go To Usage Page From Extra Surveys for Enterprise Announcement',
  C_ENTERPRISE_EXTRA_SURVEYS_CLOSE: 'Dashboard - Clicked - Close Extra Surveys for Enterprise Announcement',
  C_ENTERPRISE_EXTRA_SURVEYS_USAGE_CLOSE:
    'Dashboard - Clicked - Close Usage Page Extra Surveys for Enterprise Announcement',
  C_REPLAY_BETA_ANNOUNCEMENT: 'Dashboard - Clicked - Go To Replay Beta Announcement',
  C_REPLAY_BETA_ANNOUNCEMENT_CLOSE: 'Dashboard - Clicked - Close Replay Beta Announcement',
  C_JOIN_AI_WAITLIST_ANNOUNCEMENT: 'Dashboard - Clicked - Join AI Waitlist Announcement',
  C_CLOSE_AI_WAITLIST_ANNOUNCEMENT: 'Dashboard - Clicked - Close AI Waitlist Announcement',
  C_AI_SLI_ANNOUNCEMENT: 'Dashboard - Clicked - AI SLI Announcement',
  C_CLOSE_AI_SLI_ANNOUNCEMENT: 'Dashboard - Clicked - Close AI SLI Announcement',
  C_AI_PRODUCT_INSIGHTS_ANNOUNCMENT: 'Dashboard - Clicked - AI Product Insights Announcement',
  C_CLOSE_AI_PRODUCT_INSIGHTS_ANNOUNCMENT: 'Dashboard - Clicked - Close AI Product Insights Announcement',

  // Billing
  S_PAYMENT_INFO: 'Dashboard - Submitted - Payment Information', // { status, success }
  C_UPGRADE_PLAN: 'Dashboard - Clicked - Upgrade Plan',

  // Connect
  C_CONNECT_CARD: 'Dashboard - Clicked - Connect Card', // { card }
  C_CONNECT_CHIP: 'Dashboard - Clicked - Connect Chip', // { chip }

  // Chilipiper Booking Calendar
  E_CP_ON_START_LOADING: 'Chilipiper Booking - Loading Started',
  E_CP_ON_ROUTED: 'Chilipiper Booking - Routed', // { ms_elapsed }
  E_CP_ON_ERROR: 'Chilipiper Booking - Error Loading Chilipiper Booking Calendar', // { ms_elapsed }
  E_CP_SDK_ERROR: 'Chilipiper Booking - Error in Chilipiper Booking SDK Caught by Try Catch', // { ms_elapsed }
  E_CP_ON_SUCCESS: 'Chilipiper Booking - Meeting Successfully Booked', // { ms_elapsed, featureName }

  // CSI Explorer
  V_CSI_EXPLORER: 'Dashboard - Viewed - Explorer Reports Page',
  V_CSI_EXPLORER_REPORT: 'Dashboard - Viewed - Explorer Report',
  V_CSI_EXPLORER_CREATE_REPORT: 'Dashboard - Viewed - Explorer Create Report',
  V_CSI_EXPLORER_UPDATE_REPORT: 'Dashboard - Viewed - Explorer Update Report',
  C_CSI_EXPLORER_CREATE_REPORT: 'Dashboard - Clicked - Explorer Submit Report', // { query, studyIds, dateRange }
  C_CSI_EXPLORER_SAVE_REPORT: 'Dashboard - Clicked - Explorer Save Report',
  C_CSI_EXPLORER_COPY_REPORT_LINK: 'Dashboard - Clicked - Explorer Copy Report Link',
  C_CSI_EXPLORER_DELETE_REPORT: 'Dashboard - Clicked - Explorer Delete Report',
  C_CSI_EXPLORER_EDIT_REPORT: 'Dashboard - Clicked - Explorer Edit Report',
  C_CSI_EXPLORER_NEW_REPORT: 'Dashboard - Clicked - Explorer New Report',
  C_CSI_EXPLORER_UPDATE_REPORT: 'Dashboard - Clicked - Explorer Update Report',
  C_CSI_EXPLORER_REPORT_CARD: 'Dashboard - Clicked - Explorer Report Card', // { reportId }

  // CSV Upload
  C_UPLOAD_SPREADSHEET: 'Dashboard - Clicked - CSV Upload A Spreadsheet',
  C_UPLOAD_CSV: 'Dashboard - Clicked - CSV Upload CSV (People Page)', // source {All People, Groups}
  C_ALL_UPLOADS_TAB: 'Dashboard - Clicked - CSV All Uploads Tab',
  C_NEW_UPLOADS_TAB: 'Dashboard - Clicked - CSV New Uploads Tab',
  C_DOWNLOAD_SAMPLE_CSV: 'Dashboard - Clicked - CSV Download Sample CSV',
  C_BROWSE_FILES: 'Dashboard - Clicked - CSV Browse Files',
  C_PICK_DIFFERENT_FILE: 'Dashboard - Clicked - CSV Pick A Different File',
  C_CONTINUE_TO_UPLOAD: 'Dashboard - Clicked - CSV Continue To Upload',
  E_EMPTY_FILE: 'Dashboard - Error - CSV Empty File',
  E_DUPLICATE_COLUMN_NAMES: 'Dashboard - Error - CSV Duplicate Column Names',
  E_WRONG_FILE_FORMAT: 'Dashboard - Error - CSV Wrong File Format',
  E_MAX_FILE_SIZE: 'Dashboard - Error - CSV Max File Size',
  E_MISSING_REQUIRED_FIELDS: 'Dashboard - Error - CSV Missing Required Fields',
  E_DUPLICATE_REQUIRED_FIELDS: 'Dashboard - Error - CSV Duplicate Required Fields Selection',
  E_UPLOAD_ERROR: 'Dashboard - Error - CSV Upload Error',

  // Dashboards
  C_CREATE_DASHBOARD: 'Dashboard - Clicked - Create Dashboard', // { source }
  C_DELETE_DASHBOARD: 'Dashboard - Clicked - Delete Dashboard',
  C_UPDATE_DASHBOARD: 'Dashboard - Clicked - Dashboard Edit Details',
  C_DUPLICATE_DASHBOARD: 'Dashboard - Clicked - Duplicate Dashboard',
  C_DASHBOARD_SORT_BY: 'Dashboard - Clicked - Dashboard Sort By', // { sortBy }
  C_PIN_TO_DASHBOARD: 'Dashboard - Clicked - Pin to Dashboard',
  C_COPY_DASHBOARD_LINK: 'Dashboard - Clicked - Copy Link to Dashboard', // { source }
  C_FAVORITE_DASHBOARD: 'Dashboard - Clicked - Favorite Dashboard',
  C_VIEW_DASHBOARD: 'Dashboard - Clicked - View Dashboard', // { dashboardId, dashboardName }
  V_DASHBOARD: 'Dashboard - Viewed - Dashboard', // { dashboardId, dashboardName }

  // Demo
  S_DEMO_FORM_FILLED: 'Dashboard - Demo - Submitted - Welcome to Sprig Demo',
  C_DEMO_MEETING_REQUEST: 'Dashboard - Demo - Clicked - Book Sales Demo',
  S_DEMO_MEETING_REQUEST_DEMO_END: 'Dashboard - Demo - Submitted - Book Sales Demo At Demo End',
  C_DEMO_SKIP: 'Dashboard - Demo - Clicked - Skip All Tips',
  C_DEMO_SKIP_STEP: 'Dashboard - Demo - Clicked - Skip All Tips from Step ',
  C_DEMO_CLOSED_MODAL_DEMO_END: 'Dashboard - Demo - Clicked - Closed Booking Modal Without Booking',

  // Diagnostics
  // V_DIAGNOSTICS: (name) => `Dashboard - Viewed - ${name} Diagnostics`,
  V_EVENTS_DIAGNOSTICS: 'Dashboard - Viewed - Events Diagnostics',
  V_ATTRIBUTE_DIAGNOSTICS: 'Dashboard - Viewed - Attribute Diagnostics',
  V_VISITOR_DIAGNOSTICS: 'Dashboard - Viewed - Visitor Diagnostics',
  C_DIAGNOSTICS_MODAL: 'Dashboard - Clicked - Open Diagnostics Modal', // { surveyId, platform }
  V_DIAGNOSTICS_MODAL: 'Dashboard - Viewed - Diagnostics Modal', // { surveyId, tabIndex }
  V_PEOPLE_DIAGNOSTICS_TAB: 'Dashboard - Viewed - People Diagnostics',
  C_PEOPLE_DIAGNOSTICS_FILTER: 'Dashboard - Clicked - Filter People Diagnostics', // {stage, dateRange, query}
  C_READ_DOC_DIAGNOSTICS: 'Dashboard - Clicked - Diagnostics Read Docs',
  C_RESPONSE_CHART_DIAGNOSTICS_FILTER: 'Dashboard - Clicked - Filter Response Chart Diagnostics', // { seeData, dateRange }
  V_RESPONSE_CHART_DIAGNOSTICS: 'Dashboard - Viewed - Response Chart Diagnostics',
  V_FUNNEL_DIAGNOSTICS_TAB: 'Dashboard - Viewed - Funnel Diagnostics',

  // Doc Blocks
  V_DOC_BLOCK: 'Dashboard - Viewed - Doc Block',
  C_DISMISS_DOCBLOCK: 'Dashboard - Clicked - Dismiss Doc Block',
  C_DOCBLOCK_CTA: 'Dashboard - Clicked - Doc Block CTA',

  // Events
  C_ADD_EVENT: 'Dashboard - Clicked - Add Event',
  C_EDIT_EVENT: 'Dashboard - Clicked - Edit Event', // { type, noCodetype, matchType }
  C_DELETE_EVENT: 'Dashboard - Clicked - Delete Event',
  C_SAVE_EVENT: 'Dashboard - Clicked - Save Event', // { type, noCodetype, matchType, productSurface }
  V_EVENT_DETAILS: 'Dashboard - Viewed - Event Details', // { type }
  S_AUTO_ACCEPT_EVENTS: 'Dashboard - Submitted - Auto Accept Events', // { enabled }
  S_ALLOW_DISPLAY_SURVEY: 'Dashboard - Submitted - Allow Display Survey', // { enabled }
  S_DISMISS_ON_PAGE_CHANGE: 'Dashboard - Submitted - Auto Dismiss On Page Change', // { enabled }
  C_TEST_EVENT_URL_MATCH: 'Dashboard - Clicked - Test Your Event', // { isInvalidRegex, isMatch, pattern, matchType, urlInput }
  C_EVENT_FILTER_BY_SOURCE: 'Dashboard - Clicked - Filter by Source', // { eventSourceFilters=[…], attributeSourceFilters=[…] }
  C_EVENT_FILTER_BY_STATUS: 'Dashboard - Clicked - Filter by Event status', // { status=active or archived, forEntity=events/attributes }
  C_EVENT_SEARCH: 'Dashboard - Clicked - Search Events', // { query }
  C_EVENT_CUSTOM_DISPLAY_NAME: 'Dashboard - Clicked - Use Custom Display Name', // { status=true/false for check/uncheck “Use Key as display name”, forEntity=events/attributes }
  V_EVENT_REQUEST_MODAL: 'Dashboard - Viewed - Event Requests Modal', // { numEvents }
  C_EVENT_MANAGE_REQUESTS: 'Dashboard - Clicked - Manage Event Requests', // { numEvents, action=approve or deny }
  C_ARCHIVE_OR_UNARCHIVE_EVENTS: 'Dashboard - Clicked - Archive Events', // { action, numEvents, source }
  C_EVENTS_TAB: 'Dashboard - Clicked - Events Tab',
  C_OPEN_USAGE_DROPDOWN: 'Dashboard - Clicked - Usage dropdown',
  C_DIAGNOSTICS_MODAL_CLOSE: 'Dashboard - Clicked - Close Diagnostics Modal',

  // Empty States
  C_EMPTY_OVERVIEW: 'Dashboard - Clicked - Overivew Empty State Button',
  C_EMPTY_FOLDER: 'Dashboard - Clicked - Empty Folder New Microsurvey', // Might be deprecated
  C_EMPTY_PEOPLE: 'Dashboard - Clicked - People Empty State Button',
  C_EMPTY_EVENTS: 'Dashboard - Clicked - Events Empty State Button',
  C_EMPTY_DASHBOARD: 'Dashboard - Clicked - Dashboard Empty State Browse Studies',
  C_EMPTY_CUSTOM_TEMPLATES: 'Dashboard - Clicked - Dashboard Empty Custom Templates',
  C_EMPTY_DASHBOARDS_INDEX: 'Dashboard - Clicked - Dashboards Index Empty State',
  C_EMPTY_DASHBOARDS_INDEX_TEASER: 'Dashboard - Clicked - Dashboards Index Empty Teaser State',
  C_EMPTY_GROUPS_ALL_PEOPLE: 'Dashboard - Clicked - Groups Empty State All People Button',
  C_EMPTY_GROUPS_CSV_UPLOAD: 'Dashboard - Clicked - Groups Empty State Upload CSV Button',
  C_EMPTY_STATE_OPEN_VIDEO_MODAL: 'Dashboard - Clicked - Inproduct Teaser Demo Video', // { location: 'Data Hub' || 'Dashboard' || 'People' || 'Studies' }
  C_EMPTY_DATA_HUB_MEETING_REQUEST: 'Dashboard - Clicked - Data Hub Requested Sales Meeting',
  C_EMPTY_PEOPLE_MEETING_REQUEST: 'Dashboard - Clicked - People Page Requested Sales Meeting',

  // Free Plan Upgrade
  C_FREE_PLAN_UPGRADE: 'Dashboard - Clicked - Free Plan Upgrade',

  // Get Inspired
  C_GET_INSPIRED_MAYBE_LATER: 'Dashboard - Clicked - Get Inspired Maybe Later',
  C_GET_INSPIRED_USE_TEMPLATE: 'Dashboard - Clicked - Get Inspired Use Template', // { templateUUID, templateName }

  // Groups & People
  C_COPY_GROUP_LINK: 'Dashboard - Clicked - Copy Link to Group',
  C_DELETE_Group: 'Dashboard - Clicked - Delete Group', // source { Groups, Group details modal }
  C_DUPLICATE_Group: 'Dashboard - Clicked - Duplicate Group', // { type }
  V_GROUP_DETAILS: 'Dashboard - Viewed - Group Details', // when user views group details tab in modal
  V_GROUP_USAGE: 'Dashboard - Viewed - Group Usage', // when user views group usage tab in modal
  C_SAVE_GROUP_EDIT: 'Dashboard - Clicked - Save Group Edit', // when user clicks “Save Changes” with a new name/description for group
  C_CREATE_GROUP: 'Dashboard - Clicked - Create Group', // { type }
  C_ALL_PEOPLE_TAB: 'Dashboard - Clicked - All People Tab',
  C_GROUPS_TAB: 'Dashboard - Clicked - Groups Tab',
  C_REMOVE_GROUP: 'Dashboard - Clicked - Remove from Manual Group', // { numPeople }
  C_VIEW_GROUP: 'Dashboard - Clicked - View Group', // type { manual, dynamic }
  C_GROUPS_FILTER: 'Dashboard - Clicked - Filter Groups', // type { all, manual, dynamic }
  A_GROUPS_SEARCH: 'Dashboard - Action - Groups Search', // search in search bar { searchString}
  C_DISMISS_GROUPS_WELCOME_MODAL: 'Dashboard - Clicked - Dismiss Groups Welcome Modal',
  A_GROUP_VIS_SEARCH: 'Dashboard - Action - Visitors Search in Group', // { searchString, type, hasFilter, source }

  // Heatmap
  C_HEATMAP_HERE_TO_HELP: 'Dashboard - Clicked - Here To Help',
  V_HEATMAP_RESULTS: 'Dashboard - Viewed - Heatmap Results',
  C_HEATMAP_SUBMIT_FEEDBACK: 'Dashboard - Clicked - Survey Response Page - Heatmap - Submit Feedback Survey Link',
  C_HEATMAP_CHANGE_BACKGROUND: 'Dashboard - Clicked - Heatmap - Settings - Change Background',
  C_HEATMAP_REFRESH_BACKGROUNDS: 'Dashboard - Clicked - Heatmap - Background Modal - Refresh',
  C_HEATMAP_SET_BACKGROUND: 'Dashboard - Clicked - Heatmap - Background Modal - Set Background',

  // Helper UI Onboarding
  V_ONBOARDING_HELPERS: 'Dashboard - Viewed - Onboarding Helpers Tooltip', // { type }
  C_ONBOARDING_HELPERS: 'Dashboard - Clicked - Onboarding Helpers Tooltip', // { type }

  // Help Chat
  C_HELP_INTERCOM_OPEN: 'Dashboard - Clicked - Open Intercom Chat',

  // Home Screen Checklist and Activation Checklist
  C_PLS_CHECKLIST_ENTERPRISE: 'Dashboard - Clicked - Enterprise - Activation Checklist', // { stepName }
  C_PLS_CHECKLIST_SELF_SERVICE: 'Dashboard - Clicked - Self Service - Activation Checklist', // { stepName }

  // Integration Gallery
  V_INTEGRATION_PAGE: 'Dashboard - Viewed - Integrations Page',
  C_INTEGRATION_CARD: 'Dashboard - Clicked - Integration', // { plan, integration }
  C_SEARCH_INTEGRATION: 'Dashboard - Clicked - Search Integrations', // { plan, query }
  V_INTEGRATION_CATEGORY: 'Dashboard - Viewed - Integration Category', // { plan, category }
  C_REQUEST_INTEGRATION: 'Dashboard - Clicked - Request an Integration', // { plan }
  C_JOIN_PARTNER_NETWORK: 'Dashboard - Clicked - Join Partner Network', // { plan }
  C_READ_INTEGRATION_DOC: 'Dashboard - Clicked - Read Integration Documentation', // { plan, integration }
  C_START_INSTLLATION_CTA: 'Dashboard - Clicked - Start installation CTA', // { plan }
  C_DISMISS_INTEGRATIONS_WELCOME_MODAL: 'Dashboard - Clicked - Dismiss Integrations welcome modal', // user closes launch announcement
  C_WITH_INTEGRATION: 'Dashboard - Clicked - Install Page - Choose Method - With an Integration',

  C_WITH_GTM: 'Dashboard - Clicked - Install Page - Choose Method - GTM',
  C_WITH_SEGMENT_WEB: 'Dashboard - Clicked - Install Page - Choose Method - Segment Web',
  C_WITH_CODE: 'Dashboard - Clicked - Install Page - Choose Method - With Code',
  C_WITH_JS: 'Dashboard - Clicked - Install Page - Choose Method - Javascript',

  C_INVITED_TEAMMATE: 'Dashboard - Clicked - Invite Teammate CTA',
  C_INVITE_DEVELOPER_MODAL: 'Dashboard - Clicked - Open Invite Developer Modal', // { source: <path> }
  C_INVITED_DEVELOPER: 'Dashboard - Clicked - Open Invite Developer Modal', // { email }
  C_MOBILE_UPGRADE_BADGE: 'Dashboard - Clicked - Install Page - Mobile Upgrade Badge',

  // Integration Detailed Page
  C_INSTALL_GUIDE_FEEDBACK_LINK: 'Dashboard - Clicked - Install Guide Give Feedback', // { integration }
  V_INSTALL_GUIDE_STEP: 'Dashboard - Viewed - Install Guide Step', // { integration, step, stepNumber }
  A_INSTALL_GUIDE_STEP: 'Dashboard - Successful - Install Guide Step', // { integration, step }
  A_COMPLETE_INSTALL_GUIDE: 'Dashboard - Successful - Completed Install Guide', // { integration }
  C_COPY_INTEGRATION_LINK: 'Dashboard - Clicked - Share Install Guide', // { integration }
  C_OPEN_TUTORIAL_VIDEO: 'Dashboard - Clicked - Sidepanel Video - View Tutorial', // { integration }

  // LaunchPad
  C_LAUNCHPAD_TEMPLATES: 'Dashboard - Clicked - LaunchPad Explore Templates',
  C_LAUNCHPAD_TALK_TO_SPECIALIST: 'Dashboard - Clicked - LaunchPad Talk to a Specialist',
  C_LAUNCHPAD_OPEN_WELCOME_VIDEO_MODAL: 'Dashboard - Clicked - Open Welcome Video Modal',
  C_LAUNCHPAD_DOCUMENTATION: 'Dashboard - Clicked - LaunchPad Documentation',
  C_LAUNCHPAD_VIDEO_TUTORIAL: 'Dashboard - Clicked - LaunchPad Video Tutorial Link',
  C_LAUNCHPAD_PRODUCT_GUIDE_CONCEPT_TEST: 'Dashboard - Clicked - LaunchPad Product Guide Concept Test',
  C_LAUNCHPAD_PRODUCT_GUIDE_IN_PRODUCT: 'Dashboard - Clicked - LaunchPad Product Guide In-Product Survey',
  C_LAUNCHPAD_PRODUCT_GUIDE_REPLAY: 'Dashboard - Clicked - LaunchPad Product Guide Session Replay',
  C_LAUNCHPAD_SHAREABLE_LINK_SURVEYS: 'Dashboard - Clicked - LaunchPad Shareable Link Surveys',
  C_LAUNCHPAD_LEARN_INSTALLATION: 'Dashboard - Clicked - LaunchPad Learn About Installation',

  // Login & Logout
  A_LOGIN_START: 'Log in',
  A_LOGIN_SUCCESS: 'Login',
  A_LOGOUT: 'Log out',
  C_LOGIN_WITH_GOOGLE_START: 'Dashboard - Clicked - Sign In With Google',

  // Logo
  S_LOGO: 'Dashboard - Submitted - Logo',

  // Mixpanel Integration
  C_MIXPANEL_DOCS: 'Dashboard - Clicked - Read Docs Mixpanel Connect Card', // { configEnvironment: <Production, Development>}
  C_SAVE_MIXPANEL: 'Dashboard - Clicked - Save Mixpanel Config', // { configEnvironment: <Production, Development>}

  // Navigation
  C_NAV_MENU_TAB: 'Dashboard - Clicked - Navigation Menu Tab', // { tab }
  C_NAV_NEW_SURVEY: 'Dashboard - Clicked - Navigation (+) New Microsurvey',

  // New Study Button
  C_NEW_STUDY_POPOUT_MENU: 'Dashboard - Clicked - New Study (Pop Out Menu)', // { source: <page> }
  C_NEW_STUDY_BUTTON: 'Dashboard - Clicked - New Study (Button)', // { source: <page> }
  C_SPLIT_NEW_STUDY_BUTTON: 'Dashboard - Clicked - Side Nav - New Study Split Button', // { source: <page>, selection: string }

  // OAuth
  C_OAUTH_APPROVE: 'Dashboard - Clicked - Approve New Integration Authorization',
  C_OAUTH_DENY: 'Dashboard - Clicked - Deny New Integration Authorization',

  // Onboarding Free Plan Flow
  C_ONBOARDING_FLOW_FINISH: 'Dashboard - Clicked - Onboarding Flow Finish Concept Test Tooltip',

  // Overview & Set Up
  C_OVERVIEW_WIDGET: 'Dashboard - Clicked - Overview Widget',
  C_MOVE_OVERVIEW_WIDGET: 'Dashboard - Clicked - Move Overview Widget', // { direction }
  C_RESIZE_OVERVIEW_WIDGET: 'Dashboard - Clicked - Resize Overview Widget',
  C_REMOVE_OVERVIEW_WIDGET: 'Dashboard - Clicked - Remove Overview Widget',
  C_RENAME_OVERVIEW_WIDGET: 'Dashboard - Clicked - Rename Overview Widget',
  C_ACCORDION_ROW: 'Dashboard - Clicked - Onboarding Accordion Row', // { row }
  C_PLAY_VIDEO: (name: string) => `Dashboard - Clicked - Play ${name} Video`,
  C_CHANGE_ONBOARDING_STEP: (name: string) => `Dashboard - Clicked - Onboarding ${name}`,

  // Previews
  C_PREVIEW_LINK: 'Dashboard - Clicked - Preview Link',
  V_PREVIEW_PAGE: 'Dashboard - Viewed - Preview Page',
  C_SHARE_PREVIEW: 'Dashboard - Clicked - Share Preview',

  // PDF Upload
  C_P_FILE_UPLOAD_DELETED: 'Dashboard - Clicked - PDF Upload Deleted',
  S_P_FILE_UPLOADED: 'Dashboard - Submitted - PDF Upload',
  E_P_WRONG_FILE_FORMAT: 'Dashboard - Error - PDF Wrong File Format',
  E_P_MAX_FILE_SIZE: 'Dashboard - Error - PDF Max File Size',
  E_P_UPLOAD_ERROR: 'Dashboard - Error - PDF Upload Error',

  // Plan Explorer
  C_PLAN_EXPLORER_STEP: 'Clicked - Plan Explorer Step', // {step:, source,studyTypes, monthlyStudyCount, monthlyActiveUserCount, platforms, recommendedPlan, origin, plan_explorer_output }
  V_PLAN_EXPLORER_RECOMMENDATION: 'Viewed - Plan Explorer Recommendation', // {step:, source,studyTypes, monthlyStudyCount, monthlyActiveUserCount, platforms, recommendedPlan, origin, plan_explorer_output }
  S_PLAN_EXPLORER_EMAIL: 'Submitted - Plan Explorer Email', // { email }

  // Products
  C_PRODUCT_SWITCHER: 'Dashboard - Clicked - Product Switcher', // { product }
  C_ENVS_SWITCHER: 'Dashboard - Clicked - Environments Switcher', // { product }
  S_PRODUCT_NAME: 'Dashboard - Submitted - Product Name',

  // Recommendations
  V_AI_RECOMMENDATIONS: 'Dashboard - Viewed - Recommendation', // { description, insightRecommendationActions, insightRecommendationId, surveyId,  title }
  C_AI_RECOMMENDATIONS_SHOW_MORE: 'Dashboard - Clicked - Recommendation - Show More', // { insightRecommendationId}
  C_AI_RECOMMENDATIONS_SINGLE_RECOMMENDATION_THUMBS_UP: 'Dashboard - Clicked - Recommendation - Thumbs Up', // { description, insightRecommendationActions, insightRecommendationId, name, surveyId, teamId, title, userId }
  C_AI_RECOMMENDATIONS_SINGLE_RECOMMENDATION_THUMBS_DOWN: 'Dashboard - Clicked - Recommendation - Thumbs Down', // { description, insightRecommendationActions, insightRecommendationId, name, surveyId, teamId, title, userId }

  // Recommendation Actions
  C_AI_RECOMMENDATIONS_SINGLE_RECOMMENDATION_ACTION_THUMBS_UP:
    'Dashboard - Clicked - Recommendation Action - Thumbs Up', // { actionId, insightRecommendationId, title, description, surveyId, userEmail, teamId, name }
  C_AI_RECOMMENDATIONS_SINGLE_RECOMMENDATION_ACTION_THUMBS_DOWN:
    'Dashboard - Clicked - Recommendation Action - Thumbs Down', // { actionId, insightRecommendationId, title, description, surveyId, userEmail, teamId, name }

  // Replays
  C_NEW_REPLAY: 'Dashboard - Clicked - New Standalone Replay',
  C_TOGGLE_IN_PRODUCT_REPLAY: 'Dashboard - Clicked - Toggle In Product Replay', // { isReplayEnabled }
  V_REPLAY_RESULTS: 'Dashboard - Viewed - Standalone Replay Results',
  V_REPLAYER_MODAL: 'Dashboard - Viewed - Replayer Modal', // { filterInfo, isStandaloneReplay }
  C_SWITCH_REPLAY_CLIP: 'Dashboard - Clicked - Switch Replay Clip From Modal List', // { filterInfo, isStandaloneReplay }
  C_REWIND_REPLAY: 'Dashboard - Clicked - Rewind Replay',
  C_FAST_FORWARD_REPLAY: 'Dashboard - Clicked - Fast Forward Replay',
  C_PAUSE_REPLAY: 'Dashboard - Clicked - Pause Replay',
  C_RESUME_REPLAY: 'Dashboard - Clicked - Resume Replay',
  C_RESTART_REPLAY: 'Dashboard - Clicked - Restart Replay',
  C_TOGGLE_SKIP_INACTIVITY: 'Dashboard - Clicked - Toggle Skip Inactivity', // { isSkipInactivityOn }
  C_SEEK_REPLAY: 'Dashboard - Clicked - Seek On Replay Progress Bar',
  S_REPLAY_ISSUE: 'Dashboard - Submitted - Report Replay Issue',

  // Responses
  C_RESP_VISITOR: 'Dashboard - Clicked - Response Visitor',
  C_RESP_DETAILS: 'Dashboard - Clicked - Response Details',
  C_RESP_DELETE: 'Dashboard - Clicked - Delete Response',
  C_ADJUST_CONFIDENCE: 'Dashboard - Clicked - Adjust Confidence', // { confidence }
  C_RESP_THEME: 'Dashboard - Clicked - Theme Responses', // { themeId, surveyId, themeName, version }
  C_RESP_TAKEAWAY_COPIED: 'Dashboard - Clicked - Results - AI Summary - Copy to Clipboard', // { surveyId }
  C_RESP_THEME_MODAL: 'Dashboard - Clicked - Theme Option In Modal', // { themeId, surveyId, themeName }
  C_RESP_THEMATIC_RE_RUN: 'Dashboard - Clicked - Thematic Upgrade Triggered', // { surveyId }
  C_RESP_THEMATIC_SUBMIT_FEEDBACK: 'Dashboard - Clicked - Thematic Feedback Survey Link',
  C_RESP_THEMATIC_SINGLE_THEME_POSITIVE: 'Dashboard - Clicked - Single Thematic Feedback - Thumbs Up', // { themeId, themeTitle, themeDescription, surveyId, userEmail, teamId, name }
  C_RESP_THEMATIC_SINGLE_THEME_NEGATIVE: 'Dashboard - Clicked - Single Thematic Feedback - Thumbs Down', // { themeId, themeTitle, themeDescription, surveyId, userEmail, teamId, name }

  // Settings
  S_BRAND_COLOR: 'Dashboard - Submitted - Brand Color',
  S_SIGNATURE: 'Dashboard - Submitted - UserLeap Signature', // { enabled }
  S_SURVEY_PLACEMENT: 'Dashboard - Submitted - Web Survey Placement', // { framePosition, overlayStyle, exitOnOverlayClick }
  S_SURVEY_PLACEMENT_MOBILE: 'Dashboard - Submitted - Web Survey Mobile Placement', // { overlayStyleMobile, exitOnOverlayClickMobile }
  C_PRIVACY_POLICY: 'Dashboard - Clicked - Privacy Policy',
  C_TERMS_AND_CONDITIONS: 'Dashboard - Clicked - Terms And Conditions',
  C_SECURITY_OVERIEW: 'Dashboard - Clicked - Security Overview',
  C_DATA_PROCESSING_AMDMNT: 'Dashboard - Clicked - Data Processing Amendment',
  C_APPLY_CUSTOM_STYLES: 'Dashboard - Clicked - Apply Custom Styles',
  C_REMOVE_CUSTOM_STYLES: 'Dashboard - Clicked - Remove Custom Styles',
  C_SETTINGS_NAV_MENU: 'Dashboard - Clicked - Settings Navigation Menu', // { tab },
  C_USAGE_FILTER_BY: 'Dashboard - Clicked - Usage Filter',
  V_USAGE_PAGE: 'Dashboard - Viewed - Usage Page',
  C_ACTIVE_SURVEYS: 'Dashboard - Clicked - Managed active Surveys',
  C_ACTIVE_CT: 'Dashboard - Clicked - Managed active Concept Tests',
  C_MTU_SAMPLING: 'Dashboard - Clicked - MTU sampling',
  C_REQUEST_SAMPLING: 'Dashboard - Clicked - Request Sampling',
  C_USAGE_LIMITING: 'Dashboard - Clicked - Usage limiting',
  C_REQUEST_LIMITING: 'Dashboard - Clicked - Request Limiting',
  C_OPEN_ARCHIVE_EVENTS_MODAL: 'Dashboard - Clicked - Archive unused Events',
  C_OPEN_ARCHIVE_ATTRIBUTES_MODAL: 'Dashboard - Clicked - Archive unused ATTRIBUTES',
  C_OPEN_SELF_SERVE_STARTER_MODAL: 'Dashboard - Clicked - Open Starter self-serve Upgrade',
  C_CLOSE_SELF_SERVE_STARTER_MODAL: 'Dashboard - Clicked - Close Starter self-serve Upgrade',
  C_SUBMIT_SELF_SERVE_STARTER_MODAL: 'Dashboard - Clicked - Submit Starter self-serve Upgrade',
  C_DOMAIN_TOGGLE: 'Dashboard - Clicked - Enabled/Disabled Domain Discovery',
  S_SELF_SERVE_STARTER_MODAL: 'Dashboard - Submitted - Starter self-serve Upgrade',
  S_USER_PRIVACY: 'Dashboard - Submitted - User Privacy',

  // Settings: Plans
  C_PLANS_ENTERPRISE: 'Dashboard - Clicked - Plans Enterprise Contact Us',

  // Slack Integration
  S_SLACK_ADD_STUDY_CHANNEL: 'Dashboard - Submitted - Add Study Slack Channel', // { source }
  S_SLACK_REMOVE_STUDY_CHANNEL: 'Dashboard - Submitted - Remove Study Slack Channel', // { source }
  S_SLACK_ADD_DEFAULT_CHANNEL: 'Dashboard - Submitted - Add Default Slack Channel',
  S_SLACK_REMOVE_DEFAULT_CHANNEL: 'Dashboard - Submitted - Remove Default Slack Channel',
  C_SLACK_DISCONNECTED: 'Dashboard - Clicked - Disconnect Slack',
  C_LAUNCH_MODAL_NOT_INTERESTED: 'Dashboard - Clicked - Launch Modal - Not Interested',
  C_LAUNCH_MODAL_NOT_INTERESTED_TEAM: 'Dashboard - Clicked - Launch Modal - Not Interested - Team',

  // Signup
  V_SIGNUP: 'Dashboard - View - Signup Page',
  S_SIGNUP: 'Dashboard - Submitted - Signup', // { companySize, goal }
  C_SIGNUP_WITH_GOOGLE_START: 'Dashboard - Clicked - Sign Up With Google',
  C_ACTIVATE_ACCOUNT_WITH_GOOGLE_START: 'Dashboard - Clicked - Activate Account With Google',
  /*
    We pass in the step as { step }:
    1: Username and email
    2: Company name
    3: Preferred platform
    4: Goal
  */
  S_SIGNUP_STEP: 'Dashboard - Submitted - Signup Step',
  S_STARTUP_SIGNUP_STEP: 'Dashboard - Submitted - Startup Signup Step',
  S_SIGNUP_WORK_EMAIL: 'Dashboard - Submitted - Signup (Valid Work Email)',
  S_SIGNUP_TEAM_DOMAIN_EXISTS: 'Dashboard - Submitted - Team Domain Already Exists', // { email, teamId }
  // Nov. 2021 Signup Experimentation Analytics
  C_SIGNUP_T_NEXT_STEP: 'Dashboard - Clicked - Signup Next Step', // { type, variant, companySize, mau, step: <EmailAndPassword>, <CompanyName>, <CreateYourTeam>, <PreferredPlatformSelection>, <BookAMeeting> }

  // Sprig AI
  C_SPRIG_AI_SURVEY_AI_DYNAMIC_SUGGESTED_QUESTION: 'Dashboard - Clicked - Survey AI - Dynamic Suggested Question',
  C_SPRIG_AI_SURVEY_AI_SHARE_FEEDBACK: 'Dashboard - Clicked - Survey AI - Share Feedback',
  C_SPRIG_AI_SURVEY_AI_STATIC_SUGGESTED_QUESTION: 'Dashboard - Clicked - Survey AI - Static Suggested Question',
  S_SPRIG_AI_SURVEY_AI_CUSTOM_QUESTION: 'Dashboard - Submitted - Survey AI - Custom Question',
  C_SPRIG_AI_SURVEY_AI_TAB: 'Dashboard - Clicked - Survey AI - Sprig AI Tab', // { surveyId }
  C_SPRIG_AI_SURVEY_AI_INSIGHTS_TAB: 'Dashboard - Clicked - Survey AI - Sprig AI Insights Tab', // { surveyId }
  V_SPRIG_AI_SURVEY_AI_TAB: 'Dashboard - Viewed - Survey AI - Sprig AI Tab',
  C_SPRIG_AI_SURVEY_AI_UPGRADE_FREE_PLAN: 'Dashboard - Clicked - Survey AI - Upgrade Free Plan', // { surveyId }
  C_SPRIG_AI_SURVEY_AI_MESSAGE_FEEDBACK_VOTE: 'Dashboard - Clicked - Survey AI - AI Message Feedback Vote', // { feedback, surveyId, aiResponse, userQuestion }
  C_SPRIG_AI_SURVEY_AI_INSIGHTS_FEEDBACK_VOTE: 'Dashboard - Clicked - Survey AI - AI Insights & Summary Feedback Vote', // { feedback, surveyId, takeaways, insights }
  C_SPRIG_AI_OPEN_STUDY_GOAL_MODAL: 'Dashboard - Clicked - Open Study Goal Modal', // { surveyId }
  C_SPRIG_AI_SURVEY_SUMMARY_FEEDBACK_THUMBS_UP: 'Dashboard - Clicked - Results Page - AI Summary - Thumbs Up', // { surveyId, summary, dataGeneratedAt, surveyType }
  C_SPRIG_AI_SURVEY_SUMMARY_FEEDBACK_THUMBS_DOWN: 'Dashboard - Clicked - Results Page - AI Summary - Thumbs Down', // { surveyId, summary, dataGeneratedAt, surveyType }

  // Sprig AI - Session Replay Analysis
  C_SPRIG_AI_SESSION_REPLAY_ANALYSIS_SUMMARY_TAB: 'Dashboard - Viewed - Session Replay Analysis Summary Tab', // { surveyId, numberOfGroupsGenerated, duration }
  C_SPRIG_AI_SESSION_REPLAY_ANALYSIS_GENERATE_GROUPS_CTA:
    'Dashboard - Clicked - Session Replay Analysis Generate Groups CTA', // {surveyId, surveyName, surveyPlatform, surveyStatus, surveyType}
  C_SPRIG_AI_SESSION_REPLAY_ANALYSIS_GROUP_CARD: 'Dashboard - Clicked - Session Replay Analysis Group Card', // { groupId, groupName, groupDescription, numberOfClipsAssociated }
  C_SPRIG_AI_SESSION_REPLAY_ANALYSIS_THEME_CARD_FEEDBACK_VOTE:
    'Dashboard - Clicked - Session Replay Analysis Theme Card Feedback Vote', // { feedback, surveyId, themeId, themeName, themeDescription }
  C_SPRIG_AI_SESSION_REPLAY_ANALYSIS_SHARE_FEEDBACK: 'Dashboard - Clicked - Session Replay Analysis Share Feedback', // { surveyId, surveyName, surveyPlatform, surveyStatus, surveyType }

  // Sprig AI - Open Text Analysis Customization
  // Track if study goal is added (submitted for first time)
  C_SPRIG_AI_OPEN_TEXT_ANALYSIS_CUSTOMIZATION_ADD_NEW_GOAL:
    'Dashboard - Clicked - Open Text Analysis Customization Add New Goal', // { surveyId, surveyCreatedAt, studyGoal }
  // Track if study goal is edited (submitted subsequent times)
  C_SPRIG_AI_OPEN_TEXT_ANALYSIS_CUSTOMIZATION_EDIT_GOAL:
    'Dashboard - Clicked - Open Text Analysis Customization Edit Goal', // { surveyId, surveyCreatedAt, studyGoal }
  // Track if study goal is deleted (submitted subsequent times)
  C_SPRIG_AI_OPEN_TEXT_ANALYSIS_CUSTOMIZATION_DELETE_GOAL:
    'Dashboard - Clicked - Open Text Analysis Customization Delete Goal', // { surveyId, surveyCreatedAt }

  // Sprig AI - Study Creation Assistant
  C_SPRIG_AI_STUDY_CREATION_ASSISTANT_CREATE_WITH_AI:
    'Dashboard - Clicked - Study Creation Assistant Create With Ai Entry Point',
  C_SPRIG_AI_STUDY_CREATION_ASSISTANT_GENERATE_IDEAS: 'Dashboard - Clicked - Study Creation Assistant Generate Ideas', // {studyGoal, entryPoint, environmentId}
  C_SPRIG_AI_STUDY_CREATION_ASSISTANT_SUGGESTED_IDEA: 'Dashboard - Clicked - Study Creation Assistant Suggested Idea', // {suggestedText, environmentId}
  C_SPRIG_AI_STUDY_CREATION_ASSISTANT_CONTINUE:
    'Dashboard - Clicked - Study Creation Assistant Continue With Selected Template', // { studyGoal, selectedTemplate, studyType }
  C_SPRIG_AI_STUDY_CREATION_ASSISTANT_SELECT_AI_GENERATED_TEMPLATE:
    'Dashboard - Clicked - Study Creation Assistant Select AI Generated Template', // { selectedTemplate, studyGoal, studyType }
  C_SPRIG_AI_STUDY_CREATION_ASSISTANT_QUESTION_SELECTION:
    'Dashboard - Clicked - Study Creation Assistant Question Selection', // {selectedQuestion}
  C_SPRIG_AI_STUDY_CREATION_ASSISTANT_GENERATED_TEMPLATE_FEEDBACK_THUMBS_UP:
    'Dashboard - Clicked - Study Creation Assistant Generated Template - Thumbs Up', // { studyGoal, selectedTemplate, studyType }
  C_SPRIG_AI_STUDY_CREATION_ASSISTANT_GENERATED_TEMPLATE_FEEDBACK_THUMBS_DOWN:
    'Dashboard - Clicked - Study Creation Assistant Generated Template - Thumbs Down', // { studyGoal, selectedTemplate, studyType }
  C_SPRIG_AI_STUDY_CREATION_ASSISTANT_LAUNCH_STUDY_BUTTON:
    'Dashboard - Clicked - Study Creation Assistant Launch Study Button', // { surveyId, studyType }
  C_SPRIG_AI_STUDY_CREATION_ASSISTANT_SAVE_CHANGES_BUTTON:
    'Dashboard - Clicked - Study Creation Assistant Save Changes Button', // { surveyId, studyType }
  C_SPRIG_AI_STUDY_CREATION_ASSISTANT_SUBMIT_FEEDBACK:
    'Dashboard - Clicked - Study Creation Assistant Clicked Submit Feedback Link', // { studyGoal, studyType, selectedTemplate }

  // Surveys
  S_RESURVEY_WINDOW: 'Dashboard - Submitted - Resurvey Window',
  C_DWNLD_CSV: 'Dashboard - Clicked - Download CSV', // { opensDownloadOptionsModal }
  C_CONFIRM_DWNLD_CSV: 'Dashboard - Clicked - Confirm Download CSV', // { columnFormat, selectedNumberOfQuestionsWithThemes, totalNumberOfQuestionsWithThemes, selectedNumberOfAttributes, totalNumberOfAttributes }
  C_CSV_EXPORT_DOC: 'Dashboard - Clicked - Read Docs from Download CSV',
  C_CSV_EXPORT_AI_THEMES: 'Dashboard - Clicked - Download CSV With AI Themes',
  C_TOGGLE_PREVIEW: 'Dashboard - Clicked - Toggle Preview Panel',
  C_SUMMARY_TAB: 'Dashboard - Clicked - Survey Summary',
  V_SUMMARY_TAB_W_THEMES_DURATION: 'Dashboard - Viewed - Survey Summary With Themes - Duration Based', // { duration: <number of seconds viewed before triggering event> }
  C_RESPONSES_TAB: 'Dashboard - Clicked - Survey Responses List',
  C_CLIPS_TAB: 'Dashboard - Clicked - Session Replay Clips',
  C_REPLAY_ANALYSIS_THEMES_TAB: 'Dashboard - Clicked - Session Replay Analysis Themes',
  C_CONNECT_TAB: 'Dashboard - Clicked - Survey Connect Tab',
  C_ADD_LOGIC: 'Dashboard - Clicked - Add Logic',
  C_ADD_LOGIC_GRP: 'Dashboard - Clicked - Add Logic Group',
  C_ADD_RESP_FILTER: 'Dashboard - Clicked - Add Response Filter',
  C_APPLY_RESP_FILTER: 'Dashboard - Clicked - Apply Response Filter', // { attributeFilterCount, questionFilterCount }
  C_ADD_ATTR_RESP_FILTER: 'Dashboard - Survey Results - Clicked - Attribute Filter',
  C_ADD_QUES_RESP_FILTER: 'Dashboard - Survey Results - Clicked - Questions Filter',
  C_DURATION_FILTER: 'Dashboard - Clicked - Duration Filter', // { durationType: 'all' | 'latestDays' | 'range', days, startTime, endTime }
  C_RESP_DISPLAY_FORMAT: 'Dashboard - Clicked - Response Display Format', // { chart, questionType }
  C_REQUEST_TO_LAUNCH: 'Dashboard - Clicked - Request to Launch (Editor Lite)',
  C_LAUNCH_STUDY_FAILED: 'Dashboard - Clicked - Launch Study Failed', // { surveyId, surveyType }
  C_LAUNCH_STUDY: 'Dashboard - Clicked - Launch Study', // { reference getPropsFromSurvey(), environment: Development | Production }
  C_RELAUNCH_SURVEY: 'Dashboard - Clicked - Relaunch Survey', // reference getPropsFromSurvey()
  C_ARCHIVE_SURVEY: 'Dashboard - Clicked - Archive Survey',
  C_ARCHIVE_SURVEYS: 'Dashboard - Clicked - Archive Surveys', // {count}
  C_UNARCHIVE_SURVEY: 'Dashboard - Clicked - Unarchive Survey',
  C_EDIT_SURVEY: 'Dashboard - Clicked - Edit Survey',
  E_EDIT_SURVEY_OUTDATED: 'Dashboard - Error - Edit Outdated Survey',
  C_DUPLICATE_SURVEY: 'Dashboard - Clicked - Duplicate Survey', // {toEnvironment: Development | Production}
  C_VIEW_DUPLICATE_SURVEY: 'Dashboard - Clicked - View Duplicate Survey', // {toEnvironment: Development | Production}
  C_MOVE_SURVEY: 'Dashboard - Clicked - Move Survey (Folder)',
  C_MOVE_SURVEYS: 'Dashboard - Clicked - Move Surveys (Folder)', // {count}
  C_MOVE_FILTER: 'Dashboard - Clicked - Move Survey Filter',
  S_UPDATE_CONSTRAINT: 'Dashboard - Submitted - Updated Trigger', // { surveyId, studyType, constraints }
  C_APPLY_EVENT_PROPERTY: 'Dashboard - Clicked - Apply Event Property Trigger', // {surveyId}
  C_REMOVE_EVENT_PROPERTY: 'Dashboard - Clicked - Remove Event Property Trigger', // {surveyId}
  C_SUGGESTED_EVENT_PROPERTY: 'Dashboard - Clicked - Select Suggested Event Property Key', // {surveyId}
  C_CUSTOM_EVENT_PROPERTY: 'Dashboard - Clicked - Select Custom Event Property Key', // {surveyId}
  C_CREATE_TEMPLATE: 'Dashboard - Clicked - Create Template From Survey',
  C_ADD_QUESTION: 'Dashboard - Clicked - Add Question',
  C_CHANGE_QUESTION_TYPE: 'Dashboard - Clicked - Change Question Type',
  C_TOGGLE_MC_OPTION_RANDOMIZATION: 'Dashboard - Clicked - MC Question - Randomize',
  C_ADD_MC_QUESTION_OPTION: 'Dashboard - Clicked - MC Question - Add Choice',
  C_DELETE_QUESTION: 'Dashboard - Clicked - Delete Question',
  C_MOVE_QUESTION: 'Dashboard - Clicked - Move Question',
  C_DUPLICATE_QUESTION: 'Dashboard - Clicked - Duplicate Question',
  C_INSERT_NEW_BELOW: 'Dashboard - Clicked - Insert New Below',
  C_EXPAND_TOTAL_RESP: 'Dashboard - Clicked - Expand Total Responses',
  C_EXPAND_RESURVEY_OPTS: 'Dashboard - Clicked - Expand Resurvey Options',
  C_ADVANCED_RESURVEY_OPTS: 'Dashboard - Clicked - Advanced Resurvey Options',
  C_ADVANCED_FILTER: 'Dashboard - Clicked - Advanced Filtering',
  C_ADVANCED_SURVEY_TRIGGER: 'Dashboard - Clicked - Advanced Survey Trigger',
  C_SAVE_DRAFT: 'Dashboard - Clicked - Save Draft',
  C_SELECT_PLATFORM: 'Dashboard - Clicked - Select Platform', // { platform. surveyId }
  V_QUESTIONS_FORM: 'Dashboard - Viewed - Survey Questions Form', // { template (id), studyType }
  V_AUDIENCE_FORM: 'Dashboard - Viewed - Survey Audience Form',
  V_REVIEW_FORM: 'Dashboard - Viewed - Survey Review Form',
  V_WLU_SURVEY_SUMMARY: 'Dashboard - Viewed - WLU Survey Summary', // { responseCount, studyType, surveyId, surveyStatus, environmentId, $timestamp }
  S_ADD_FIGMA_PROTOTYPE: 'Dashboard - Submitted - Add Figma Prototype URL',
  C_FIGMA_PROTOTYPE_FEEDBACK: 'Dashboard - Clicked - Share Feedback on Figma Prototype',
  C_TOGGLE_QUESTION_REQUIRED: 'Dashboard - Clicked - Toggle Question Required',
  C_ADD_PROTOTYPE_FOOTER: 'Dashboard - Clicked - Add Prototype URL',
  S_ADD_PROTOTYPE: 'Dashboard - Submitted - Add Prototype URL',
  A_DRAG_QUESTION: 'Dashboard - Action - Drag Reorder Question',
  C_TOGGLE_COLLAPSE_ALL: 'Dashboard - Clicked - Toggle Collapse All Questions',
  C_TOGGLE_COLLAPSE_ONE: 'Dashboard - Clicked - Toggle Collapse Question',
  C_SAVE_AND_COPY_LINK: 'Dashboard - Clicked - Save Changes and Copy Link (Editor Lite)',
  C_DETAIL_COPY_LINK: 'Dashboard - Clicked - Copy Link (Editor Lite)',
  C_SHARE_STUDY_RESULT_LINK: 'Dashboard - Clicked - Share Study Results Link', // { surveyId, questionId }
  V_SHARE_STUDY_RESULT_LINK: 'Dashboard - Viewed - Share Study Results Link', // { surveyId, questionId }
  C_TOGGLE_CHART_TYPE: 'Dashboard - Clicked - Toggle Question Result Chart Type', // { chartType }
  C_MULTI_LANG_ADD_LANGUAGES: 'Dashboard - Clicked - Multi Language -  Add Languages', // { languages }
  V_MULTI_LANG_TRANSLATION_UPLOAD_ERROR: 'Dashboard - Viewed - Multi Language - Upload Translation File Error', // { error }
  C_MULTI_LANG_DOWNLOAD_TRANSLATION: 'Dashboard - Clicked - Multi Language - Download Translation File CTA',
  C_MULTI_LANG_DELETE_LANGUAGE: 'Dashboard - Clicked - Multi Language - Delete Language', // { language }
  C_MULTI_LANG_REMOVE_LANGUAGE_CARD: 'Dashboard - Clicked - Multi Language - Remove Language Card',
  C_MULTI_LANG_DOWNLOAD_TRANSLATION_MODAL:
    'Dashboard - Clicked - Multi Language - Download Translation CTA - From Modal',
  C_MULTI_LANG_UPLOAD_TRANSLATION: 'Dashboard - Clicked - Multi Language - Upload Translation File CTA',
  C_MULTI_LANG_PREVIEW_LANGUAGE_DROPDOWN: 'Dashboard - Clicked - Multi Language - Preview Language Dropdown', // { isFullscreenPreview }
  C_DRAG_QUESTION_CHOICE: 'Dashboard - Clicked - Drag & Drop Question Choice', // { questionType }
  C_TARGETING_ADD_EVENT: 'Dashboard - Clicked - Targeting Card - Add Trigger', // { studyType, triggerType }

  // Study Folder
  C_ADD_FOLDER: 'Dashboard - Clicked - Add Folder',
  C_DELETE_FOLDER: 'Dashboard - Clicked - Delete Folder',
  C_RENAME_FOLDER: 'Dashboard - Clicked - Rename Folder',
  C_FOLDER_SORT_BY: 'Dashboard - Clicked - Folder Sort By', // { sortBy }
  C_GRID_VIEW: 'Dashboard - Clicked - Grid View',
  C_LIST_VIEW: 'Dashboard - Clicked - List View',
  C_EXPAND_FOLDERS: 'Dashboard - Clicked - Expand Folder Content',
  C_NEW_FOLDER: 'Dashboard - Clicked - Studies Page - New Folder',
  C_SURVEYS_SEARCH_BAR: 'Dashboard - Clicked - Studies Page - Search Bar',
  C_SURVEYS_FILTER: 'Dashboard - Clicked - Studies Page - Filters',

  // Table
  C_TABLE_SORT: 'Dashboard - Clicked - Sort Events', // { title }

  // Team
  S_JOIN_TEAM: 'Dashboard - Submitted - Join Team',

  // Templates
  C_TEMPLATE_COLLECTION: 'Dashboard - Clicked - Template Collection', // { collection }
  C_TEMPLATE_CARD: 'Dashboard - Clicked - Template Card', // { card }
  C_TEMPLATE_CUSTOMIZE: 'Dashboard - Clicked - Template Customize & Launch', // { source: <card, preview>}
  C_CREATE_FROM_SCRATCH: 'Dashboard - Clicked - Create From Scratch', // { studyType: 'inProductSurvey' | 'conceptTest', source: 'templates' | 'emptyFolder' | some sort of nav> }
  C_TEMPLATE_CATEGORY: 'Dashboard - Clicked - Template Use Case', // { surveyTemplateCategoryName: string, surveyTemplateCategoryId: string }
  C_POPULAR_TEMPLATE: 'Dashboard - Clicked - Popular Template', // { surveyTemplateName: string, surveyTemplateId: string }
  C_TEMPLATE_BROWSE_ALL: 'Dashboard - Clicked - Browse Templates',
  C_DISMISS_TEMPLATES_WELCOME_MODAL: 'Dashboard - Clicked - Dismiss Templates Welcome Modal',
  C_USE_THIS_TEMPLATE: 'Dashboard - Clicked - Use This Template', // { templateId }
  C_SHARE_TEMPLATE: 'Dashboard - Clicked - Share Template', // { source: e.g. linkedin, url }

  // Test a study Preview
  C_PREVIEW_TEST_STUDY: 'Dashboard - Clicked - Preview on your website',
  C_PREVIEW_TEST_STUDY_LAUNCH: 'Dashboard - Clicked - Launch Test Study', // { platform, url }
  C_PREVIEW_TEST_STUDY_STOP: 'Dashboard - Clicked - Stop Test Study',
  C_PREVIEW_TEST_STUDY_PREVIEW: 'Dashboard - Clicked - Test Study External Url Preview',

  // User
  S_NEW_USER: 'Dashboard - Submitted - New User', // { role, inviteeEmail }
  S_EDIT_USER: 'Dashboard - Submitted - Edit User', // { role }
  A_PASSWORD_RESET: 'Dashboard - Action - Send Password Reset Email', // { email }
  S_PASSWORD_RESET_SUCCESS: 'Dashboard - Submitted Form - Password Successfully Reset', // { email }
  C_ADD_MEMBER: 'Dashboard - Clicked - Add Member',
  C_MANAGE_REQUESTS: 'Dashboard - Clicked - Manage Join Team Requests',
  S_INVITE_TO_SPRIG: 'Dashboard - Submitted - Invite To Sprig', // { invitedByEmail, invitedById, inviteeId, inviteeEmail }
  C_JOIN_TEAM: 'Dashboard - Clicked - Join Team',
  C_REQUEST_JOIN_TEAM: 'Dashboard - Clicked - Request to Join Team',
  C_CREATE_NEW_TEAM: 'Dashboard - Clicked - Created a New Team',

  // Upsell Modal
  S_MEETING_REQUEST: 'Dashboard - Submitted - Requested Sales Meeting',
  C_CLOSED_WITHOUT_BOOKING: 'Dashboard - Clicked - Closed Sales Meeting Without Booking',

  // User Interviews Integration
  C_TO_USER_INTERVIEWS: 'Dashboard - Clicked - Go to User Interviews',
  C_USER_INTERVIEWS_DISCONNECT: 'Dashboard - Clicked - Disconnect User Interviews',
  C_USER_INTERVIEWS_LEARN_MORE: 'Dashboard - Clicked - User Interviews Learn More',
  C_USER_INTERVIEWS_STUDY: 'Dashboard - Clicked - Toggle User Interviews Delivery for Study',

  // Visitors / People Page
  A_VIS_SEARCH: 'Dashboard - Action - Visitors Search', // { searchString }
  V_VIS_TIMELINE: 'Dashboard - Viewed - Visitor Timeline', // source { All People, Group, Survey Responses }
  A_LEFT_VIS_TIMELINE: 'Dashboard - Action - Left Visitor Timeline', // { timeOnPageSeconds }

  // Video & Voice Question
  C_ADD_VIDEO_QUESTION: 'Dashboard - Clicked - Add Video & Voice Question', // { surveyId, teamId, mediaType }
  C_DELETE_UPLOAD: 'Dashboard - Clicked - Delete Upload for Video & Voice Question', // { surveyId, teamId, mediaType }
  C_HIDE_RECORDED_PROMPT: 'Dashboard - Clicked - V&V - Recorded Prompt Toggle', // { surveyId, teamId, promptHidden }
  A_START_RECORDING: 'Dashboard - Action - Start Recording for Video & Voice Question', // { surveyId, teamId, mediaType }
  A_START_UPLOAD: 'Dashboard - Action - Start Upload for Video & Voice Question', // { surveyId, teamId, mediaType }

  // Webhook
  C_TEST_WEBHOOK: 'Dashboard - Clicked - Test Webhook', // { env }
};
