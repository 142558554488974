import React from 'react';

import styled from '@emotion/styled';

import { Spinner } from './Spinner';

interface LoadingIndicatorProps {
  className?: string;
  children?: React.ReactNode;
}

export const LoadingIndicator = ({ className, children }: LoadingIndicatorProps) => (
  <LoadingContainer
    className={className}
    aria-live="polite"
    aria-label="Loading..."
    aria-busy="true"
    data-testid="loading"
  >
    {children ? children : <Spinner />}
  </LoadingContainer>
);

//TODO update styles
const LoadingContainer = styled.div`
  font-size: 1.8rem;
  flex-grow: 1;
  color: hsl(201, 8%, 70%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
