import { css } from '@emotion/react';

import { border as borderColor } from './colors';
import { size } from './size';

export const roundBorderLight = css`
  border-radius: ${size(1.5)};
  border: 1px ${borderColor.light} solid;
`;

export const roundBorderMedium = css`
  border-radius: ${size(1.5)};
  border: 1px ${borderColor.medium} solid;
`;

export const roundBorderDark = css`
  border-radius: ${size(1.5)};
  border: 1px ${borderColor.dark} solid;
`;
