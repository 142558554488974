export enum BooleanOperator {
  And = 1,
  Or = 2,
}

export const BOOLEAN_OPERATOR_MAP: { [key in BooleanOperator]: 'and' | 'or' } = {
  [BooleanOperator.And]: 'and',
  [BooleanOperator.Or]: 'or',
};

export enum Comparator {
  CompletelySubmitted = 'complete', // used for labeling below
  Contains = 'contains',
  EndsWith = 'endswith',
  Equal = 'eq',
  Exactly = 'list_exact',
  GivenUp = 'given_up',
  GreaterThan = 'gt',
  GreaterThanOrEqual = 'gte',
  In = 'in',
  Is = 'is', // not a real comparator. Only used for MCSS for labeling purposes
  IsNot = 'isNot', // not a real comparator. Only used for MCSS for labeling purposes
  IsSet = 'isSet',
  LessThan = 'lt',
  LessThanOrEqual = 'lte',
  IncludesAll = 'list_all',
  IncludesOne = 'list_alo',
  NotIncludes = 'list_dni',
  Matches = 'matches',
  NotContains = 'notcontains',
  NotEqual = 'neq',
  NotMatches = 'notmatches',
  Partial = 'partial',
  Skipped = 'skipped',
  StartsWith = 'startswith',
  Submitted = 'answered',
}
