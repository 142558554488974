import { RoutingOptions, QuestionTypeID } from '@sprigShared/questions';

export enum TranslationKeyMap {
  'Button Label' = 'buttonLabel',
  'Checkbox Text' = 'checkboxText',
  'Choices' = 'choices',
  Description = 'description',
  'Description HTML' = 'descriptionHtml',
  'Multiple Choices Selected Text' = 'dropdownMultiselectText',
  'Dropdown Prompt Text' = 'dropdownPlaceholder',
  'Footer HTML' = 'footerHtml',
  'Input Text Placeholder' = 'inputTextPlaceholder',
  'Lower Label' = 'lowerLabel',
  'Skip Button Label' = 'skipButtonLabel',
  'Thank You Headline' = 'thankYouHeadline',
  'Thank You Subheader' = 'thankYouSubheader',
  Title = 'title',
  'Title HTML' = 'titleHtml',
  'Upper Label' = 'upperLabel',
}

export const TranslationLabelMap: Record<Exclude<`${TranslationKeyMap}`, 'choices'>, keyof typeof TranslationKeyMap> = {
  buttonLabel: 'Button Label',
  checkboxText: 'Checkbox Text',
  description: 'Description',
  descriptionHtml: 'Description HTML',
  dropdownMultiselectText: 'Multiple Choices Selected Text',
  dropdownPlaceholder: 'Dropdown Prompt Text',
  footerHtml: 'Footer HTML',
  inputTextPlaceholder: 'Input Text Placeholder',
  lowerLabel: 'Lower Label',
  skipButtonLabel: 'Skip Button Label',
  thankYouHeadline: 'Thank You Headline',
  thankYouSubheader: 'Thank You Subheader',
  title: 'Title',
  titleHtml: 'Title HTML',
  upperLabel: 'Upper Label',
};

export type TranslationLabel =
  | keyof typeof TranslationKeyMap
  | `Choice ${number}`
  | `Row ${number}`
  | `Column ${number}`;

export interface EndCard {
  headline?: string;
  subheader?: string;
}

export interface Question {
  options: { optionProperties?: { allowsTextEntry?: boolean; noneOfTheAbove?: boolean; isPinned?: boolean } }[];
  properties: {
    scaleLabelType?: string;
    buttonUrl?: string;
    buttonText?: string;
    captionText?: string;
    hideRecordedPrompt?: boolean;
    openTextPlaceholder?: string;
    required?: boolean;
    endCard?: EndCard;
    footerHtml?: string;
    questionHtml?: string;
    captionHtml?: string;
    isDropdown?: boolean;
    displayMatrixAsAccordion?: boolean;
  };
  routingOptions: RoutingOptions;
  questionTypeId: QuestionTypeID;
}

// account for different object structures between api and dashboard
export type LiveQuestion = {
  values?: Question;
  props?: { properties: { required: boolean } };
  properties: Question['properties'] | null;
  routingOptions: Question['routingOptions'] | null;
} & Omit<Question, 'properties' | 'routingOptions'>;
