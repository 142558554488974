import { css } from '@emotion/react';

import { size } from './size';

const baseHeader = css`
  font-weight: 600;
  line-height: 150%;
  margin: 0;
`;
const baseBody = css`
  font-size: ${size(2)};
  line-height: 150%;
`;
const baseSmallBody = css`
  font-size: ${size(1.75)};
  line-height: 150%;
`;
const baseCaption = css`
  font-size: ${size(1.5)};
  line-height: 150%;
`;

export const typography = {
  h1: css`
    ${baseHeader}
    font-size: ${size(4)};
  `,
  h2: css`
    ${baseHeader}
    font-size: ${size(3)};
  `,
  h3: css`
    ${baseHeader}
    font-size: ${size(2.5)};
  `,
  h4: css`
    ${baseHeader}
    font-size: ${size(2.25)};
  `,
  h5: css`
    ${baseHeader}
    font-size: ${size(2)};
  `,
  h6: css`
    ${baseHeader}
    font-size: ${size(1.75)};
  `,
  body: css`
    ${baseBody}
    font-weight: 400;
  `,
  bodyMedium: css`
    ${baseBody}
    font-weight: 500;
  `,
  bodyBold: css`
    ${baseBody}
    font-weight: 600;
  `,
  smallBody: css`
    ${baseSmallBody}
    font-weight: 400;
  `,
  smallBodyMedium: css`
    ${baseSmallBody}
    font-weight: 500;
  `,
  smallBodyBold: css`
    ${baseSmallBody}
    font-weight: 600;
  `,
  caption: css`
    ${baseCaption}
    font-weight: 400;
  `,
  captionMedium: css`
    ${baseCaption}
    font-weight: 500;
  `,
  captionBold: css`
    ${baseCaption}
    font-weight: 600;
  `,
};
