import { isNode } from './nodeOrBrowser';

export enum Environments {
  qa25 = 'qa25',
  qa50 = 'qa50',
  qa99 = 'qa99',
  staging = 'staging',
  production = 'production',
}

export type SprigEnvironment = `qa${number}` | 'staging' | 'production';
// TODO: Update process.env.NODE_ENV to process.env.ENVIRONMENT
type environment = Environments | 'local' | 'localhost' | 'development' | 'test';

/*
  Match host where:
    - could have staging|qa<NUMBER> (matching group)
    - finally has sprig.com
*/
export const ENV_REGEX_HOST = /((qa[A-z0-9]*|staging)(?:\.)?)?sprig\.com/i;

export class Environment {
  private _environment: environment;
  private _baseHost: string;
  private _node_env: string;

  static isSprigEnvironmentValid(str: string): str is Environments {
    return /qa[A-z0-9]*|staging|production|development/.test(str);
  }

  static isEnvironmentValid(str: string): str is environment {
    return /qa[A-z0-9]*|staging|production|local|localhost|development|test/.test(str);
  }

  constructor() {
    // default to production for node, ensures prod is running
    // TODO: Update process.env.NODE_ENV to process.env.ENVIRONMENT
    this._node_env = process.env.ENVIRONMENT || process.env.NODE_ENV || '';

    if (isNode()) {
      this._environment = Environment.isSprigEnvironmentValid(this._node_env)
        ? this._node_env
        : Environments.production;
      this._baseHost = this._environment === 'production' ? 'sprig.com' : `${this._environment}.sprig.com`;
      return;
    }

    const [host = 'sprig.com', , env = 'production'] = window?.location?.host?.match(ENV_REGEX_HOST) || [];
    this._environment = Environment.isSprigEnvironmentValid(env) ? env : Environments.production;
    this._baseHost = host;
  }

  get environment() {
    return this.isLocal() ? 'development' : this._environment;
  }

  get baseHost() {
    return this.isLocal() ? 'localhost' : this._baseHost;
  }

  get apiUrl() {
    return process.env.API_URL
      ? process.env.API_URL
      : this.isLocal()
      ? `http://localhost:8080`
      : `https://api.${this.baseHost}`;
  }

  get appUrl() {
    return process.env.APP_URL
      ? process.env.APP_URL
      : this.isLocal()
      ? `http://localhost:3000`
      : `https://app.${this.baseHost}`;
  }

  get cdnUrl() {
    return process.env.CDN_URL
      ? process.env.CDN_URL
      : this.isLocal()
      ? // this doesnt exist yet
        `http://localhost:4002`
      : `https://cdn.${this.baseHost}`;
  }

  get webSurveyUrl() {
    return process.env.WEB_SURVEY_URL
      ? process.env.WEB_SURVEY_URL
      : this.isLocal()
      ? `http://localhost:4001`
      : `https://a.${this.baseHost}`;
  }

  get replayerUrl() {
    const fallbackUrl = this.isLocal() ? `http://localhost:4003` : `https://session-replayer.${this.baseHost}`;
    return process.env.REPLAYER_URL ? process.env.REPLAYER_URL : fallbackUrl;
  }

  get marketingUrl() {
    return process.env.MARKETING_URL
      ? process.env.MARKETING_URL
      : this.isLocal()
      ? `http://localhost:3001`
      : `https://${this.baseHost}`;
  }

  public isProd() {
    return this._environment === 'production' && !this.isLocal();
  }

  public isStaging() {
    return this._environment === 'staging' && !this.isLocal();
  }

  public isQa() {
    return this._environment.includes('qa') && !this.isLocal();
  }

  public isLocal() {
    // if node_env is test we should default to localhost
    if (this.isTest()) {
      return true;
    }

    if (isNode()) {
      return ['localhost', 'local', 'development', 'dev'].includes(this._environment);
    }
    return window?.location?.host?.includes('localhost') || window?.location?.host?.includes('0.0.0.0');
  }

  public isTest() {
    return this._node_env === 'test';
  }
}
