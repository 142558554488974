import TTCommonsProBlack from './TT_Commons_Pro_Black.woff2';
import TTCommonsProBlackItalic from './TT_Commons_Pro_Black_Italic.woff2';
import TTCommonsProBold from './TT_Commons_Pro_Bold.woff2';
import TTCommonsProBoldItalic from './TT_Commons_Pro_Bold_Italic.woff2';
import TTCommonsProDemiBold from './TT_Commons_Pro_DemiBold.woff2';
import TTCommonsProDemiBoldItalic from './TT_Commons_Pro_DemiBold_Italic.woff2';
import TTCommonsProExtraBlack from './TT_Commons_Pro_ExtraBlack.woff2';
import TTCommonsProExtraBlackItalic from './TT_Commons_Pro_ExtraBlack_Italic.woff2';
import TTCommonsProExtraBold from './TT_Commons_Pro_ExtraBold.woff2';
import TTCommonsProExtraBoldItalic from './TT_Commons_Pro_ExtraBold_Italic.woff2';
import TTCommonsProExtraLight from './TT_Commons_Pro_ExtraLight.woff2';
import TTCommonsProExtraLightItalic from './TT_Commons_Pro_ExtraLight_Italic.woff2';
import TTCommonsProLight from './TT_Commons_Pro_Light.woff2';
import TTCommonsProLightItalic from './TT_Commons_Pro_Light_Italic.woff2';
import TTCommonsProMedium from './TT_Commons_Pro_Medium.woff2';
import TTCommonsProMediumItalic from './TT_Commons_Pro_Medium_Italic.woff2';
import TTCommonsProNormal from './TT_Commons_Pro_Normal.woff2';
import TTCommonsProNormalItalic from './TT_Commons_Pro_Normal_Italic.woff2';
import TTCommonsProThin from './TT_Commons_Pro_Thin.woff2';
import TTCommonsProThinItalic from './TT_Commons_Pro_Thin_Italic.woff2';

export const ttCommonsPro = `
  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProExtraBlack}) format('woff2');

    font-weight: 950;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProExtraBlackItalic}) format('woff2');

    font-weight: 950;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProBlack}) format('woff2');

    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProBlackItalic}) format('woff2');

    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProExtraBold}) format('woff2');

    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProExtraBoldItalic}) format('woff2');

    font-weight: 800;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProBold}) format('woff2');

    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProBoldItalic}) format('woff2');

    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProDemiBold}) format('woff2');

    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProDemiBoldItalic}) format('woff2');

    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProMedium}) format('woff2');

    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProMediumItalic}) format('woff2');

    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProNormal}) format('woff2');

    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProNormalItalic}) format('woff2');

    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProLight}) format('woff2');

    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProLightItalic}) format('woff2');

    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProExtraLight}) format('woff2');

    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProExtraLightItalic}) format('woff2');

    font-weight: 200;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProThin}) format('woff2');

    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons Pro';
    src: url(${TTCommonsProThinItalic}) format('woff2');

    font-weight: 100;
    font-style: italic;
  }
`;
