import React from 'react';

import styled from '@emotion/styled';
import { toast, ToastOptions, ToastContainer, ToastContent } from 'react-toastify';
import type { ToastContainerProps } from 'react-toastify';

import { typography } from '../../styles';
import { Icon } from '../Icon';

import 'react-toastify/dist/ReactToastify.css';

export const ToastContainerHideErrorsClassName = 'ToastContainerHideErrors';
const hideErrorMessagesClassName = 'hide-error-messages';

const DEFAULT_OPTIONS: Partial<ToastOptions> = {
  autoClose: 3000,
  theme: 'colored',
};

interface ToastAPI {
  success: (content: ToastContent | string, options?: ToastOptions) => void;
  error: (content: ToastContent | string, options?: ToastOptions) => void;
  dismiss: () => void;
}

export const Toast: ToastAPI = Object.freeze({
  success: (content: ToastContent | string, options?: ToastOptions) => {
    toast.success(content, { icon: <Icon size={2} src="check" />, ...DEFAULT_OPTIONS, ...options });
  },
  error: (content: ToastContent | string, options?: ToastOptions) => {
    toast.error(content, {
      icon: <Icon size={2} src="warning-circle" />,
      ...DEFAULT_OPTIONS,
      ...options,
      // Hide error toasts when ToastContainerHideErrors class is present -- currently do this for DEMO account
      className: hideErrorMessagesClassName,
    });
  },
  dismiss: () => {
    toast.dismiss();
  },
});

const ToastCloseIcon = () => {
  return (
    <ToastCloseContainer>
      <Icon size={2} src="close" />
    </ToastCloseContainer>
  );
};

export const ToastWrapper = (props: ToastContainerProps) => (
  <StyledToastContainer closeButton={<ToastCloseIcon />} theme="colored" hideProgressBar={true} {...props} />
);

const StyledToastContainer = styled(ToastContainer)`
  --toastify-toast-width: 332px;
  --toastify-toast-min-height: 40px;
  --toastify-color-success: #158a75;
  --toastify-color-error: #d15153;
  --toastify-font-family: 'TT Commons Pro';

  & .Toastify__toast-container {
    ${typography.smallBody}
  }

  & .Toastify__toast {
    box-shadow: 0px 4px 20px rgba(11, 72, 61, 0.1);
    border-radius: 4px;
    padding: 11px;
  }

  & .Toastify__toast-body {
    ${typography.smallBody}
    align-items: flex-start;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    margin: 0;
    max-width: 278px;
    padding: 0 4px 0 0;
  }

  & .Toastify__toast-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width: 18px;
  }

  /* Hide error toasts when ToastContainerHideErrors class is present -- currently do this for DEMO account */
  &.${ToastContainerHideErrorsClassName} .${hideErrorMessagesClassName} {
    display: none !important;
  }
`;

const ToastCloseContainer = styled.div`
  width: 18px;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
